<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-card>
            <v-card-title>
              <h3 class="primary--text">Roles</h3>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="disabled = (disabled + 1) % 2">
                <v-icon left>security</v-icon>Add Role
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    :loading="loading"
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.name">
                          <td>{{ item.name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-flex>

                <v-flex xs12 sm6>
                  <v-card>
                    <v-card-title>
                      <h3 class="text-uppercase">
                        <v-icon color="black" class="mr-3">security</v-icon
                        >Create New Role
                      </h3>
                    </v-card-title>
                    <v-card-text>
                      <v-form ref="roleform" @submit.prevent="saveRole">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-text-field
                              name="role_name"
                              v-model="role_name"
                              label="Title"
                              type="text"
                              :rules="roleRules"
                              :disabled="disabled == 1 ? true : false"
                              ref="role_name"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-form>
                      <v-alert v-if="ss" :value="true" type="success">{{
                        ssMsg
                      }}</v-alert>
                      <v-alert v-if="err" :value="true" type="error">{{
                        errMsg
                      }}</v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="disabled == 1 ? true : false"
                        color="primary"
                        @click="saveRole()"
                      >
                        <v-icon left v-if="!savingRole">save</v-icon>
                        {{ savingRole ? "Processing..." : "Save" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions
              >Total Roles: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>

        <v-flex xs12 sm6>
          <v-card>
            <v-card-title>
              <h3 class="primary--text">Permissions</h3>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="disabled2 = (disabled2 + 1) % 2">
                <v-icon left>security</v-icon>Add Permission
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(permission, i) in permissions" :key="i">
                          <td>{{ permission.name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>

                <v-flex xs12 sm6>
                  <v-card>
                    <v-card-title>
                      <h3 class="text-uppercase">
                        <v-icon color="black" class="mr-3">security</v-icon>New
                        Permission
                      </h3>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="permissionform"
                        @submit.prevent="savePermission"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-text-field
                              name="permission_name"
                              v-model="permission_name"
                              label="Title"
                              type="text"
                              :rules="permissionRules"
                              :disabled="disabled2 == 1 ? true : false"
                              ref="permission_name"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-form>
                      <v-alert v-if="ss2" :value="true" type="success">{{
                        ss2Msg
                      }}</v-alert>
                      <v-alert v-if="err2" :value="true" type="error">{{
                        err2Msg
                      }}</v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="disabled2 == 1 ? true : false"
                        color="primary"
                        @click="savePermission()"
                      >
                        <v-icon left v-if="!savingPermission">save</v-icon>
                        {{ savingPermission ? "Processing..." : "Save" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions
              >Total Permissions:
              {{ permissionCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Roles and Permissions";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Roles and Permissions",
        subtitle: "View and manage your roles and permissions information.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Settings",
            disabled: false,
            href: "#",
          },
          {
            text: "Roles and Permissions",
            disabled: true,
            href: "roles-permissions",
          },
        ],
      },
      permissions: [],
      permissionCount: 0,
      permSearch: null,
      permHeaders: [{ text: "Title", valeu: "name" }],
      items: [],
      itemCount: 0,
      search: null,
      headers: [{ text: "Name", value: "name" }],
      fetching: false,
      disabled: 1,
      disabled2: 1,
      loading: true,
      loadingPermissions: true,
      savingRole: false,
      savingPermission: false,
      mrgn: "ma-4",
      error: false,
      errors: {},
      guard: "api",
      role_name: "",
      permission_name: "",
      response: "",
      err: false,
      errMsg: "",
      ss: false,
      ssMsg: "",
      err2: false,
      err2Msg: "",
      ss2: false,
      ss2Msg: "",
      roleRules: [(v) => !!v || "Role name is required"],
      permissionRules: [(v) => !!v || "Permission name is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
</style>
