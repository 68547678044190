<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-layout row wrap v-if="!isNull(appraisal_in_view)">
      <v-flex xs12 sm4>
        <v-card-title @click="goBack()">
          <span class="myFocus inner-header-subtitle">Go back</span>
        </v-card-title>
      </v-flex>
    </v-layout>

    <v-container fluid grid-list-md>
      <v-layout v-if="!isNull(appraisal_in_view)" row wrap>
        <v-tabs
          v-model="general_tab"
          background-color="primary accent-4"
          centered
          dark
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="mtab in general_tab_items"
            :key="mtab.title"
            :href="mtab.href"
            @click="openTabs(mtab)"
          >
            {{ mtab.title }}
            <v-icon>{{ mtab.icon }}</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="general_tab">
          <v-tab-item
            v-for="(mtab, i) in general_tab_items"
            :key="i"
            :value="'tab-' + i"
          >
            <v-card flat>
              <v-card-text>
                <div v-if="mtab.title == 'Scale Valuation'">
                  <Scalevaluation :Scalevaluation="ScalevaluationData" />
                </div>
                <div v-if="mtab.title == 'Parameters'">
                  <Parameters :Parameters="ParametersData" />
                </div>
                <div v-if="mtab.title == 'Present post questions'">
                  <Presentpostquestions
                    :Presentpostquestions="PresentpostquestionsData"
                  />
                </div>
                <div v-if="mtab.title == 'Career development questions'">
                  <Careerdevelopmentquestions
                    :Careerdevelopmentquestions="CareerdevelopmentquestionsData"
                  />
                </div>
                {{ mtab.data }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-layout>

      <v-layout v-if="isNull(appraisal_in_view)" row wrap>
        <v-flex xs12 sm12>
          <v-card>
            <v-card-title>
              <v-btn
                fab
                color="blue"
                class="white--text"
                @click="refreshTable()"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn fab color="secondary" @click="proceedToAdd()">
                <v-icon>add</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <h2>
                          <span class="myFocus" @click="viewDetail(item)">
                            {{ item.title }}
                          </span>
                        </h2>
                      </td>
                      <td>{{ item.starts }}</td>
                      <td>{{ item.ends }}</td>
                      <td class="myFocus">
                        <span
                          v-if="!isNull(item.scaleevaluations)"
                          @click="viewAppraisal(item)"
                        >
                          <v-icon>search</v-icon> View</span
                        >
                        <span v-else @click="addScaleItem(item)"> + Add </span>
                      </td>
                      <td>
                        <v-chip
                          class="ma-2"
                          :color="
                            item.status.name == 'Completed' ? 'red' : 'blue'
                          "
                          text-color="white"
                        >
                          {{ item.status.name }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          color="blue"
                          fab
                          small
                          dark
                          @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog v-model="addItemDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{ add_item_dialog_title }}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
              <v-icon left>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-card flat>
              <v-form ref="addItemForm" @submit.prevent="addItem">
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 sm12>
                      <v-text-field
                        name="title"
                        v-model="title"
                        label="Title"
                        type="text"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-dialog
                        ref="sdialog"
                        v-model="starts_dialog"
                        :return-value.sync="starts"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="starts"
                            label="Start Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          :min="minYear"
                          :max="maxYear"
                          v-model="starts"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="starts_dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.sdialog.save(starts)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                        ref="edialog"
                        v-model="ends_dialog"
                        :return-value.sync="ends"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="ends"
                            label="End Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            class="ml-5"
                            :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          :min="minYear"
                          :max="maxYear"
                          v-model="ends"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="ends_dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.edialog.save(ends)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm4>
                      <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Status"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <!-- v-if="saving" disabled -->
            <v-btn color="primary" x-large class="px-6" @click="addItem()">
              <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editItemDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
              <v-icon left>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-card flat>
              <v-form
                ref="editItemForm"
                @submit.prevent="editItemArray(item_in_view)"
              >
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 sm12>
                      <v-text-field
                        name="title"
                        v-model="title"
                        label="Title"
                        type="text"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-dialog
                        ref="sdialog"
                        v-model="starts_dialog"
                        :return-value.sync="starts"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="starts"
                            label="Start Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          :min="minYear"
                          :max="maxYear"
                          v-model="starts"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="starts_dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.sdialog.save(starts)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                        ref="edialog"
                        v-model="ends_dialog"
                        :return-value.sync="ends"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="ends"
                            label="End Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            class="ml-5"
                            :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          :min="minYear"
                          :max="maxYear"
                          v-model="ends"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="ends_dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.edialog.save(ends)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm4>
                      <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Status"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-autocomplete
                        name="ended_by"
                        v-model="ended_by"
                        :items="users_list"
                        item-text="name"
                        label="Ended By"
                        :rules="requiredRules"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                        ref="eedialog"
                        v-model="ended_on_dialog"
                        :return-value.sync="ended_on"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="ended_on"
                            label="Ended  On"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            class="ml-5"
                            :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          :min="minYear"
                          :max="maxYear"
                          v-model="ended_on"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="ended_on_dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.eedialog.save(ended_on)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <!-- v-if="saving" disabled -->
            <v-btn
              color="warning"
              x-large
              class="px-6"
              @click="editItemArray(item_in_view)"
            >
              <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="detailsDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="pt-2 pb-2 primary--text">
            <span> Detail </span>
            <v-spacer></v-spacer>
            <v-btn text @click="detailsDialog = false">
              <v-icon left>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>

          <v-card class="mx-auto">
            <v-list two-line subheader>
              <v-list-item v-for="item in details" :key="item.key">
                <v-list-item-avatar>
                  <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.value"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.key"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addScaleItemDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="pt-2 pb-2 primary--text">
            <span
              >Add Scale Evaluation ID:
              {{ item_in_view.id == 0 ? "Please wait.." : item_in_view.id }}
            </span>
            <v-spacer></v-spacer>
            <v-btn text @click="addScaleItemDialog = false">
              <v-icon left>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-card flat>
              <v-form
                ref="addScaleItemForm"
                @submit.prevent="addScaleEvalautionItem(item_in_view)"
              >
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-text-field
                        name="title"
                        v-model="title"
                        label="Title"
                        type="text"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm8>
                      <v-text-field
                        name="description"
                        v-model="description"
                        type="text"
                        label="description"
                        class="ml-5"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm6>
                      <v-autocomplete
                        name="point"
                        v-model="point"
                        :items="point_list"
                        item-text="name"
                        label="Choose Point"
                        :rules="requiredRules"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <!-- v-if="saving" disabled -->
            <v-btn
              color="primary"
              x-large
              class="px-6"
              @click="addScaleEvalautionItem(item_in_view)"
            >
              <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <Loader :LoaderDialog="LoaderData"></Loader>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import Loader from "@/components/Global/Loader/page";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";
import Scalevaluation from "./Scalevaluation/page";
import Parameters from "./Parameters/page";
import Presentpostquestions from "./Presentpostquestions/page";
import Careerdevelopmentquestions from "./Careerdevelopmentquestions/page";

export default {
  created() {
    document.title = "Staff Appraisal";
  },

  components: {
    InnerHeader,
    Scalevaluation,
    Loader,
    Parameters,
    Presentpostquestions,
    Careerdevelopmentquestions,
  },

  data() {
    return {
      addItemDialog: false,
      hrsubsidiary_id: 5,
      point: {},
      point_list: [
        { id: 1, name: 1 + " Point" },
        { id: 2, name: 2 + " Points" },
        { id: 3, name: 3 + " Points" },
        { id: 4, name: 4 + " Points" },
        { id: 5, name: 5 + " Points" },
      ],
      description: "",
      addScaleItemDialog: false,
      title: "",
      starts: "",
      starts_dialog: false,
      started_by: {},
      started_on: "",
      ends: "",
      ends_dialog: false,
      ended_by: {},
      ended_on: "",
      ended_on_dialog: false,
      status: {},
      status_list: [
        { id: 1, name: "Appraisal Queued" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Ongoing" },
        { id: 4, name: "Pending" },
        { id: 5, name: "Cancelled" },
      ],
      // LoaderData:{
      //   dialog: false,
      //   message: 'Confirming your current location'
      // },
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,

      add_item_dialog_title: "Add Performance Appraisal",
      headers: [
        { text: "Title", value: "title" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Scale Evaluation", value: "scale_evaluation" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: [],
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: this.addMonth(8),
      appraisal_in_view: {},
      LoaderData: {
        dialog: false,
        message: "Establishing secure connection..",
      },
      // PerfomanceappraisalData:{
      //   title: 'Perfomance Appraisal',
      //   dialog : false,
      //   button_loading : false,
      //   button_disabled : false,
      //   users_list: [],
      //   addItemDialog : false,
      //   data: [],
      // },
      CareerdevelopmentquestionsData: {
        title: "Career Development Questions",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        data: [],
      },
      PresentpostquestionsData: {
        title: "Staff Present post questions",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        data: [],
      },
      ScalevaluationData: {
        title: "Scale Valuation",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        data: [],
      },
      ParametersData: {
        title: "Parameters",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        data: [],
      },

      general_tab: null,
      info:
        "Always ensure that all your profiles are updated because your job appraisal depends on it",
      general_tab_items: [
        {
          title: "Parameters",
          href: "#tab-0",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Scale Valuation",
          href: "#tab-1",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Present post questions",
          href: "#tab-2",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Career development questions",
          href: "#tab-3",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
      ],
      user: this.$root.curuser,
      requiredRules: [(v) => !!v || "This field is required"],
      headerData: {
        title: "Performance Appraisal",
        subtitle: "Update your company appraisal",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/staff",
          },
          {
            text: "account",
            disabled: true,
            href: "account",
          },
        ],
      },
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
