import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;

      this.name = item.name;
      this.due_date = item.due_date;
      this.completion = item.completion;
      this.is_completed = item.is_completed;
      this.is_future = item.is_future;
      this.is_current = item.is_current;
      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.name = this.name;
        item.due_date = this.due_date;
        item.completion = this.completion;
        item.is_current = this.is_current;
        item.is_future = this.is_future;
        item.is_completed = this.is_completed;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              name: item.name,
              completion: item.completion,
              due_date: item.due_date,
              is_current: item.is_current.name,
              is_future: item.is_future.name,
              is_completed: item.is_completed.name,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrindividualdevelopmentplans/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted Development plan",
            title: "Development plan edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.name == "") {
        return false;
      }
      if (this.completion == "") {
        return false;
      }
      if (this.due_date == "") {
        return false;
      }

      if (isEmpty(this.is_current)) {
        return false;
      }
      if (isEmpty(this.is_future)) {
        return false;
      }
      if (isEmpty(this.is_completed)) {
        return false;
      }
      if (isEmpty(this.status)) {
        return false;
      }
      return true;
    },
    clearFields() {
      this.name = "";
      this.due_date = "";
      this.completion = "";
      this.status = {};
      this.is_completed = {};
      this.is_future = {};
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,
        name: this.name,
        due_date: this.due_date,
        completion: this.completion,
        is_current: this.is_current,
        is_future: this.is_future,
        is_completed: this.is_completed,
        status: this.status,
        hmo_plan: this.hmo_plan,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        name: item.name,
        due_date: item.due_date,
        completion: item.completion,
        is_current: item.is_current.name,
        is_future: item.is_future.name,
        is_completed: item.is_completed.name,
        hmo_plan: item.hmo_plan,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrindividualdevelopmentplans`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.name == item.name && data.completion == item.completion) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.name == item.name &&
                storedData.completion == item.completion
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added Development plan",
            title: "Development Plan addition",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.name == item.name && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrindividualdevelopmentplans`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let is_current = this.is_current_list.filter(
          (element) => q.is_current == element.name
        );
        let is_completed = this.is_completed_list.filter(
          (element) => q.is_completed == element.name
        );
        let is_future = this.is_future_list.filter(
          (element) => q.is_future == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.is_current = is_current.length > 0 ? is_current[0] : {};
        q.is_completed = is_completed.length > 0 ? is_completed[0] : {};
        q.is_future = is_future.length > 0 ? is_future[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllData();
    },
  },
};
