<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <h3 class="font-weight-light">
                {{ pageTitle }}
              </h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                text
                rounded
                color="blue-grey"
                class="mr-2"
                @click="refreshTable()"
              >
                <v-icon>refresh</v-icon> refresh
              </v-btn>
              <v-btn
                v-if="locations_list != {}"
                rounded
                dark
                outlined
                color="primary"
                @click="addItemDialog = true"
              >
                <v-icon>add</v-icon> New Record
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip class="ma-2" color="green" text-color="white">
                          <v-icon left>person</v-icon>
                          <h2>
                            <span
                              :class="
                                item.attendance != {}
                                  ? 'white--text myFocus'
                                  : ' '
                              "
                              @click="attendanceDialogPopup(item)"
                            >
                              {{
                                item.user.surname + " " + item.user.firstname
                              }}
                            </span>
                          </h2>
                        </v-chip>
                      </td>
                      <td>{{ item.user.email }}</td>
                      <td>{{ item.pfa }}</td>
                      <td>{{ item.pfa_pin }}</td>
                      <td>
                        <v-btn
                          color="blue"
                          fab
                          small
                          dark
                          @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="pfa"
                      v-model="pfa"
                      label="Pension Fund Administrator"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="pfa_pin"
                      v-model="pfa_pin"
                      type="text"
                      label="PFA PI"
                      class="ml-5"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit item dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="editItemDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm9>
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Name Of Truck"
                      type="text"
                      :rules="titleRules"
                      :value="title"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="plate_number"
                      v-model="plate_number"
                      type="text"
                      label="Plate No."
                      class="ml-5"
                      :value="plate_number"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="location"
                      v-model="location"
                      :items="locations_list"
                      item-text="name"
                      label="Location"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="vendor"
                      v-model="vendor"
                      :items="vendors_list"
                      item-text="name"
                      label="Vendor"
                      :rules="vendorRules"
                      return-object
                      class="ml-5"
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="vehicle_status"
                      v-model="vehicle_status"
                      :items="status_list"
                      item-text="name"
                      label="Vehicle Status"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm delete dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="attendanceDialog"
      persistent
      max-width="400"
    >
      <!-- {{item_in_view.attendance}} -->
      <v-date-picker v-model="attendance_dates" multiple color="primary">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="attendanceDialog = false"
          >Close</v-btn
        >
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Pension";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      pageTitle: "My Pension Account",
      pfa: "",
      pfa_pin: "",
      user: {},
      users_list: [],
      attendance_dates: [],
      attendanceDialog: false,
      attendance_data: [],
      sort_item_by_vendor_dialog_title: "Search by Vendor",
      sortItemByVendorDialog: false,
      search_location: "",
      search_vendor: "",
      sort_item_dialog_title: "Search Trucks by location",
      sortItemDialog: false,
      vendorViewDialog: false,
      vendor_in_view: {},
      vendors_list: [],
      status_list: this.$root.vehicle_states,
      locations_list: [],
      delete_item_dialog_title: "Delete a Truck",
      confirmDeleteDialog: false,
      item_in_view: {},
      add_item_dialog_title: "Add Staff Pension",
      edit_item_dialog_title: "Edit Pension",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Staff Pension",
        subtitle: "View and manage all staff pension",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Pension",
            disabled: true,
            href: "pension",
          },
        ],
      },
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      vehicle_status: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Staff Name", value: "staff_name" },
        { text: "Email", value: "email" },
        { text: "PFA", value: "pfa" },
        { text: "PIN", value: "pin" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: "2MB",
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      titleRules: [(v) => !!v || "Name of Truck is required"],
      plateNumberRules: [(v) => !!v || "Plate number is required"],
      departmentRules: [(v) => !!v || "Official department/unit is required"],
      locationRules: [(v) => !!v || "Location is required"],
      vendorRules: [(v) => !!v || "A Vendor is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
