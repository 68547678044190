<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light text-capitalize">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <h4 class="font-weight-light text-capitalize primary--text">
              {{ pageMessage }}
            </h4>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <span class="clickable" @click="viewDetail(item)">
                        {{ item.title }}
                      </span>
                    </td>
                    <!-- <td>{{ item.program }}</td> -->
                    <td>
                      {{ item.institution }}
                    </td>
                    <td>
                      <span v-html="naira"></span>{{ item.cost | formatNumber }}
                    </td>

                    <td>{{ item.certification_obtained }}</td>
                    <!-- <td>{{ item.remarks }}</td>
                    <td>{{ item.date_obtained }}</td> -->
                    <td>
                      <v-chip
                        class="ma-2"
                        color="teal darken-2"
                        text-color="white"
                        v-if="item.status.name == 'Completed'"
                      >
                        {{ item.status.name }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        color="orange darken-2"
                        text-color="white"
                        v-else
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <!-- <v-btn
                        class="mr-2"
                        color="orange darken-2"
                        rounded
                        outlined
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon> Update
                      </v-btn> -->
                      <v-btn
                        color="cyan darken-1"
                        rounded
                        outlined
                        small
                        dark
                        @click="viewDetail(item)"
                      >
                        <v-icon>visibility</v-icon> View
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Title"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="program"
                      v-model="program"
                      type="text"
                      label="Program"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="remarks"
                      v-model="remarks"
                      type="text"
                      label="Remarks"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="certification_obtained"
                      v-model="certification_obtained"
                      label="Certification obtained"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="date_obtained"
                      v-model="date_obtained"
                      type="text"
                      label="Date obtained"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="institution"
                      v-model="institution"
                      label="Institution"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm3>
                    <v-text-field
                      name="cost"
                      v-model="cost"
                      label="Cost"
                      class="ml-5"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm3>
                        <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        class="ml-5"
                        label="Status"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
              </v-col> -->
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          Training Detail
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">label</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.title"
                ></v-list-item-title>
                <v-list-item-subtitle> Title </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">view_day</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.program"
                ></v-list-item-title>
                <v-list-item-subtitle> Program Category </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">apartment</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.institution"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Institution Attended
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">school</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.certification_obtained"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Certification Obtained
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">chrome_reader_mode</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.remarks"
                ></v-list-item-title>
                <v-list-item-subtitle> Remarks </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">event_available</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.date_obtained"
                    ></v-list-item-title>
                    <v-list-item-subtitle> Date Obtained </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.status"
                    ></v-list-item-title>
                    <v-list-item-subtitle> Status </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded text @click="detailsDialog = false"> close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Training",
  props: ["Training"],
  data() {
    return {
      pageTitle: "My Trainings",
      pageMessage: "Contact HR Department To Schedule Your Training(s)",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      institution: "",
      date_obtained: "",
      remarks: "",
      cost: "",
      program: "",
      title: "",
      certification_obtained: "",
      qualification_obtained: "",
      status: { id: 1, name: "Pending" },
      status_list: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Suspended" },
      ],
      add_item_dialog_title: "Add a training",
      addItemDialog: this.Training.addItemDialog,
      headers: [
        { text: "Title", value: "title" },
        // { text: "Program", value: "program" },
        { text: "Institution", value: "institution" },
        { text: "Cost", value: "cost" },
        { text: "Certification Obtained", value: "certification_obtained" },
        // { text: "remarks", value: "remarks" },
        // { text: "date_obtained", value: "date_obtained" },
        { text: "Status", value: "status" },
        { text: "ACTIONS", value: "ID" },
      ],
      currentItem: [],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Training.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
