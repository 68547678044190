export default {
  methods: {
    prepTimeline() {
      this.$root.notifications_list.forEach((element) => {
        if (element.subtitle != undefined) {
          this.items.push(element);
        }
      });
    },
    init() {
      this.prepTimeline();
    },
  },
};
