import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    tabInfo() {
      return this.info;
    },
    openTabs(tab) {
      console.log(tab);
    },
    isNull(data) {
      return isEmpty(data);
    },
    loadAllUsers() {
      this.users_list = this.$root.users_list;

      this.QualificationData.users_list = this.users_list;
      this.BiographyData.users_list = this.users_list;
      this.GuarantorData.users_list = this.users_list;
      this.BeneficiariesData.users_list = this.users_list;
      this.NextofkinData.users_list = this.users_list;
      this.AddressesData.users_list = this.users_list;
      this.LastEmployerData.users_list = this.users_list;
      this.TrainingData.users_list = this.users_list;
      this.QueryData.users_list = this.users_list;
      this.HMODependantsData.users_list = this.users_list;
      this.DevelopmentPlanData.users_list = this.users_list;
      this.ExitsData.users_list = this.users_list;
      this.HMOData.users_list = this.users_list;
      this.AttendanceData.users_list = this.users_list;
      this.LoaderData.dialog = false;
    },
    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });

          this.QualificationData.users_list = this.users_list;
          this.BiographyData.users_list = this.users_list;
          this.GuarantorData.users_list = this.users_list;
          this.BeneficiariesData.users_list = this.users_list;
          this.NextofkinData.users_list = this.users_list;
          this.AddressesData.users_list = this.users_list;
          this.LastEmployerData.users_list = this.users_list;
          this.TrainingData.users_list = this.users_list;
          this.QueryData.users_list = this.users_list;
          this.HMODependantsData.users_list = this.users_list;
          this.DevelopmentPlanData.users_list = this.users_list;
          this.ExitsData.users_list = this.users_list;
          this.HMOData.users_list = this.users_list;
          this.AttendanceData.users_list = this.users_list;
          this.LoaderData.dialog = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    init() {
      this.loadAllUsers();
      //    this.getAllUsers()
    },
  },
};
