<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              text
              rounded
              color="blue-grey"
              class="mr-2"
              @click="refreshTable()"
            >
              <v-icon>refresh</v-icon> refresh
            </v-btn>
            <v-btn
              rounded
              dark
              outlined
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span class="myFocus" @click="viewDetail(item)">
                          {{ item.hospital }}
                        </span>
                      </h2>
                    </td>
                    <td>
                      <v-chip
                        class="ma-2"
                        :color="item.status.name == 'Rejected' ? 'red' : 'blue'"
                        text-color="white"
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="users_list"
                        item-text="name"
                        label="Choose a staff"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout> -->

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-select
                      name="providers"
                      v-model="provider"
                      :items="['Oceanic Health']"
                      item-text="name"
                      class="ml-5"
                      label="Providers"
                      :rules="requiredRules"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="hmo_id"
                      v-model="hmo_id"
                      type="text"
                      label="HMO ID number"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="plan_dialog"
                      :return-value.sync="plan"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="plan"
                          label="Plan date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="plan"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="plan_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(plan)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hospital"
                      v-model="hospital"
                      type="text"
                      label="Hospital"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="HMO Plan"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-select
                      name="providers"
                      v-model="provider"
                      :items="['Oceanic Health']"
                      item-text="name"
                      class="ml-5"
                      label="Providers"
                      :rules="requiredRules"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="hmo_id"
                      v-model="hmo_id"
                      type="text"
                      label="HMO ID number"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="plan_dialog"
                      :return-value.sync="plan"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="plan"
                          label="Plan date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="plan"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="plan_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(plan)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hospital"
                      v-model="hospital"
                      type="text"
                      label="Hospital"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "HMO",
  props: ["HMO"],
  data() {
    return {
      pageTitle: "My HMO",
      details: [],
      hr_ids: [],
      plan_dialog: false,
      plan: "",
      provider: "",
      hmo_id: "",
      hospital: "",
      approval_comment: "",
      approved_by: {},
      added_by: this.$root.curuser,

      leave_status_list: this.$root.leave_statuses,
      detailsDialog: false,
      user: this.$root.curuser,
      status: { },
      //status: { id: 3, name: "Basic" },
      status_list: [
        { id: 1, name: "Gold Plus" },
        { id: 2, name: "Gold" },
        { id: 3, name: "Basic" },
        //{ id: 4, name: "Completed" },
      ],
      edit_item_dialog_title: "",
      editItemDialog: false,
      add_item_dialog_title: "Add HMO",
      addItemDialog: this.HMO.addItemDialog,
      headers: [
        { text: "Hospital", value: "Hospital" },
        //  { text: "Date of Birth", value: "dob" },
        // { text: "Gender", value: "gender" },
        // { text: "Blood Group", value: "blood_group" },
        // { text: "Genotype", value: "Genotype" },
        // { text: "Hospital", value: "hospital" },
        // { text: "Attachments", value: "attachments" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        //    { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.HMO.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      // maxYear: new Date().toISOString().substr(0, 10),
      maxYear: this.addMonth(8),
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
