<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <v-layout row>
              <v-flex xs12 sm6>
                <v-btn color="secondary" @click="switchAddress('Residential')">
                  Residential Address
                </v-btn>
              </v-flex>
              <v-flex xs12 sm6>
                <v-btn color="primary" @click="switchAddress('Permanent')">
                  Permanent Address
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-title>
          <div v-if="active_button == 'Residential'">
            <Residential :Residential="ResidentialData" />
          </div>
          <div v-if="active_button == 'Permanent'">
            <Permanent :Permanent="PermanentData" />
          </div>
          <div v-else>
            {{ active_button }}
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
import Permanent from "./Permanent/page";
import Residential from "./Residential/page";

export default {
  name: "Addresses",
  props: ["Addresses"],
  components: {
    Permanent,
    Residential,
  },
  data() {
    return {
      addItemDialog: this.Addresses.addItemDialog,
      active_button: "Choose an address type",
      PermanentData: {
        title: "Permanent Address",
        users_list: this.Addresses.users_list,
        user: this.Addresses.user,
      },
      ResidentialData: {
        title: "Residential Address",
        users_list: this.Addresses.users_list,
        user: this.Addresses.user,
      },
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Addresses.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
