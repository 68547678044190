<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              rounded
              light
              color="white"
              class="mr-2"
              @click="refreshTable()"
            >
              <v-icon>refresh</v-icon> Refresh
            </v-btn>
            <v-btn
              rounded
              dark
              outlined
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td class="clickable" @click="viewDetail(item)">
                      {{ item.surname }}
                    </td>
                    <td class="clickable" @click="viewDetail(item)">
                      {{ item.othernames }}
                    </td>
                    <td>{{ item.phone_no }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.state }}</td>
                    <td>{{ item.country }}</td>
                    <!-- <td>{{ item.rel_type.name }}</td> -->
                    <td>
                      <v-btn
                        color="orange darken-2"
                        class="ma-1"
                        rounded
                        outlined
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon> Update
                      </v-btn>
                      <v-btn
                        color="blue-grey darken-2"
                        rounded
                        outlined
                        small
                        dark
                        @click="viewDetail(item)"
                      >
                        <v-icon>visibility</v-icon> View
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="surname"
                      v-model="surname"
                      label="Surname"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="othernames"
                      v-model="othernames"
                      type="text"
                      label="Othernames"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="phone_no"
                      v-model="phone_no"
                      type="number"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="state"
                      v-model="state"
                      type="text"
                      label="State"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="country"
                      v-model="country"
                      type="text"
                      label="Country"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm4>
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="profession"
                      v-model="profession"
                      type="text"
                      label="Profession"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text rounded @click="addItemDialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded color="primary" dark class="px-6" @click="addItem()">
            <v-icon left>save</v-icon> Save record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Edit Guarantor's Record</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="surname"
                      v-model="surname"
                      label="Surname"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="othernames"
                      v-model="othernames"
                      type="text"
                      label="Othernames"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="phone_no"
                      v-model="phone_no"
                      type="number"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="state"
                      v-model="state"
                      type="text"
                      label="State"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="country"
                      v-model="country"
                      type="text"
                      label="country"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm4>
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field
                      name="profession"
                      v-model="profession"
                      type="text"
                      label="Profession"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text rounded @click="editItemDialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <v-icon left>save</v-icon> Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          Guarantor's Detail
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">label</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentItem.surname }} {{ currentItem.othernames }}
                </v-list-item-title>
                <v-list-item-subtitle> Name </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">event_available</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ currentItem.phone_no }}
                    </v-list-item-title>
                    <v-list-item-subtitle> Phone Number </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ currentItem.rel_type }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Relationship Type
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">business_center</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentItem.profession }}
                </v-list-item-title>
                <v-list-item-subtitle> Profession </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentItem.address }} {{ currentItem.state }}
                  {{ currentItem.country }}
                </v-list-item-title>
                <v-list-item-subtitle> House Address </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded text @click="detailsDialog = false"> close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Guarantor",
  props: ["Guarantor"],
  data() {
    return {
      pageTitle: "My Guarantor's ",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      surname: "",
      othernames: "",
      phone_no: "",
      address: "",
      state: "",
      country: "",
      status: { id: 3, name: "Pending" },
      status_list: [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      rel_type: {},
      rel_type_list: [
        { id: 1, name: "Brother" },
        { id: 2, name: "Sister" },
        { id: 3, name: "Mother" },
        { id: 4, name: "Father" },
        { id: 5, name: "Uncle" },
        { id: 6, name: "Aunt" },
        { id: 7, name: "Niece" },
        { id: 8, name: "Nephew" },
        { id: 9, name: "Friend" },
        { id: 10, name: "Other" },
      ],
      profession: "",
      edit_item_dialog_title: "",
      editItemDialog: false,
      years_experience: "",
      email_address: "",
      phone_number: "",
      name: "",
      add_item_dialog_title: "Add a guarantor",
      addItemDialog: this.Guarantor.addItemDialog,
      headers: [
        { text: "Surname", value: "surname" },
        { text: "Othernames", value: "othernames" },
        { text: "Phone number", value: "phone_number" },
        { text: "Address", value: "address" },
        { text: "State", value: "state" },
        { text: "Country", value: "country" },
        { text: "ACTIONS", value: "ID" },
      ],
      currentItem: [],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Guarantor.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
