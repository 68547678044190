import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;
      this.institution = item.institution;
      this.qualification_obtained = item.qualification_obtained;
      this.date_obtained = item.date_obtained;
      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.institution = this.institution;
        item.date_obtained = this.date_obtained;
        item.qualification_obtained = this.qualification_obtained;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              institution: item.institution,
              date_obtained: item.date_obtained,
              qualification_obtained: item.qualification_obtained,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrqualifications/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted Qualification",
            title: "Qualification editted",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (isEmpty(this.department)) {
        return false;
      }
      if (isEmpty(this.job_title)) {
        return false;
      }
      if (isEmpty(this.job_grade)) {
        return false;
      }

      return true;
    },
    clearFields() {},
    // proceedToAddItem(){
    //     //don't add multiple entries
    //     if(this.items.length == 0)
    //     this.addItemDialog = true
    // },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,
        user_id: this.$root.curuser.id,
        dob: this.dob,
        maiden_name: this.maiden_name,
        mother_maiden_name: this.mother_maiden_name,
        pob: this.pob,
        gender: this.gender,
        user_height: this.user_height,
        marital_status: this.marital_status,
        blood_group: this.blood_group,
        genotype: this.genotype,
        nationality: this.nationality,
        soo: this.soo,
        lga: this.lga,
        town: this.town,
        entry_location: this.entry_location,
        number_children: this.number_children,
        department: this.department,
        department_id: this.department.id,
        date_employed: this.date_employed,
        date_exited: this.date_exited,
        // hrjobtitle_id: this.job_title.id,
        hrjobtitle_text: this.job_title,
        // hrjobgrade_id: this.job_grade.id,
        hrjobgrade_text: this.job_grade,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();

      delete item.user;
      delete item.gender;
      delete item.job_title;
      delete item.job_grade;
      delete item.marital_status;
      delete item.department;
      delete item.genotype;
      delete item.blood_group;

      item.gender = this.gender.name;
      item.genotype = this.genotype.name;
      item.marital_status = this.marital_status.name;
      item.job_grade = this.job_grade.name;
      item.job_title = this.job_title.name;
      item.blood_group = this.blood_group.name;
      this.addItemToAPI(item);
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrbiographies`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          //update the ID of the item
          if (
            data.department_id == item.department_id &&
            data.hrjobtitle_id == item.hrjobtitle_id
          ) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.department_id == item.department_id &&
                storedData.hrjobtitle_id == item.hrjobtitle_id
              ) {
                this.items[index].id = data.id;
                this.items[index].department = this.department;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added qualification",
            title: "Qualification added",
            sender_id: this.$root.curuser.id,
          };
          this.$refs.addItemForm.reset();
          this.addItemDialog = false;
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (
              element.department_id == item.department_id &&
              element.id == 0
            ) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
        });
    },
    async getAllData() {
      this.loading = true;
      await Axios.get(`${apiUrl}/hrbiographies`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async getAllJobTitles() {
      this.job_title_list = [];
      await Axios.get(`${apiUrl}/hrjobtitles`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((job_title) => {
            job_title.name = job_title.title;
            this.job_title_list.push(job_title);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllJobGrades() {
      this.job_grade_list = [];
      await Axios.get(`${apiUrl}/hrgrades`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((job_grade) => {
            job_grade.name = job_grade.title;
            this.job_grade_list.push(job_grade);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let department = this.$root.departments_list.filter(
          (element) => q.department_id == element.id
        );

        q.department = department.length > 0 ? department[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        if (this.$root.curuser.id == q.user.id) this.items.push(q);
      });
    },
    isNull(data) {
      return isEmpty(data);
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          console.log(response.data.message);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllData();
      this.getAllJobTitles();
      this.getAllJobGrades();
    },
  },
};
