<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <!-- <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <!-- <v-spacer></v-spacer>
              <v-btn fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
              </v-btn> -->
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span class="myFocus" @click="viewDetail(item)">
                          {{ item.surname }}
                        </span>
                      </h2>
                    </td>
                    <!-- <td width="10">
                        <v-chip
                          class="ma-2"
                          color="blue"
                          text-color="white"
                        >
                        {{ item.phone_no }}
                        </v-chip>
                        </td> -->
                    <!-- <td>{{ item.address }}</td> -->
                    <!-- <td>{{ item.state }}</td>
                      <td>{{ item.country }}</td> -->
                    <!-- <td>{{ item.rel_type.name }}</td>
                      <td>{{ item.profession }}</td>                                               -->
                    <td>
                      <v-chip
                        class="ma-2"
                        :color="item.status.name == 'Rejected' ? 'red' : 'blue'"
                        text-color="white"
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="surname"
                      v-model="surname"
                      label="Surname"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="othernames"
                      v-model="othernames"
                      type="text"
                      label="Othernames"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-text-field
                      name="phone_no"
                      v-model="phone_no"
                      type="text"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="state"
                      v-model="state"
                      type="text"
                      label="State"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="country"
                      v-model="country"
                      type="text"
                      label="country"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="profession"
                      v-model="profession"
                      type="text"
                      label="profession"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="surname"
                      v-model="surname"
                      label="Surname"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="othernames"
                      v-model="othernames"
                      type="text"
                      label="Othernames"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-text-field
                      name="phone_no"
                      v-model="phone_no"
                      type="text"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="state"
                      v-model="state"
                      type="text"
                      label="State"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="country"
                      v-model="country"
                      type="text"
                      label="country"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="profession"
                      v-model="profession"
                      type="text"
                      label="profession"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Guarantor",
  props: ["Guarantor"],
  data() {
    return {
      user_in_view: this.Guarantor.user,
      detailsDialog: false,
      details: [],
      hr_ids: [],
      surname: "",
      othernames: "",
      phone_no: "",

      state: "",
      country: "",
      status: {},
      status_list: [
        { id: 1, name: "Accepted" },
        { id: 2, name: "Rejected" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      rel_type: {},
      rel_type_list: [
        { id: 1, name: "Brother" },
        { id: 2, name: "Sister" },
        { id: 3, name: "Mother" },
        { id: 4, name: "Father" },
        { id: 5, name: "Uncle" },
        { id: 6, name: "Aunt" },
        { id: 7, name: "Niece" },
        { id: 8, name: "Nephew" },
        { id: 9, name: "Side Chick" },
        { id: 10, name: "Other" },
      ],
      profession: "",

      edit_item_dialog_title: "",
      editItemDialog: false,
      years_experience: "",
      email_address: "",
      phone_number: "",
      address: "",
      name: "",

      add_item_dialog_title: "Add a guarantor",
      addItemDialog: this.Guarantor.addItemDialog,
      headers: [
        { text: "Surname", value: "surname" },
        //    { text: "Othernames", value: "othernames" },
        // { text: "Phone number", value: "phone_number" },
        // { text: "Address", value: "address" },
        //  { text: "State", value: "state" },
        //  { text: "Country", value: "country" },
        //   { text: "Relationship", value: "rel_type" },
        //   { text: "Profession", value: "profession" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        //  { text: "Delete", value: "delete" },
      ],
      items: this.Guarantor.user.hrguarantors,
      itemCount: 0,
      search: null,
      users_list: this.Guarantor.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
