import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;
      this.name = item.name;
      this.proportion = item.proportion;
      this.rel_type = item.rel_type;
      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      if (item != null) {
        item.name = this.name;
        item.proportion = this.proportion;
        item.rel_type = this.rel_type;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              name: item.name,
              proportion: parseFloat(item.proportion),
              rel_type: item.rel_type.name,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrbeneficiaries/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted beneficiary",
            title: "Beneficiary edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },
    postData(CDDialog) {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }
      let data = {
        ValueOfCurrencyDistributed: this.ValueOfCurrencyDistributed,
        Date: this.date,
        CbnInstitutionCode: this.CbnInstitutionCode,
        OriginatingSortCode: this.OriginatingSortCode,
        DestinationSortCode: this.DestinationSortCode,
        Currency: this.currency,
        Denomination: this.denomination,
        CDDialog: CDDialog,
      };
      CDDialog.button_loading = true;
      CDDialog.button_disabled = true;
      this.$emit("postCurrencyDistribution", data);
    },
    validateForm() {
      if (this.name == "") {
        return false;
      }
      if (this.proportion == "") {
        return false;
      }
      if (isEmpty(this.status)) {
        return false;
      }
      if (isEmpty(this.rel_type)) {
        return false;
      }

      return true;
    },
    clearFields() {
      this.name = "";
      this.proportion = 0;
      this.status = {};
      this.rel_type = "";
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,
        name: this.name,
        proportion: this.proportion,
        status: this.status,
        rel_type: this.rel_type,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        name: item.name,
        proportion: parseFloat(item.proportion),
        status: item.status.name,
        rel_type: item.rel_type.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrbeneficiaries`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.name == item.name && data.proportion == item.proportion) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.name == item.name &&
                storedData.proportion == item.proportion
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added a beneficiary",
            title: "Beneficiary added",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.name == item.name && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrbeneficiaries`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let rel_type = this.rel_type_list.filter(
          (element) => q.rel_type == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.rel_type = rel_type.length > 0 ? rel_type[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.currentItem = {
        id: item.id,
        user_id: item.user_id,
        name: item.name,
        proportion: item.proportion,
        rel_type: item.rel_type.name,
        status: item.status.name,
      };
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllData();
    },
  },
};
