import Vue from "vue";
import Router from "vue-router";

import auth from "./middleware/auth";
//import { admin, finance, ermc, manager, cs, amh } from "./middleware/roles";
import { admin } from "./middleware/roles";

// import Dashboard from './views/Dashboard.vue'
import Dashboard from "./components/Dashboard/HR/Dashboard.vue";
import HOD_Dashboard from "./components/Dashboard/HOD/Dashboard.vue";
import Staff_Dashboard from "./components/Dashboard/staff/Dashboard.vue";
import Profile from "./components/Profile/Profile.vue";
import Login from "./components/Login/Login.vue";
import ResetPassword from "./components/ChangePassword/ResetPassword.vue";
import UserManagement from "./components/Setting/User/UserManagement.vue";
import RolesPermission from "./components/Setting/RolesPermission/RolesPermission.vue";
import Message from "./components/Message/Message.vue";
import Users from "./components/Users/Page.vue";
import Attendance from "./components/Attendance/Page.vue";
import Pension from "./components/Pension/Page.vue";
import Timeline from "./components/Timeline/Page.vue";
import Departments from "./components/Departments/Page.vue";
import HR_Leave from "./components/Leave/HR/Page.vue";
import Staff_Leave from "./components/Leave/staff/Page.vue";
import HOD_Leave from "./components/Leave/HOD/Page.vue";
import HR_Travels from "./components/Travels/HR/Page.vue";
import HOD_Travels from "./components/Travels/HOD/Page.vue";
import Staff_Travels from "./components/Travels/Staff/Page.vue";
import Staff_HMO from "./components/HMO/Staff/Page.vue";
import HOD_HMO from "./components/HMO/HOD/Page.vue";
import HR_HMO from "./components/HMO/HR/Page.vue";
import HR_Insurance from "./components/Insurance/HR/Page.vue";
import Staff_Insurance from "./components/Insurance/Staff/Page.vue";
import Staff_Account from "./components/Account/Staff/Page.vue";
import HR_Account from "./components/Account/HR/Page.vue";
import APPRAISAL_STAFF from "./components/Appraisal/Staff/Page.vue";
import APPRAISAL_HR from "./components/Appraisal/HR/Page.vue";
import APPRAISAL_HOD from "./components/Appraisal/HOD/Page.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/dashboard/hr",
      name: "dashboard",
      component: Dashboard,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/appraisal/staff",
      name: "appraisal_staff",
      component: APPRAISAL_STAFF,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/appraisal/hod",
      name: "appraisal_hod",
      component: APPRAISAL_HOD,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/appraisal/hr",
      name: "appraisal_hr",
      component: APPRAISAL_HR,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/departments",
      name: "departments",
      component: Departments,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/timeline",
      name: "timeline",
      component: Timeline,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/account/staff",
      name: "account",
      component: Staff_Account,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/account/hr",
      name: "account",
      component: HR_Account,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/dashboard/staff",
      name: "staff_dashboard",
      component: Staff_Dashboard,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/insurance/staff",
      name: "staff_insurance",
      component: Staff_Insurance,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/insurance/hr",
      name: "hr_insurance",
      component: HR_Insurance,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/hmo/staff",
      name: "Staff_HMO",
      component: Staff_HMO,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/hmo/hod",
      name: "HOD_HMO",
      component: HOD_HMO,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/hmo/hr",
      name: "HR_HMO",
      component: HR_HMO,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/travels/hr",
      name: "hr_travels",
      component: HR_Travels,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/travels/staff",
      name: "staff_travels",
      component: Staff_Travels,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/travels/hod",
      name: "hod_travels",
      component: HOD_Travels,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/dashboard/hod",
      name: "hod_dashboard",
      component: HOD_Dashboard,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "*",
      name: "404",
      meta: { layout: "Authentication" },
      component: require("@/components/General/404.vue").default,
    },
    {
      path: "/",
      name: "login",
      meta: { layout: "Authentication" },
      component: Login,
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      meta: { layout: "Authentication" },
      component: ResetPassword,
    },
    {
      path: "/profile/:id",
      name: "profile",
      component: Profile,
      meta: {
        middleware: [auth],
      },
    },

    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/staff-attendance",
      name: "attendance",
      component: Attendance,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/pension",
      name: "pension",
      component: Pension,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/leave/hr",
      name: "HR_Leave",
      component: HR_Leave,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/leave/staff",
      name: "Staff_Leave",
      component: Staff_Leave,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/leave/hod",
      name: "HOD_Leave",
      component: HOD_Leave,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/user-management",
      name: "user-management",
      component: UserManagement,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/message",
      name: "message",
      component: Message,
      meta: {
        middleware: [auth],
      },
    },

    {
      path: "/roles-permissions",
      name: "roles-permissions",
      component: RolesPermission,
      meta: {
        middleware: [auth, admin],
      },
    },
  ],
  // mode: "history",
  base: process.env.BASE_URL,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
