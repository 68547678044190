<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              text
              rounded
              color="blue-grey"
              class="mr-2"
              @click="refreshTable()"
            >
              <v-icon>refresh</v-icon> refresh
            </v-btn>
            <v-btn
              rounded
              dark
              outlined
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span class="myFocus" @click="viewDetail(item)">
                          {{ item.name }}
                        </span>
                      </h2>
                    </td>
                    <td>
                      <v-chip class="ma-2" color="blue" text-color="white">
                        {{ item.completion }}
                      </v-chip>
                    </td>
                    <!-- <td>{{ item.due_date }}</td>
                      <td>{{ item.is_current.name }}</td>                                              
                      <td>{{ item.is_future.name }}</td>                                              
                      <td>{{ item.is_completed.name }}</td>                                               -->
                    <!-- <td>{{ item.status.name }}</td>                                               -->
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Plan"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="completion"
                      v-model="completion"
                      type="text"
                      label="Completion"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_current"
                      v-model="is_current"
                      :items="is_current_list"
                      item-text="name"
                      label="Current?"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_future"
                      v-model="is_future"
                      :items="is_future_list"
                      item-text="name"
                      label="Future?"
                      class="ml-5"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_completed"
                      v-model="is_completed"
                      :items="is_completed_list"
                      item-text="name"
                      label="Completed?"
                      class="ml-5"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <!-- <v-flex xs12 sm4>
                  <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Status"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                </v-flex> -->

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="due_date_dialog"
                      :return-value.sync="due_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="due_date"
                          label="Due date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="due_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="due_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(due_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!--<v-flex xs12 sm4 class="ml-5">
                    <v-autocomplete
                      name="hmo_plan"
                      v-model="hmo_plan"
                      :items="['Basic', 'Gold', 'Gold Plus']"
                      item-text="name"
                      label="HMO plan"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>-->
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Plan"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="completion"
                      v-model="completion"
                      type="text"
                      label="Completion"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_current"
                      v-model="is_current"
                      :items="is_current_list"
                      item-text="name"
                      label="Current?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_future"
                      v-model="is_future"
                      :items="is_future_list"
                      item-text="name"
                      label="Future?"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="is_completed"
                      v-model="is_completed"
                      :items="is_completed_list"
                      item-text="name"
                      label="Completed?"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <!-- <v-flex xs12 sm4>
                  <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Status"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                </v-flex> -->

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="due_date_dialog"
                      :return-value.sync="due_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="due_date"
                          label="Due date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="due_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="due_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(due_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "DevelopmentPlan",
  props: ["DevelopmentPlan"],
  data() {
    return {
      pageTitle: "My Development Plans",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      name: "",
      completion: "",
      due_date: "",
      due_date_dialog: false,
      is_current: {},
      hmo_plan: "",
      is_current_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      is_completed: {},
      is_completed_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      is_future: {},
      is_future_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],

      status: { id: 3, name: "Pending" },
      status_list: [
        { id: 1, name: "Awaiting Approval" },
        { id: 2, name: "Cancelled" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      edit_item_dialog_title: "",
      editItemDialog: false,
      add_item_dialog_title: "Add a development Plan",
      addItemDialog: this.DevelopmentPlan.addItemDialog,
      headers: [
        { text: "Name", value: "name" },
        { text: "Completion", value: "completion" },
        // { text: "Due date", value: "due_date" },
        // { text: "Is current?", value: "is_current" },
        // { text: "Is future?", value: "is_future" },
        // { text: "Is completed?", value: "is_completed" },
        //     { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.DevelopmentPlan.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: this.addMonth(8),
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
      objRequiredRules: [(v) => !!v.name || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
