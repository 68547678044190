import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    searchItemsByVendor(vendor) {
      if (!isEmpty(vendor)) {
        this.sortItemByVendorDialog = false;
        this.items = [];
        this.loading = true;
        this.searchItemsByVendorInAPI(vendor);
      } else {
        alert("Choose a location");
      }
    },
    publishSearchTable(data) {
      data.forEach((itemm) => {
        itemm.tmsvehicles.forEach((item) => {
          //   alert(item.name)
          var location = null;
          this.locations_list.forEach((loc) => {
            if (loc.id == item.tmslocation_id) {
              location = loc;
            }
          });
          var vendor = null;
          this.vendors_list.forEach((vend) => {
            if (vend.id == item.tmsvendor_id) {
              vendor = vend;
            }
          });
          var row = {
            id: item.id,
            name: item.name,
            plate_number: item.plate_number,
            location: location,
            vendor: vendor,
            status: item.status,
          };
          this.items.push(row);
        });
      });
      this.loading = false;
    },
    searchItemsByVendorInAPI(vendor) {
      Axios.get(`${apiUrl}/tmsvendors/${vendor.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          this.publishSearchTable(data);
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Search Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    searchItemsByLocation(location) {
      if (!isEmpty(location)) {
        this.sortItemDialog = false;
        this.searchItemsByLocationInAPI(location);
      } else {
        alert("Choose a location");
      }
    },
    searchItemsByLocationInAPI(location) {
      this.items = [];
      this.loading = true;
      Axios.get(`${apiUrl}/tmslocations/${location.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          this.publishSearchTable(data);
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Search Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    viewVendor(vendor) {
      this.vendor_in_view = vendor;
      this.vendorViewDialog = true;
    },
    clearFields() {
      this.user = {};
      this.hod = {};
      this.releaved_by = {};
      this.no_days_taken = "";
      this.start_date = "";
      this.end_date = "";
      this.no_days_remaining = 0;
    },
    removeItemFromAPI(item) {
      Axios.delete(`${apiUrl}/tmsvehicles/${item.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Truck Deleted!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Delete Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    proceedToRemoveItem(item) {
      this.item_in_view = item;
      this.confirmDeleteDialog = true;
    },
    removeItem(item) {
      this.items.forEach((element, index) => {
        if (element == item) {
          this.removeItemFromAPI(item);
          this.items.splice(index, 1);
        }
      });
      this.confirmDeleteDialog = false;
    },
    updateItemOnAPI(item) {
      let input = {
        leave_id: item.id,
        //  "user_id": item.user.id,
        leave_type: item.leave_type.name,
        leave_year: item.leave_year.name,
        booked_month: item.booked_month.name,
        no_days_entitled: item.no_days_entitled,
        no_days_taken: item.no_days_taken,
        no_days_remaining: item.no_days_remaining,
        start_date: item.start_date,
        end_date: item.end_date,
        hod: item.hod.id,
        //  "hod_approved": item.hod_approved.name,
        hod_comment: item.hod_comment,
        releaved_by: item.releaved_by.id,
        // "leave_advice": item.leave_advice,
        // "leave_advice_by": item.leave_advice_by.id,
        // "leave_advice_on": item.leave_advice_on,
        // "resumed_on": item.resumed_on,
        // "resumption_confirmed_by": item.resumption_confirmed_by.id,
        // "resumption_confirmed_on": item.resumption_confirmed_on,
        // "status": item.status.name,
        // "created_at": "2020-07-02 14:28:43",
        // "updated_at": "2020-07-02 14:28:43",
      };
      Axios.put(`${apiUrl}/hrleaves/${item.id}`, input, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }

      //let item = this.items.filter(element=>element.id === itemm.id)
      if (item != null) {
        //    item.id = itemm.id
        // item.user = this.user
        item.hod = this.hod;
        item.leave_type = this.leave_type;
        item.releaved_by = this.releaved_by;
        item.start_date = this.start_date;
        item.end_date = this.end_date;
        item.no_days_taken = this.no_days_taken;
        item.no_days_remaining = this.no_days_remaining;
        item.booked_month = this.booked_month;
        // item.hod_approved = this.hod_approved
        // item.hod_comment = this.hod_comment
        // item.leave_advice = this.leave_advice
        item.leave_year = this.leave_year;
        // item.leave_advice_by = this.leave_advice_by
        // item.resumption_confirmed_by = this.resumption_confirmed_by
        // item.leave_advice_on = this.leave_advice_on
        // item.resumed_on = this.resumed_on
        // item.resumption_confirmed_on = this.resumption_confirmed_on
        // item.status = this.leave_status
        item.no_days_entitled = this.no_days_entitled;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;
            this.updateItemOnAPI(item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    editItem(item) {
      this.item_in_view = item;
      //  this.user = item.user
      this.hod = item.hod;
      this.releaved_by = item.releaved_by;
      this.start_date = item.start_date;
      this.end_date = item.end_date;
      this.no_days_taken = item.no_days_taken;
      this.no_days_remaining = item.no_days_remaining;
      this.leave_type = item.leave_type;
      this.booked_month = item.booked_month;
      //  this.hod_approved = item.hod_approved
      //  this.hod_comment = item.hod_comment
      //  this.leave_advice = item.leave_advice
      this.leave_year = item.leave_year;
      //  this.leave_advice_by = item.leave_advice_by
      //  this.resumption_confirmed_by = item.resumption_confirmed_by
      // this.leave_advice_on = item.leave_advice_on
      // this.resumed_on = item.resumed_on
      // this.resumption_confirmed_on = item.resumption_confirmed_on
      // this.leave_status = item.status
      this.no_days_entitled = item.no_days_entitled;
      this.editItemDialog = true;
    },
    validateForm() {
      if (this.end_date == "") {
        return false;
      }
      if (this.start_date == "") {
        return false;
      }
      if (this.no_days_taken == "") {
        return false;
      }
      if (this.no_days_entitled == "") {
        return false;
      }
      if (this.no_days_remaining == "") {
        return false;
      }
      // if(isEmpty(this.user)){
      //     return false
      // }
      if (isEmpty(this.hod)) {
        return false;
      }
      if (isEmpty(this.releaved_by)) {
        return false;
      }
      if (isEmpty(this.leave_type)) {
        return false;
      }
      if (isEmpty(this.leave_year)) {
        return false;
      }
      if (isEmpty(this.booked_month)) {
        return false;
      }
      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.user,
        hod: this.hod,
        leave_type: this.leave_type,
        releaved_by: this.releaved_by,
        start_date: this.start_date,
        leave_year: this.leave_year.name,
        end_date: this.end_date,
        booked_month: this.booked_month,
        no_days_remaining: this.no_days_remaining,
        no_days_entitled: this.no_days_entitled,
        no_days_taken: this.no_days_taken,
        created_at: "Just Now",
        updated_at: "Just Now",
        status: "Awaiting Approvall",
        resumption_confirmed_on: "",
        resumed_on: "",
        leave_advice_on: "",
        resumption_confirmed_by: null,
        leave_advice_by: null,
        leave_advice: null,
        hod_comment: "",
        hod_approved: null,
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        leave_type: item.leave_type.name,
        leave_year: item.leave_year,
        booked_month: item.booked_month.name,
        no_days_entitled: item.no_days_entitled,
        no_days_taken: item.no_days_taken,
        start_date: item.start_date,
        end_date: item.end_date,
        releaved_by: item.releaved_by.id,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrleaves`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (
            data.start_date == item.start_date &&
            data.end_date == item.end_date
          ) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.start_date == item.start_date &&
                storedData.releaved_by.id == item.releaved_by
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Leave Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (
              element.start_date == item.start_date &&
              element.end_date == item.end_date &&
              element.id == 0
            ) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    publishTable(data) {
      data.forEach((leave) => {
        let user = this.users_list.filter(
          (element) => leave.user_id == element.id
        );
        let hod = this.users_list.filter((element) => leave.hod == element.id);
        let releaved_by = this.users_list.filter(
          (element) => element.id == leave.releaved_by
        );
        let leave_advice_by = this.users_list.filter(
          (element) => element.id == leave.leave_advice_by
        );
        let resumption_confirmed_by = this.users_list.filter(
          (element) => element.id == leave.resumption_confirmed_by
        );
        let booked_month = this.booked_months_list.filter(
          (month) => month.name == leave.booked_month
        );
        let leave_year = this.leave_years_list.filter(
          (year) => year.name == leave.leave_year
        );
        let leave_type = this.leave_types_list.filter(
          (type) => type.name == leave.leave_type
        );
        let status = this.status_list.filter(
          (statuss) => statuss.name == leave.status
        );

        let item = {};
        item.id = leave.id;
        item.user = user[0];
        item.hod = hod[0];
        item.leave_type = leave_type[0];
        item.releaved_by = releaved_by[0];
        item.start_date = leave.start_date;
        item.end_date = leave.end_date;
        item.no_days_taken = leave.no_days_taken;
        item.no_days_remaining = leave.no_days_remaining;
        item.booked_month = booked_month[0];
        item.hod_approved = leave.hod_approved;
        item.hod_comment = leave.hod_comment;
        item.leave_advice = leave.leave_advice;
        item.leave_year = leave_year[0];
        item.leave_advice_by = leave_advice_by[0];
        item.resumption_confirmed_by = resumption_confirmed_by[0];
        item.leave_advice_on = leave.leave_advice_on;
        item.resumed_on = leave.resumed_on;
        item.resumption_confirmed_on = leave.resumption_confirmed_on;
        item.status = status[0];
        item.created_at = leave.created_at;
        item.updated_at = leave.updated_at;
        item.no_days_entitled = leave.no_days_entitled;
        if (this.$root.curuser.id == item.user.id) {
          console.log(item.releaved_by);
          this.items.push(item);
        }
      });
    },
    detailsDialogPopup(item) {
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.getAllUsers();
    },
    async getAllData() {
      //   alert(JSON.stringify(this.$root.curuser.id))
      //   console.log(this.$root.curuser)
      await Axios.get(`${apiUrl}/hrleaves`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });
          this.getAllData();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
};
