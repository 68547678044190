import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    isNull(data) {
      return isEmpty(data);
    },
    editItem(item) {
      this.item_in_view = item;
      this.title = item.title;
      this.description = item.description;
      this.from_location = item.from_location;
      this.date_invited = item.date_invited;
      this.appeared = item.appeared;
      this.panel_members = item.panel_members;
      this.panel_decision = item.panel_decision;
      this.management_decision = item.management_decision;
      this.invited_by = item.invited_by;
      this.invited_on = item.invited_on;

      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.title = this.title;
        item.description = this.description;
        item.from_location = this.from_location;
        item.date_invited = this.date_invited;
        item.appeared = this.appeared;
        item.panel_members = this.panel_members;
        item.panel_decision = this.panel_decision;
        item.management_decision = this.management_decision;
        item.invited_by = this.invited_by;
        item.invited_on = this.invited_on;

        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,

              title: item.title,
              description: item.description,
              from_location: item.from_location,
              date_invited: item.date_invited,
              appeared: item.appeared.name,
              panel_members: item.panel_members,
              panel_decision: item.panel_decision,
              management_decision: item.management_decision,
              invited_by: item.invited_by.id,
              invited_on: item.invited_on,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrdisciplinaries/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);

          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " marked your disciplinary as " +
              item.status,
            title: "Disciplinary update from HR",
            sender_id: this.$root.curuser.id,
          };
          let receiver_ids = [];
          receiver_ids.push(this.user_in_view.id);
          notification_data.receiver_ids = receiver_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.title == "") {
        return false;
      }
      if (this.description == "") {
        return false;
      }
      if (this.from_location == "") {
        return false;
      }
      if (this.date_invited == "") {
        return false;
      }
      if (this.invited_on == "") {
        return false;
      }
      if (isEmpty(this.status)) {
        return false;
      }
      if (isEmpty(this.appeared)) {
        return false;
      }
      return true;
    },
    clearFields() {
      this.title = "";
      this.description = "";
      this.management_decision = "";
      this.panel_decision = "";
      this.panel_members = "";
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.Disciplinary.user,
        title: this.title,
        description: this.description,
        from_location: this.from_location,
        date_invited: this.date_invited,
        appeared: this.appeared,
        panel_members: this.panel_members,
        panel_decision: this.panel_decision,
        management_decision: this.management_decision,
        invited_by: this.invited_by,
        status: this.status,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        staff_id: item.user.id,
        title: item.title,
        description: item.description,
        from_location: item.from_location,
        date_invited: item.date_invited,
        appeared: item.appeared.name,
        panel_members: item.panel_members,
        panel_decision: item.panel_decision,
        management_decision: item.management_decision,
        invited_by: item.invited_by.id,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrdisciplinaries`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.surname == item.surname && data.phone_no == item.phone_no) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.surname == item.surname &&
                storedData.phone_no == item.phone_no
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });

          let notification_data = {
            message: "You have been summoned to the disciplianry committee",
            title: "Disciplinary Invitation",
            sender_id: this.$root.curuser.id,
          };
          let receiver_ids = [];
          receiver_ids.push(this.user_in_view.id);
          notification_data.receiver_ids = receiver_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.surname == item.surname && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrdisciplinaries`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let invited_by = this.users_list.filter(
          (element) => q.invited_by == element.id
        );
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let appeared = this.appeared_list.filter(
          (element) => q.appeared == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        q.invited_by = invited_by.length > 0 ? invited_by[0] : {};
        q.appeared = appeared.length > 0 ? appeared[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        //for unknown reasons, objects are repeated... lets flush duplicates
        if (!this.items.includes(q)) this.items.push(q);
      });
    },
    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },

    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },

    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      console.log(this.Disciplinary.user.hrdisciplinaries);
      this.publishTable(this.Disciplinary.user.hrdisciplinaries);
      //    this.getAllData()
    },
  },
};
