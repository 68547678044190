<template>
  <div>
    <v-row>
      <v-col cols="12" sm="7">
        <div class="inner-header-title">
          <h2
            class="primary--text font-weight-light text-capitalize"
            v-text="headerData.title"
          ></h2>
        </div>
        <div class="inner-header-subtitle">
          <h4 class="font-weight-light" v-text="headerData.subtitle"></h4>
        </div>
      </v-col>
      <v-col cols="12" sm="5" class="float-right">
        <v-breadcrumbs :items="headerData.breadcrumbLinks"></v-breadcrumbs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "InnerHeader",
  props: ["headerData"],
};
</script>
