<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid class="min-height px-6 py-3">
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="12" sm="6" v-for="link in gridLinks" :key="link.route">
              <v-card shaped :to="link.route" class="infobox">
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="pa-3">
                      <v-avatar size="100" class="mr-3">
                        <img
                          v-if="link.image != null"
                          :src="link.image"
                          width="200"
                        />
                        <img v-else :src="userAvatar" width="200" />
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8" class="text-center pa-3 mt-4">
                    <h1 class="font-weight-light">{{ link.name }}</h1>
                    CLICK TO VIEW
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-card class="mt-3">
            <v-card-title class="text-uppercase font-weight-light">
              <v-icon left>fas fa-newspaper</v-icon>
              Information Center
            </v-card-title>
            <v-card-text style="max-height: 600px; overflow-y: auto">
              <div v-for="(item, i) in notifications_list" :key="i">
                <v-divider class="blue-grey lighten-4 my-2"></v-divider>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-avatar size="30" class="mr-3">
                      <img :src="item.avatar" width="30" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" sm="10"> {{ item.title }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span v-html="item.message"></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <small>By: {{ item.sentBy }}</small>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <small> Date: {{ item.time }} </small>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Scripts
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "Application Console";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      notifications_list: [],
      users_list: this.$root.users_list,
      gridLinks: [
        {
          name: "My Account",
          icon: "fa-user-tie",
          image: null,
          route: "/account/staff",
        },
        {
          name: "Appraisal",
          icon: "fas fa-award",
          image: require("@/assets/icons/appraisal.png"),
          route: "/appraisal/staff",
        },
        {
          name: "Travels",
          icon: "fas fa-route",
          image: require("@/assets/icons/travel.png"),
          route: "/travels/staff",
        },
        {
          name: "Leave",
          icon: "fas fa-suitcase-rolling",
          image: require("@/assets/icons/leave.png"),
          route: "/leave/staff",
        },
        {
          name: "Pension",
          icon: "fas fa-blind",
          image: require("@/assets/icons/pension.png"),
          route: "/pension",
        },
        {
          name: "Activity",
          icon: "fas fa-newspaper",
          image: require("@/assets/icons/newsfeed.png"),
          route: "/timeline",
        },
      ],
      testAvatar: require("@/assets/insurance.jpg"),
      headerData: {
        title: "Dashboard",
        subtitle: "Manage all HR activities all in one place",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
        ],
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      user_id: this.$root.curuser.id,
      userAvatar: this.$root.curuser.avatar_id,
      user_role: this.$root.curuserroles[0],
      supportHeader: "Contact Support",
      supportContacts: [
        {
          name: "Ibeh Ernest",
          phone: "08020689069",
          email: "e.ibeh@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "John Doe",
          phone: "07120689068",
          email: "j.doe@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "Jane Shinene",
          phone: "07020689067",
          email: "j.shinene@icms.ng",
          avatar: "avatar1.png",
        },
      ],
      loading: false,
      ss: false,
      err: false,
      ssMsg: "",
      errMsg: "",
      error: false,
      errors: {},
      response: "",
      senderSurname: this.$root.curuser.surname,
      senderFirstname: this.$root.curuser.firstname,
      senderEmail: this.$root.curuser.email,
      fullname: "",
      message: "",
    };
  },

  mixins: [mounted, methods],
};
</script>
<style>
.rounded-card {
  border-radius: 30px;
  cursor: pointer;
}
</style>
