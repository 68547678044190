import {
  apiUrl,
  //isEmpty,
  capitalize,
  //,timeNow,todayDate
} from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;
      this.question = item.question;
      this.added_by = item.added_by;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      this.$refs.editItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }
      if (item != null) {
        item.question = this.question;
        let put = {
          id: item.id,
          question: item.question,
        };
        this.updateItemOnAPI(put);
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/presentpostquestions/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.init();
    },
    validateForm() {
      if (this.question == "") {
        return false;
      }
      // if(isEmpty(this.added_by)){
      //     return false
      // }
      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }
      let item = {
        //    id: 0,
        added_by: this.$root.curuser,
        question: this.question,
        //    performanceappraisal_id: this.Parameters.data.id
      };

      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        added_by: item.added_by.id,
        question: item.question,
      };
      this.addItemToAPI(new_item);
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/presentpostquestions`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.question == item.question) {
            this.items.forEach((storedData, index) => {
              if (storedData.question == item.question) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.question == item.question) {
              //&& element.id == 0
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },

    async getAllData() {
      await Axios.get(`${apiUrl}/presentpostquestions`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    publishTable(data) {
      data.forEach((q) => {
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    proceedToAdd() {
      this.addItemDialog = true;
    },

    init() {
      this.items = [];
      this.loading = true;
      this.getHR_Ids();
      this.getAllData();
    },
  },
};
