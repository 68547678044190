import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    isNull(data) {
      return isEmpty(data);
    },
    viewVendor(vendor) {
      this.vendor_in_view = vendor;
      this.vendorViewDialog = true;
    },
    clearFields() {
      this.user = {};
      //   this.added_on = ''
      this.hrtraveleligibilitygroup = {};
      this.expensetype = {};
      this.duration = {};
      this.number_nights = {};
      this.title = "";
      this.description = "";
      this.destination = "";
      this.hotel = "";
      this.hotel_cost = "";
      this.total_cost = "";
      this.transportation_cost = "";
      this.pocket_money = "";
      this.pocket_money = "";
    },
    removeItemFromAPI(item) {
      Axios.delete(`${apiUrl}/tmsvehicles/${item.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Truck Deleted!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Delete Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    proceedToRemoveItem(item) {
      this.item_in_view = item;
      this.confirmDeleteDialog = true;
    },
    removeItem(item) {
      this.items.forEach((element, index) => {
        if (element == item) {
          this.removeItemFromAPI(item);
          this.items.splice(index, 1);
        }
      });
      this.confirmDeleteDialog = false;
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrtravelexpenses/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.user = this.user;
        item.duration = this.duration;
        item.total_cost = this.total_cost;
        item.pocket_money = this.pocket_money;
        item.transportation_cost = this.transportation_cost;
        item.number_nights = this.number_nights;
        item.hotel_cost = this.hotel_cost;
        item.hotel = this.hotel;
        item.destination = this.destination;
        item.description = this.description;
        item.title = this.title;
        item.approved_on = this.approved_on;
        item.approval_comment = this.approval_comment;
        // item.added_on = this.added_on
        // item.added_by = item.added_by
        item.hrtraveleligibilitygroup = this.hrtraveleligibilitygroup;
        item.expensetype = this.expensetype;
        item.approved_by = this.approved_by;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              title: item.title,
              description: item.description,
              hrtraveleligibilitygroup_id: item.hrtraveleligibilitygroup.id,
              expensetype_id: item.expensetype.id,
              destination: item.destination,
              duration: item.duration.name,
              hotel: item.hotel,
              number_nights: item.number_nights.name,
              hotel_cost: parseFloat(item.hotel_cost),
              transportation_cost: parseFloat(item.transportation_cost),
              pocket_money: parseFloat(item.pocket_money),
              total_cost: parseFloat(item.total_cost),
              added_by: item.added_by.id,
              added_on: item.added_on,
              approved_on: item.approved_on,
              status: item.status.name,
              approved_by: item.approved_by.id,

              approval_comment: item.approval_comment,
              // "created_at": "2020-02-26 03:16:32",
              // "updated_at": "2020-02-26 03:16:32",
              // "user": null
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    editItem(item) {
      this.item_in_view = item;
      this.user = item.user;
      this.approved_on = item.approved_on;
      this.approval_comment = item.approval_comment;
      this.added_by = item.added_by;
      this.added_on = item.added_on;
      this.title = item.title;
      this.description = item.description;
      this.destination = item.destination;
      this.hotel = item.hotel;
      this.hotel_cost = item.hotel_cost;
      this.number_nights = item.number_nights;
      this.transportation_cost = item.transportation_cost;
      this.pocket_money = item.pocket_money;
      this.total_cost = item.total_cost;
      this.hrtraveleligibilitygroup = item.hrtraveleligibilitygroup;
      this.expensetype = item.expensetype;
      this.duration = item.duration;
      this.created_at = item.created_at;
      this.updated_at = item.updated_at;
      this.approved_by = item.approved_by;
      this.status = item.status;
      this.editItemDialog = true;
    },
    validateForm() {
      if (this.added_on == "") {
        return false;
      }
      if (this.title == "") {
        return false;
      }
      if (this.description == "") {
        return false;
      }
      if (this.destination == "") {
        return false;
      }
      if (this.hotel == "") {
        return false;
      }
      if (this.hotel_cost == "") {
        return false;
      }
      if (this.transportation_cost == "") {
        return false;
      }
      if (this.pocket_money == "") {
        return false;
      }
      if (this.total_cost == "") {
        return false;
      }

      if (isEmpty(this.number_nights)) {
        return false;
      }
      if (isEmpty(this.user)) {
        return false;
      }
      if (isEmpty(this.hrtraveleligibilitygroup)) {
        return false;
      }
      if (isEmpty(this.expensetype)) {
        return false;
      }
      if (isEmpty(this.duration)) {
        return false;
      }

      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        added_by: this.added_by,
        added_on: this.added_on,
        user: this.user,
        title: this.title,
        description: this.description,
        destination: this.destination,
        hotel: this.hotel,
        hotel_cost: this.hotel_cost,
        number_nights: this.number_nights,
        transportation_cost: this.transportation_cost,
        pocket_money: this.pocket_money,
        total_cost: this.total_cost,
        hrtraveleligibilitygroup: this.hrtraveleligibilitygroup,
        expensetype: this.expensetype,
        duration: this.duration,
        created_at: "Just Now",
        updated_at: "Just Now",
        status: this.status,
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        staff_id: item.user.id,
        title: item.title,
        description: item.description,
        hrtraveleligibilitygroup_id: item.hrtraveleligibilitygroup.id,
        expensetype_id: item.expensetype.id,
        destination: item.destination,
        duration: item.duration.name,
        hotel: item.hotel,
        number_nights: item.number_nights.name,
        hotel_cost: item.hotel_cost,
        transportation_cost: item.transportation_cost,
        pocket_money: item.pocket_money,
        total_cost: item.total_cost,
        added_by: item.added_by.id,
        added_on: item.added_on,
        status: item.status.name,
        // "approved_by": null,
        // "approved_on": null,
        // "approval_comment": null,
        // "created_at": "2020-02-26 03:16:32",
        // "updated_at": "2020-02-26 03:16:32",
        // "user": null
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrtravelexpenses`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (
            data.title == item.title &&
            data.description == item.description
          ) {
            this.items.forEach((storedData, index) => {
              if (storedData.title == item.title) {
                //&& storedData.releaved_by.id == item.releaved_by
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Leave Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (
              element.title == item.title &&
              element.description == item.description &&
              element.id == 0
            ) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    publishTable(data) {
      data.forEach((travel) => {
        let user = this.users_list.filter(
          (element) => travel.user_id == element.id
        );
        let added_by = this.users_list.filter(
          (element) => travel.added_by == element.id
        );
        let approved_by = this.users_list.filter(
          (element) => travel.approved_by == element.id
        );
        let status = this.status_list.filter(
          (statuss) => statuss.name == travel.status
        );
        let hrtraveleligibilitygroup = this.hrtraveleligibilitygroup_list.filter(
          (el) => el.id == travel.hrtraveleligibilitygroup_id
        );
        let expensetype = this.expensetype_list.filter(
          (el) => el.id == travel.expensetype_id
        );
        let duration = this.duration_list.filter(
          (el) => el.name == travel.duration
        );
        let number_nights = this.number_nights_list.filter(
          (el) => el.name == travel.number_nights
        );

        let item = {};
        item.id = travel.id;
        item.title = travel.title;
        item.description = travel.description;
        item.destination = travel.destination;
        item.hotel = travel.hotel;
        item.approved_on = travel.approved_on;
        item.approval_comment = travel.approval_comment;
        item.created_at = travel.created_at;
        item.updated_at = travel.updated_at;
        item.added_on = travel.added_on;
        item.hotel_cost = travel.hotel_cost;
        item.transportation_cost = travel.transportation_cost;
        item.pocket_money = travel.pocket_money;
        item.total_cost = travel.total_cost;
        item.hrtraveleligibilitygroup =
          hrtraveleligibilitygroup.length > 0
            ? hrtraveleligibilitygroup[0]
            : {};
        item.added_by = added_by.length > 0 ? added_by[0] : {};
        item.approved_by = approved_by.length > 0 ? approved_by[0] : {};
        item.expensetype = expensetype.length > 0 ? expensetype[0] : {};
        item.duration = duration.length > 0 ? duration[0] : {};
        item.number_nights = number_nights.length > 0 ? number_nights[0] : {};
        item.status = status.length > 0 ? status[0] : {};
        item.user = user.length > 0 ? user[0] : {};
        this.items.push(item);
      });
    },
    detailsDialogPopup(item) {
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.getAllUsers();
    },
    async getAllData() {
      //   alert(JSON.stringify(this.$root.curuser.id))
      //   console.log(this.$root.curuser)
      await Axios.get(`${apiUrl}/hrtravelexpenses`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });
          this.getAllData();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getAllEligibilityGroups() {
      await Axios.get(`${apiUrl}/hrtraveleligibilitygroups`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.hrtraveleligibilitygroup_list = response.data.data.reverse();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    //     async getAllExpenseTypes() {
    //         await Axios.get(
    //             `${apiUrl}/expensetypes`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${this.$root.auth}`
    //                 }
    //             }
    //                 .then((response) => {
    //                     this.expensetype_list = response.data.reverse();
    //                 })
    //                 .catch(({ response }) => {
    //                     console.log(response);
    //                 })
    // },
    setDurationList() {
      for (let k = 1; k < 200; k++) {
        let row = {};
        row.id = k;
        row.name = k + " day(s)";
        this.duration_list.push(row);
      }
    },
    setNumberOfNightsList() {
      for (let k = 1; k < 100; k++) {
        let row = {};
        row.id = k;
        row.name = k;
        this.number_nights_list.push(row);
      }
    },
    init() {
      this.setNumberOfNightsList();
      this.setDurationList();
      this.getAllEligibilityGroups();
      this.getAllExpenseTypes();
      this.getAllUsers();
    },
  },
};
