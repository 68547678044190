import Axios from "axios";
import { apiUrl } from "@/config";

export default {
  methods: {
    getAllRoles(user_data) {
      this.LoaderData.dialog = true;
      this.LoaderData.message = "We are getting everything ready.";
      Axios.get(`${apiUrl}/roles/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.getAllBiographies(data, user_data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    login() {
      if (this.$refs.loginform.validate()) {
        this.loading = true;
        Axios.post(`${apiUrl}/login`, {
          email: this.email,
          password: this.password,
        })
          .then((response) => {
            this.loading = false;
            this.$root.authenticated = true;
            localStorage.setItem("authenticated", true);
            this.$root.auth = response.data.token;
            // console.log("********** TOKEN ************");
            // console.log(response.data.token);
            localStorage.setItem("auth", JSON.stringify(response.data.token));
            this.$root.curuser = response.data.user[0];
            localStorage.setItem(
              "curuser",
              JSON.stringify(response.data.user[0])
            );
            this.$root.curuseravatar = response.data.avatar;
            localStorage.setItem(
              "curuseravatar",
              JSON.stringify(response.data.avatar)
            );
            this.$root.curuseravatarbig = response.data.cardImage;
            localStorage.setItem(
              "curuseravatarbig",
              JSON.stringify(response.data.cardImage)
            );
            this.$root.curuserroles = response.data.roles;
            localStorage.setItem(
              "curuserroles",
              JSON.stringify(response.data.roles)
            );
            this.$root.curuserpermissions = response.data.permissions;
            localStorage.setItem(
              "curuserpermissions",
              JSON.stringify(response.data.permissions)
            );
            this.getAllUsers(response.data);
          })
          .catch(({ response }) => {
            this.loading = false;
            this.error = true;
            this.$root.authenticated = false;
            localStorage.setItem("authenticated", false);
            if (response.status === 401) {
              this.$notify({
                group: "platform",
                duration: 3000,
                type: "error",
                title: "Authentication failed",
                text: "These credentials do not match any of our records.",
              });
            } else {
              this.errors = response.data.message;
              // Show Successful Logged In Message
              console.log(response.data);
              this.$notify({
                group: "platform",
                speed: 500,
                duration: 3000,
                type: "error",
                title: "Authentication failed",
                text: response.data.message,
              });
            }
          });
      }
    },
    async getAllUsers(user_data) {
      this.LoaderData.dialog = true;
      this.LoaderData.message = "Preparing your Dashboard";
      await Axios.get(
        `${apiUrl}/users/all`, //users/all/plain
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });
          this.$root.users_list = this.users_list;
          localStorage.setItem("users_list", JSON.stringify(this.users_list));

          this.LoaderData.dialog = false;
          this.getAllRoles(user_data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBiographies(data, user_data) {
      this.LoaderData.dialog = true;
      this.LoaderData.message = "Preparing Departments...";
      await Axios.get(`${apiUrl}/hrbiographies`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          console.log(response);
          let dataa = response.data.data.reverse();
          this.biography_list = dataa;
          this.getAllDepartments(data, user_data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getAllDepartments(data, user_data) {
      await Axios.get(`${apiUrl}/departments`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          // console.log(response);
          let dataa = response.data.data.reverse();
          this.department_list = dataa;
          this.getDepartmentMembers(data, user_data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    getDepartmentMembers(data, user_data) {
      if (this.department_list.length > -1 && this.biography_list.length > -1) {
        this.department_list.forEach((department) => {
          let hod = this.users_list.filter(
            (element) => department.hod == element.id
          );
          let staff_list = this.biography_list.filter(
            (element) => department.id == element.department_id
          );

          let members = [];
          if (hod.length > 0) {
            let hodd = hod[0];
            hodd.name = hodd.name + " (HOD)";
            members.push(hodd);
          }
          this.users_list.forEach((user) => {
            staff_list.forEach((bio) => {
              if (user.id == bio.user_id) {
                members.push(user);
              }
            });
          });

          let item = {};
          item.hod = hod.length > 0 ? hod[0] : {};
          item.biography_list = staff_list;
          item.name = department.name;
          item.id = department.id;
          item.members_list = members;
          this.department.push(item);
        });
        //  console.log('***********************')
        //  console.log(JSON.stringify(this.department))
        //  console.log(this.department)
        this.$root.departments_list = this.department;
        localStorage.setItem(
          "departments_list",
          JSON.stringify(this.department)
        );
      }
      let isHOD = false;
      let isHR = false;
      this.LoaderData.dialog = false;
      this.items = [];
      let row = {};
      let lists = [];
      data.forEach((element) => {
        user_data.roles.forEach((role) => {
          console.log("***************************");
          console.log(element);
          if (element.name == "Head of Department" && role == element.name) {
            isHOD = true;
            isHR = false;
            row = {
              text: "Sign in as Head of Department",
              icon: "mdi-clock",
              user_position: "HOD",
              path: "/dashboard/hod",
            };
            lists.push(row);
          } else if (element.name.includes("HR") && role == element.name) {
            isHR = true;
            isHOD = false;
            row = {
              text: "Sign in as HR Agent",
              icon: "mdi-account",
              user_position: "HR",
              path: "/dashboard/hr",
            };
            lists.push(row);
          }
        });
      });
      if (isHOD || isHR) {
        this.loginAccess = true;
      }
      row = {
        text: "Sign in as ICMS Officer",
        icon: "mdi-account",
        user_position: "STAFF",
        path: "/dashboard/staff",
      };
      lists.push(row);
      this.items = lists;
      if (isHOD == false && isHR == false) {
        this.proceed(row);
      }
    },
    getStaffDepartment() {
      this.$root.departments_list.forEach((department) => {
        if (department.members_list != undefined) {
          department.members_list.forEach((member) => {
            if (member.id == this.$root.curuser.id) {
              this.$root.staff_department = department;
              localStorage.setItem(
                "staff_department",
                JSON.stringify(department)
              );
              // console.log(
              //   "$$$$$$$$$$$$$$$$$$$$ DEPARTMENTSSS $$$$$$$$$$$$$$$$$$$$$$"
              // );
              // console.log(department);
            }
          });
        }
      });
    },
    proceed(item) {
      this.$root.user_position = item.user_position;
      localStorage.setItem("user_position", item.user_position);
      this.getStaffDepartment();
      this.$router.push({ path: item.path });
    },
  },
};
