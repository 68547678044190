import { apiUrl } from "@/config";
import Axios from "axios";

export default {
  methods: {
    activeBox(el) {
      this.is_active = el;
      if (el == 1) {
        this.active_box_title = "Profile Information";
        this.active_box_icon = "person_pin";
        this.getProfile();
      } else if (el == 2) {
        this.active_box_title = "Display Picture";
        this.active_box_icon = "add_a_photo";
      } else if (el == 3) {
        this.active_box_title = "Change Password";
        this.active_box_icon = "transform";
      } else if (el == 4) {
        this.active_box_title = "Bank Accounts";
        this.active_box_icon = "account_balance_wallet";
      } else if (el == 5) {
        this.active_box_title = "Change Pin";
        this.active_box_icon = "apps";
      } else {
        console.log("Invalid Request");
      }
    },
    // Get User Profile
    getProfile() {
      Axios.get(`${apiUrl}/profiles/user/${this.user_id}/get`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.loadingProfile = false;
          this.profile = response.data.data;
          if (this.profile.length === 0) {
            console.log("");
          } else {
            console.log(this.profile);
            this.profile_id = this.profile.id;
            this.profile_grade = this.profile.grade;
            this.profile_qualification = this.profile.qualification;
            this.profile_tracker_line = this.profile.tracker_line;
            this.profile_gender = this.profile.gender;
            this.profile_dob = this.profile.dob;
            this.profile_marital_status = this.profile.marital_status;
            this.profile_address = this.profile.address;
            this.profile_country = this.profile.country;
            this.profile_soo = this.profile.soo;
            this.profile_lga = this.profile.lga;
            this.profile_secondary_email = this.profile.secondary_email;
          }
          if (this.profile.length > 0) {
            this.canEdit = true;
          } else {
            this.canEdit = false;
          }
        })
        .catch(({ response }) => {
          this.loadingProfile = false;
          console.log(response.data);
        });
    },
    // Convert Number to kb -> yb
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      const k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    // Change Avatar
    pickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(e) {
      const minSize = 2097152; // 2MB
      const files = e.target.files;
      const file = files[0];
      const imageSize = file.size;
      if (file !== undefined) {
        this.image_name = file.name;
        this.image_size = this.formatBytes(file.size);
        this.image_type = file.type;

        if (this.image_name.lastIndexOf(".") <= 0) {
          return;
        }

        // Validations
        if (imageSize > minSize) {
          this.canUpload = false;
          this.err = true;
          this.errMsg =
            "The chosen file is higher than " + this.min_size + " MegaByte";
        } else {
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener("load", () => {
            this.image_url = fr.result;
            this.image = file;
          });
          this.canUpload = true;
          this.err = false;
        }
      } else {
        this.image = "";
        this.image_name = "";
        this.image_size = "";
        this.image_type = "";
        this.image_url = "";
      }
    },
    uploadProfileImage() {
      this.saving = true;
      const fd = new FormData();
      fd.append("avatar", this.image);

      Axios.post(`${apiUrl}/profiles/user/${this.user_id}/upload-image`, fd, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.saving = false;
          this.$root.curuseravatar = response.data.avatar;
          localStorage.setItem(
            "curuseravatar",
            JSON.stringify(response.data.avatar)
          );
          this.$root.curuseravatarbig = response.data.cardImage;
          localStorage.setItem(
            "curuseravatarbig",
            JSON.stringify(response.data.cardImage)
          );
          // this.$forceUpdate();
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Request successful",
            text: response.data.message,
          });
          setTimeout(
            () =>
              this.$router.push({
                path: `/profile/${response.data.user.firstname}${response.data.user.id}`,
              }),
            2000
          );
        })
        .catch(({ response }) => {
          this.saving = false;
          console.log(response.data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Upload failed!",
            text: response.data.message,
          });
        });
    },
    // Change Theme
    changeTheme(theme) {
      this.profile_theme = theme;
      console.log(this.profile_theme);
      if (this.profile_theme != "light") {
        this.$vuetify.theme.dark = true;
        this.$vuetify.theme.light = false;
      } else {
        this.$vuetify.theme.dark = false;
        this.$vuetify.theme.light = true;
      }
    },
    // Save profile
    saveProfile() {
      console.log(this.profile_theme);
      if (this.$refs.profileform.validate()) {
        this.saving = true;
        // Create Profile
        Axios.post(
          `${apiUrl}/${this.user_id}/profile`,
          {
            user_id: this.user_id,
            grade: this.profile_grade,
            qualification: this.profile_qualification,
            tracker_line: this.profile_tracker_line,
            gender: this.profile_gender,
            dob: this.profile_dob,
            marital_status: this.profile_marital_status,
            address: this.profile_address,
            country: this.profile_country,
            soo: this.profile_state,
            lga: this.profile_lga,
            secondary_email: this.profile_secondary_email,
            pref_theme: this.profile_theme,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.saving = false;
            console.log(response.data.user, this.profile_theme);
            this.$root.curuser = response.data.user;
            localStorage.setItem("curuser", JSON.stringify(response.data.user));
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Request Successful",
              text: response.data.message,
            });
            location.reload();
          })
          .catch(({ response }) => {
            console.log(response.data);
            this.saving = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text: response.data.message,
            });
          });
      }
    },
    // Update Profile Information
    updateProfile() {
      if (this.$refs.profileform.validate()) {
        this.saving = true;
        // Create Profile
        Axios.put(
          `${apiUrl}/profiles/user/${this.user_id}/update`,
          {
            id: this.profile_id,
            user_id: this.user_id,
            grade: this.profile_grade,
            qualification: this.profile_qualification,
            tracker_line: this.profile_tracker_line,
            gender: this.profile_gender,
            dob: this.profile_dob,
            marital_status: this.profile_marital_status,
            address: this.profile_address,
            country: this.profile_country,
            soo: this.profile_state,
            lga: this.profile_lga,
            secondary_email: this.profile_secondary_email,
            pref_theme: this.profile_theme,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.saving = false;
            this.$root.curuser = response.data.user;
            localStorage.setItem("curuser", JSON.stringify(response.data.user));
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Request Successful",
              text: response.data.message,
            });
            location.reload();
          })
          .catch(({ response }) => {
            console.log(response.data);
            this.saving = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text: response.data.message,
            });
          });
      }
    },
    // Update Profile Password
    changePassword() {
      if (this.$refs.passwordform.validate()) {
        this.saving = true;
        Axios.post(
          `${apiUrl}/user/change-password`,
          {
            new_password: this.password,
            confirm_new_password: this.password_confirmation,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.saving = false;
            localStorage.clear();
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Request successful",
              text: response.data.message,
            });
            setTimeout(() => location.reload(), 2000);
          })
          .catch(({ response }) => {
            this.saving = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text: "",
            });
            console.log(response.data);
          });
      }
    },
    // Compare PINS
    comparePins() {
      if (this.new_pin != this.confirm_new_pin) {
        this.canChange = false;
        this.err = true;
        this.errMsg = "The confirm new pin and new pin must match.";
      } else {
        this.err = false;
        this.canChange = true;
        this.chckPin();
      }
    },
    // Check PIn
    checkPinLength() {
      if (this.pin.length == 6) {
        this.chckPin();
      }
    },
    // Update Profile Password
    changePin() {
      if (this.$refs.pinform.validate()) {
        this.saving = true;
        Axios.put(
          `${apiUrl}/user/pin/change`,
          {
            old_pin: this.old_pin,
            new_pin: this.new_pin,
            confirm_new_pin: this.confirm_new_pin,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.saving = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "PIN Changed.",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            this.saving = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text: "We are unable to change your PIN.",
            });
            console.log(response.data);
          });
      }
    },
    // Update Profile Password
    chckPin() {
      this.saving = true;
      Axios.post(
        `${apiUrl}/user/pin/check`,
        {
          new_pin: this.pin,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          this.saving = false;
          this.checkPinDialog = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "PIN Checked.",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          this.saving = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Request failed",
            text: "We are unable to check your PIN.",
          });
          console.log(response.data);
        });
    },

    // Get User Banks
    getBanks() {
      Axios.get(`${apiUrl}/userbanks/user/${this.user_id}/bank/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.bankLoading = false;
          this.banks = response.data.data;
          this.bankCount = this.banks.length;
        })
        .catch(({ response }) => {
          this.bankLoading = false;
          console.log(response);
        });
    },
    // Add New Bank
    addBank() {
      if (this.$refs.newBankForm.validate()) {
        this.saving = true;
        Axios.post(
          `${apiUrl}/userbanks`,
          {
            name: this.bank_name,
            account_type: this.account_type,
            account_number: this.account_number,
            bvn: this.bvn,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.saving = false;
            this.bankForm = false;
            this.getBanks();
            this.$refs.newBankForm.reset();
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Bank Added",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            this.saving = false;
            console.log(response.data);
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Bank registration failed!",
              text: response.data.message,
            });
          });
      }
    },
    // Delete Bank
    deleteBank(id) {
      this.saving = true;
      Axios.delete(`${apiUrl}/userbanks/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.saving = false;
          this.getBanks();
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Bank deleted",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          this.saving = false;
          console.log(response.data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Bank deletion failed!",
            text: response.data.message,
          });
        });
    },
    // Set Preferred Bank
    setPreferredBank(id) {
      this.setting = true;
      Axios.put(
        `${apiUrl}/userbanks/user/${this.user_id}/bank/set-preferred`,
        {
          bank_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          this.setting = false;
          this.getBanks();
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Banks Updated",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          this.setting = false;
          console.log(response.data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Bank update failed!",
            text: response.data.message,
          });
        });
    },
    // Get States
    getStates() {
      if (this.profile_country.name != "Nigeria") {
        this.states = [
          {
            name: "Others",
            lgas: ["Others"],
          },
        ];
      }
    },
    // Get LGAs
    getLgas() {
      this.profile_state = this.profile_soo.name;
      this.states.forEach((element) => {
        if (this.profile_soo.name === element.name) {
          this.lgas = element.lgas;
        }
      });
    },
  },
};
