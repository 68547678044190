export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Staff" : "Edit Password";
    },
    isHR() {
      return this.$root.user_position === "HR";
    },
    requiredRules(field = "field") {
      return [(v) => !!v || `This ${field} is required`];
    },
  },
};
