<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-layout row wrap v-if="!isNull(user_in_view)">
      <v-flex xs12 sm4>
        <v-card-title @click="goBack()">
          <span class="myFocus inner-header-subtitle">Go back</span>
        </v-card-title>
      </v-flex>
    </v-layout>

    <v-container fluid>
      <v-row v-if="isNull(user_in_view)">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <h3 class="font-weight-light">
                {{ pageTitle }}
              </h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isHR"
                fab
                color="secondary"
                @click="staffDialog = true"
              >
                <v-icon>add</v-icon>
                <!-- Add a Truck -->
              </v-btn>
              <v-btn rounded text color="blue-grey" @click="refreshTable()">
                <v-icon>refresh</v-icon> Refresh
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td class="clickable" @click="viewStaff(item)">
                        {{
                          item.surname +
                          " " +
                          item.firstname +
                          " " +
                          item.othernames
                        }}
                      </td>
                      <td>{{ item.email }}</td>
                      <td width="10">{{ item.staff_id }}</td>
                      <td width="10">{{ item.phone_number }}</td>

                      <td>
                        <v-btn
                          color="blue-grey"
                          rounded
                          outlined
                          small
                          dark
                          @click="viewStaff(item)"
                        >
                          <v-icon>visibility</v-icon> View
                        </v-btn>
                        <v-btn
                          color="blue-grey"
                          icon
                          fab
                          small
                          dark
                          class="mx-1"
                          @click="editStaffItem(item)"
                          v-if="isHR"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="blue-grey"
                              icon
                              fab
                              small
                              dark
                              class="mx-1"
                              v-if="isHR"
                              @click="suspendUser(item)"
                            >
                              <v-icon>lock</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >{{ item.is_suspended ? "un" : "" }}suspend</span
                          >
                        </v-tooltip>
                        <v-btn
                          color="blue-grey"
                          icon
                          fab
                          small
                          dark
                          class="mx-1"
                          v-if="isHR"
                          @click="deleteItem(item)"
                        >
                          <v-icon>delete_outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!isNull(user_in_view)">
        <v-col cols="12">
          <v-tabs
            v-model="general_tab"
            background-color="white"
            centered
            light
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
              v-for="mtab in general_tab_items"
              :key="mtab.title"
              :href="mtab.href"
              @click="openTabs(mtab)"
            >
              {{ mtab.title }}
              <v-icon>{{ mtab.icon }}</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="general_tab">
            <v-tab-item
              v-for="(mtab, i) in general_tab_items"
              :key="i"
              :value="'tab-' + i"
            >
              <v-card flat>
                <v-card-text>
                  <div v-if="mtab.title == 'Biography'">
                    <Biography :Biography="BiographyData" />
                  </div>
                  <div v-if="mtab.title == 'Qualifications'">
                    <Qualification :Qualification="QualificationData" />
                  </div>
                  <div v-if="mtab.title == 'Trainings'">
                    <Training :Training="TrainingData" />
                  </div>
                  <div v-if="mtab.title == 'Last Employer'">
                    <LastEmployer :LastEmployer="LastEmployerData" />
                  </div>
                  <div v-if="mtab.title == 'Beneficiaries'">
                    <Beneficiaries :Beneficiaries="BeneficiariesData" />
                  </div>
                  <div v-if="mtab.title == 'Addresses'">
                    <Addresses :Addresses="AddressesData" />
                  </div>
                  <div v-if="mtab.title == 'Next of Kin'">
                    <Nextofkin :Nextofkin="NextofkinData" />
                  </div>
                  <div v-if="mtab.title == 'Guarantor'">
                    <Guarantor :Guarantor="GuarantorData" />
                  </div>
                  <div v-if="mtab.title == 'Disciplinaries'">
                    <Disciplinary :Disciplinary="DisciplinaryData" />
                  </div>
                  <div v-if="mtab.title == 'HMO Dependants'">
                    <HMODependants :HMODependants="HMODependantsData" />
                  </div>
                  <div v-if="mtab.title == 'Development Plan'">
                    <DevelopmentPlan :DevelopmentPlan="DevelopmentPlanData" />
                  </div>
                  <div v-if="mtab.title == 'Insurance Benefits'">
                    <InsuranceBenefits
                      :InsuranceBenefits="InsuranceBenefitsData"
                    />
                  </div>
                  <div v-if="mtab.title == 'HMO'">
                    <HMO :HMO="HMOData" />
                  </div>
                  <div v-if="mtab.title == 'Attendance'">
                    <Attendance :Attendance="AttendanceData" />
                  </div>
                  {{ mtab.data }}
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-model="staffDialog" max-width="500px" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
            New Item
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="userAddForm">
              <v-layout row wrap>
                <v-flex xs12 sm6 v-if="editedIndex === -1">
                  <v-text-field
                    name="firstname"
                    v-model="editedItem.firstname"
                    label="Firstname"
                    type="text"
                    class="ml-5"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 v-if="editedIndex === -1">
                  <v-text-field
                    name="surname"
                    v-model="editedItem.surname"
                    label="Surname"
                    type="text"
                    class="ml-5"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 v-if="editedIndex === -1">
                  <v-text-field
                    name="email"
                    v-model="editedItem.email"
                    label="Email"
                    type="email"
                    class="ml-5"
                    :rules="emailRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="password"
                    v-model="editedItem.password"
                    label="Password"
                    type="password"
                    class="ml-5"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="password_confirmation"
                    v-model="editedItem.password_confirmation"
                    label="Confirm Password"
                    type="password"
                    class="ml-5"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 v-if="editedIndex === -1">
                  <v-text-field
                    name="phone_number"
                    v-model="editedItem.phone_number"
                    label="Phone Number"
                    type="text"
                    class="ml-5"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="editedIndex === -1">
                  <v-text-field
                    name="staff_id"
                    v-model="editedItem.staff_id"
                    label="Staff Id"
                    type="text"
                    class="ml-5"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this user?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import watch from "./watch";
import methods from "./methods";
import Biography from "./Biography/page";
import Qualification from "./Qualifications/page";
import Training from "./Training/page";
import LastEmployer from "./LastEmployer/page";
import Beneficiaries from "./Beneficiaries/page";
import Addresses from "./Addresses/page";
import Nextofkin from "./Nextofkin/page";
import Guarantor from "./Guarantor/page";
import Disciplinary from "./Disciplinary/page";
import HMODependants from "./HMODependants/page";
import DevelopmentPlan from "./DevelopmentPlan/page";
import InsuranceBenefits from "./InsuranceBenefits/page";
import HMO from "./HMO/page";
import Attendance from "./Attendance/page";

export default {
  created() {
    document.title = "Staff Profiles";
  },

  components: {
    InnerHeader,
    Biography,
    Training,
    LastEmployer,
    Beneficiaries,
    Addresses,
    Nextofkin,
    Guarantor,
    Disciplinary,
    HMODependants,
    DevelopmentPlan,
    InsuranceBenefits,
    HMO,
    Qualification,
    Attendance,
  },

  data() {
    return {
      pageTitle: "Registered Profiles",
      user_in_view: {},
      AttendanceData: {
        title: "Staff Attendance",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      HMOData: {
        title: "HMO",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      InsuranceBenefitsData: {
        title: "Insurance Benefits",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      DevelopmentPlanData: {
        title: "Development Plan",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      HMODependantsData: {
        title: "HMO Dependants",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      GuarantorData: {
        title: "Guarantor",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      BeneficiariesData: {
        title: "Beneficiaries",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      NextofkinData: {
        title: "Next of kin",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      AddressesData: {
        title: "Staff Addresses",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      BiographyData: {
        title: "Staff Biography",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      LastEmployerData: {
        title: "Last Employer",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      QualificationData: {
        title: "Staff Qualification",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      TrainingData: {
        title: "Staff Training",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      DisciplinaryData: {
        title: "Staff Disciplinary",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
        user: {},
      },
      general_tab: null,
      info:
        "Always ensure that all your profiles are updated because your job appraisal depends on it",
      general_tab_items: [
        {
          title: "Biography",
          href: "#tab-0",
          icon: "mdi-phone",
          data:
            "Always ensure that all your profiles are updated because your job appraisal may depend on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Attendance",
          href: "#tab-1",
          icon: "mdi-phone",
          data:
            "Always ensure that all your profiles are updated because your job appraisal may depend on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Qualifications",
          href: "#tab-2",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Trainings",
          href: "#tab-3",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Last Employer",
          href: "#tab-4",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Beneficiaries",
          href: "#tab-5",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Addresses",
          href: "#tab-6",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Guarantor",
          href: "#tab-7",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Next of Kin",
          href: "#tab-8",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Disciplinaries",
          href: "#tab-9",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "HMO Dependants",
          href: "#tab-10",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Development Plan",
          href: "#tab-11",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "Insurance Benefits",
          href: "#tab-12",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
        {
          title: "HMO",
          href: "#tab-13",
          icon: "mdi-heart",
          data:
            "Always ensure that all your profiles are updated because your job appraisal depends on it. If possible, update it daily to make sure your appraisal is always on the HR desk",
        },
      ],

      leave_status_list: this.$root.leave_statuses,
      user: this.$root.curuser,
      users_list: [],

      item_in_view: {},
      add_item_dialog_title: "Add Staff HMO",
      edit_item_dialog_title: "Edit Staff HMO",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      // maxYear: new Date().toISOString().substr(0, 10),
      maxYear: this.addMonth(8),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Staff profiles",
        subtitle: "Manage the company profile of all staff",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/staff",
          },
          {
            text: "account",
            disabled: true,
            href: "account",
          },
        ],
      },

      items: [],
      itemCount: 0,
      search: null,

      status: {},
      status_list: this.$root.leave_statuses,
      headers: [
        { text: "Staff Name", value: "staff_name" },
        { text: "Email", value: "email" },
        { text: "Staff ID", value: "staff_id" },
        { text: "Phone", value: "phone" },
        { text: "ACTION", value: "id" },
      ],
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      emailRules: [
        (v) => !!v || "Email address name is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
      ],
      titleRules: [(v) => !!v || "This field is required"],
      locationRules: [(v) => !!v || "This field is required"],
      // requiredRules: [(v) => !!v || "This field is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
      staffDialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        staff_id: "",
        surname: "",
        firstname: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      defaultItem: {
        staff_id: "",
        surname: "",
        firstname: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      isDeleting: false,
      isLoading: false,
      isSaving: false,
    };
  },

  mixins: [mounted, computed, methods, watch],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
.action-item:hover {
  background: rgb(235, 233, 233);
  cursor: pointer;
}
</style>
