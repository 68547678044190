<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card v-if="loading">
          <v-card-title>
            <h3 class="font-weight-light text-capitalize">
              {{ pageTitle }}
            </h3>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-progress-linear
              indeterminate
              color="primary darken-3"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>
            <h3 class="font-weight-light text-capitalize">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <span v-if="clockingIn">
              <v-btn rounded color="blue-grey lighten-5" light>
                <v-progress-linear
                  indeterminate
                  color="primary darken-3"
                ></v-progress-linear>
                Clocking In
              </v-btn>
            </span>
            <span v-else>
              <v-btn
                rounded
                color="primary"
                dark
                v-if="attendanceNotMarked"
                @click="getUserLatLong()"
              >
                <v-icon left>how_to_reg</v-icon>
                Clock In
              </v-btn>
            </span>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <div v-if="loading">
              <v-progress-linear
                indeterminate
                color="primary darken-3"
              ></v-progress-linear>
            </div>
            <div v-else>
              <full-calendar :config="config" :events="clockInObjs" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="attendanceModal" persistent max-width="500">
      <v-card>
        <v-card-title class="pt-2 pb-2">
          <span>Staff Attendance Book</span>
          <v-spacer></v-spacer>
          <v-btn text @click="attendanceModal = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <v-form ref="addItemForm" @submit.prevent="addItem">
                <v-row>
                  <v-col cols="12">
                    <h2 class="font-weight-light" v-if="notOfficeLocation">
                      You are currently signing in from or around: <br />
                      <strong>{{ streetRealAddress.formatted_address }}</strong>
                    </h2>
                    <h2 class="font-weight-light" v-else>
                      You are currently signing in from: <br />
                      <strong>{{ clockInObj.officeLocation }}</strong>
                    </h2>
                  </v-col>
                </v-row>
                <div v-if="notOfficeLocation">
                  <v-row>
                    <v-col cols="12" class="primary--text text-capitalize">
                      Your location is not registered as one of our office
                      locations. Breifly explain below why you are signing in
                      from the above location.
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        v-model="remark"
                        name="remark"
                        label="Reasons for signing in from this location"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="12" class="teal--text text-capitalize">
                      <h2 class="font-weight-light">
                        Thank you for clocking in. Click the Save Attendance
                        button to complete the process.
                      </h2>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn rounded text @click="attendanceModal = false">
            <v-icon left>close</v-icon>Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <span v-if="saving">
            <v-btn rounded color="blue-grey lighten-5" light>
              <v-progress-linear
                indeterminate
                color="primary darken-3"
              ></v-progress-linear>
              Processing...
            </v-btn>
          </span>
          <span v-else>
            <v-btn
              rounded
              color="primary"
              dark
              @click="addItem(clockInObj, streetRealAddress.formatted_address)"
            >
              <v-icon left>save</v-icon>
              Save Attendance
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>

    <Loader :LoaderDialog="LoaderData"></Loader>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
import Loader from "@/components/Global/Loader/page";
import moment from "moment";

export default {
  name: "Attendance",
  props: ["Attendance"],
  components: {
    Loader,
  },
  data() {
    return {
      pageTitle: "My Attendance Sheet",
      officeLatLong: [
        { id: 1, location: "Head Office", lat: 6.4474, lng: 3.3903 },
        { id: 2, location: "Oyingbo Office", lat: 6.5474, lng: 3.4903 },
        { id: 3, location: "Abuja Office", lat: 6.6474, lng: 3.5903 },
      ],
      clockInObj: {
        title: "",
        allDay: true,
        editable: false,
        backgroundColor: "TEAL",
        textColor: "#FFFFFF",
        start: moment(),
        end: moment().add(1, "d"),
        officeLocation: "",
        date: "",
        time: "",
      },
      clockInObjs: [],
      streetRealAddress: [],
      currentDate: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
      events: [],
      config: {
        defaultView: "month",
        selectable: true,
        // plugins: [interactionPlugin],
        eventRender: function (event, element) {
          console.log(event, element);
        },
      },
      clockingIn: false,
      attendanceNotMarked: true,
      notOfficeLocation: false,
      saving: false,
      attendanceModal: false,
      calendarDialog: false,
      time_in: "",
      signed_in_date: "",
      signed_in_location: "",
      time_out: "",
      remark: "",
      LoaderData: {
        dialog: false,
        message: "Confirming your current location",
      },
      attendance_dates: [],
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,

      add_item_dialog_title: "Mark attendance",
      addItemDialog: this.Attendance.addItemDialog,
      headers: [
        { text: "Date", value: "date" },
        { text: "Sign in Location", value: "Sign in Location" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Attendance.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      location: null,
      gettingLocation: false,
      errorStr: null,
      display_address: "",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
<style lang="css" scoped>
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
  color: white;
  background: #af0000 !important;
}
</style>
