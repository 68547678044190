export default {
  mounted() {
    this.getUsers();
    this.getUnreadMessages();
    this.getMessages(this.message_type);
    this.fetchMessages = setInterval(
      (this.filteredMessages, this.getUnreadMessages),
      30000
    );
  },
};
