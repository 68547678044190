<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              text
              rounded
              color="blue-grey"
              class="mr-2"
              @click="refreshTable()"
            >
              <v-icon>refresh</v-icon> refresh
            </v-btn>
            <v-btn
              rounded
              dark
              outlined
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td width="10">
                      <v-chip class="ma-2" color="green" text-color="white">
                        <h2>
                          <span class="myFocus" @click="viewDetail(item)">
                            {{ item.type_of_disengagement }}
                          </span>
                        </h2>
                      </v-chip>
                    </td>
                    <!-- <td width="10">
                        <v-chip
                          class="ma-2"
                          color="blue"
                          text-color="white"
                        >
                        {{ item.resignation_type }}
                        </v-chip>
                        </td> -->
                    <td>{{ item.resignation_date }}</td>
                    <td width="10">
                      <v-chip class="ma-2" color="blue" text-color="white">
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="ldialog"
                      v-model="last_date_dialog"
                      :return-value.sync="last_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="last_date"
                          label="Last date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="last_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="last_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.ldialog.save(last_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="bank"
                      v-model="bank"
                      label="Name of Bank"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="account_number"
                      v-model="account_number"
                      type="text"
                      label="Account number"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="forward_address"
                      v-model="forward_address"
                      type="text"
                      label="Forward address"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="forward_phone"
                      v-model="forward_phone"
                      type="text"
                      class="ml-5"
                      label="Forward phone"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="type_of_disengagement"
                      v-model="type_of_disengagement"
                      type="text"
                      class="ml-5"
                      label="Type of disengagement"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="resignation_type"
                      v-model="resignation_type"
                      type="text"
                      label="Resignation type"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="rdialog"
                      v-model="resignation_date_dialog"
                      :return-value.sync="resignation_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="resignation_date"
                          label="Resignation date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="resignation_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="resignation_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rdialog.save(resignation_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="pref_new_position"
                      v-model="pref_new_position"
                      type="text"
                      class="ml-5"
                      label="Pref new position"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="reason_change"
                      v-model="reason_change"
                      type="text"
                      label="Reason change"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="changes_implemented"
                      v-model="changes_implemented"
                      type="text"
                      class="ml-5"
                      label="Changes implemented"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="positive_aspect_work"
                      v-model="positive_aspect_work"
                      type="text"
                      class="ml-5"
                      label="Positive aspect work"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="positive_aspect_company"
                      v-model="positive_aspect_company"
                      type="text"
                      class="ml-5"
                      label="Positive aspect company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="negative_aspect_work"
                      v-model="negative_aspect_work"
                      type="text"
                      label="Negative aspect work"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="negative_aspect_company"
                      v-model="negative_aspect_company"
                      type="text"
                      class="ml-5"
                      label="Negative aspect company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="placing"
                      v-model="placing"
                      type="text"
                      class="ml-5"
                      label="Placing"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="job_content"
                      v-model="job_content"
                      type="text"
                      class="ml-5"
                      label="Job content"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="utilized"
                      v-model="utilized"
                      type="text"
                      label="Utilized"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="working_condition"
                      v-model="working_condition"
                      :items="Working_condition_list"
                      class="ml-5"
                      item-text="name"
                      label="Working condition"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="working_group"
                      v-model="working_group"
                      :items="working_group_list"
                      item-text="name"
                      label="Working group"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="salary"
                      v-model="salary"
                      type="text"
                      label="Salary"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="personal_development"
                      v-model="personal_development"
                      type="text"
                      class="ml-5"
                      label="Personal development"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="career_path"
                      v-model="career_path"
                      type="text"
                      class="ml-5"
                      label="Career path"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="adjustment_company"
                      v-model="adjustment_company"
                      type="text"
                      class="ml-5"
                      label="Adjustment company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="advancement_opportunities"
                      v-model="advancement_opportunities"
                      type="text"
                      label="Advancement opportunities"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="performance"
                      v-model="performance"
                      :items="performance_list"
                      item-text="name"
                      class="ml-5"
                      label="Performance"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="personnel_policy"
                      v-model="personnel_policy"
                      type="text"
                      class="ml-5"
                      label="Personnel policy"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="comm_channel"
                      v-model="comm_channel"
                      :items="comm_channel_list"
                      item-text="name"
                      class="ml-5"
                      label="Comm. channel"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_supervisor"
                      v-model="rel_supervisor"
                      :items="rel_supervisor_list"
                      item-text="name"
                      label="Relationship with supervisor"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_colleagues"
                      v-model="rel_colleagues"
                      :items="rel_colleagues_list"
                      item-text="name"
                      class="ml-5"
                      label="Relationship with colleagues"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_subordinates"
                      v-model="rel_subordinates"
                      :items="rel_subordinates_list"
                      item-text="name"
                      class="ml-5"
                      label="Relationship with subordinates"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="experience_benefit"
                      v-model="experience_benefit"
                      type="text"
                      class="ml-5"
                      label="Experience benefit"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="can_reapply"
                      v-model="can_reapply"
                      :items="can_reapply_list"
                      item-text="name"
                      label="Can Re-apply?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="recommend_to_friend"
                      v-model="recommend_to_friend"
                      type="text"
                      class="ml-5"
                      label="Recommend to friend"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="available_reason"
                      v-model="available_reason"
                      type="text"
                      class="ml-5"
                      label="Available reason"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="re_employable"
                      v-model="re_employable"
                      :items="re_employable_list"
                      item-text="name"
                      label="Re-employable?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="all_items_returned"
                      v-model="all_items_returned"
                      :items="all_items_returned_list"
                      item-text="name"
                      label="All items returned?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="list_items"
                      v-model="list_items"
                      type="text"
                      class="ml-5"
                      label="Items in possession"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="exit_confirmed_by"
                      v-model="exit_confirmed_by"
                      :items="users_list"
                      item-text="name"
                      class="ml-5"
                      label="Exit confirmed by?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-dialog
                      ref="edialog"
                      v-model="exit_confirmed_on_dialog"
                      :return-value.sync="exit_confirmed_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="exit_confirmed_on"
                          label="Exit confirmed on"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="exit_confirmed_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="exit_confirmed_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.edialog.save(exit_confirmed_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                          name="status"
                          v-model="status"
                          :items="status_list"
                          item-text="name"
                          label="status"
                          :rules="requiredRules"
                          return-object
                      >
                      </v-autocomplete>
                  </v-flex>
               </v-layout> -->
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="ldialog"
                      v-model="last_date_dialog"
                      :return-value.sync="last_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="last_date"
                          label="Last date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="last_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="last_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.ldialog.save(last_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="bank"
                      v-model="bank"
                      label="Name of Bank"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="account_number"
                      v-model="account_number"
                      type="text"
                      label="Account number"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="forward_address"
                      v-model="forward_address"
                      type="text"
                      label="Forward address"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="forward_phone"
                      v-model="forward_phone"
                      type="text"
                      class="ml-5"
                      label="Forward phone"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="type_of_disengagement"
                      v-model="type_of_disengagement"
                      type="text"
                      class="ml-5"
                      label="Type of disengagement"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="resignation_type"
                      v-model="resignation_type"
                      type="text"
                      label="Resignation type"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="rdialog"
                      v-model="resignation_date_dialog"
                      :return-value.sync="resignation_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="resignation_date"
                          label="Resignation date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="resignation_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="resignation_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rdialog.save(resignation_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="pref_new_position"
                      v-model="pref_new_position"
                      type="text"
                      class="ml-5"
                      label="Pref new position"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="reason_change"
                      v-model="reason_change"
                      type="text"
                      label="Reason change"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="changes_implemented"
                      v-model="changes_implemented"
                      type="text"
                      class="ml-5"
                      label="Changes implemented"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="positive_aspect_work"
                      v-model="positive_aspect_work"
                      type="text"
                      class="ml-5"
                      label="Positive aspect work"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="positive_aspect_company"
                      v-model="positive_aspect_company"
                      type="text"
                      class="ml-5"
                      label="Positive aspect company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="negative_aspect_work"
                      v-model="negative_aspect_work"
                      type="text"
                      label="Negative aspect work"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="negative_aspect_company"
                      v-model="negative_aspect_company"
                      type="text"
                      class="ml-5"
                      label="Negative aspect company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="placing"
                      v-model="placing"
                      type="text"
                      class="ml-5"
                      label="Placing"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="job_content"
                      v-model="job_content"
                      type="text"
                      class="ml-5"
                      label="Job content"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="utilized"
                      v-model="utilized"
                      type="text"
                      label="Utilized"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="working_condition"
                      v-model="working_condition"
                      :items="working_condition_list"
                      class="ml-5"
                      item-text="name"
                      label="Working condition"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="working_group"
                      v-model="working_group"
                      :items="working_group_list"
                      item-text="name"
                      label="Working group"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="salary"
                      v-model="salary"
                      type="text"
                      label="Salary"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="personal_development"
                      v-model="personal_development"
                      type="text"
                      class="ml-5"
                      label="Personal development"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="career_path"
                      v-model="career_path"
                      type="text"
                      class="ml-5"
                      label="Career path"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="adjustment_company"
                      v-model="adjustment_company"
                      type="text"
                      class="ml-5"
                      label="Adjustment company"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="advancement_opportunities"
                      v-model="advancement_opportunities"
                      type="text"
                      label="Advancement opportunities"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="performance"
                      v-model="performance"
                      :items="performance_list"
                      item-text="name"
                      class="ml-5"
                      label="Performance"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="personnel_policy"
                      v-model="personnel_policy"
                      type="text"
                      class="ml-5"
                      label="Personnel policy"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="comm_channel"
                      v-model="comm_channel"
                      :items="comm_channel_list"
                      item-text="name"
                      class="ml-5"
                      label="Comm. channel"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_supervisor"
                      v-model="rel_supervisor"
                      :items="rel_supervisor_list"
                      item-text="name"
                      label="Relationship with supervisor"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_colleagues"
                      v-model="rel_colleagues"
                      :items="rel_colleagues_list"
                      item-text="name"
                      class="ml-5"
                      label="Relationship with colleagues"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="rel_subordinates"
                      v-model="rel_subordinates"
                      :items="rel_subordinates_list"
                      item-text="name"
                      class="ml-5"
                      label="Relationship with subordinates"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="experience_benefit"
                      v-model="experience_benefit"
                      type="text"
                      class="ml-5"
                      label="Experience benefit"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="can_reapply"
                      v-model="can_reapply"
                      :items="can_reapply_list"
                      item-text="name"
                      label="Can Re-apply?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="recommend_to_friend"
                      v-model="recommend_to_friend"
                      type="text"
                      class="ml-5"
                      label="Recommend to friend"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="available_reason"
                      v-model="available_reason"
                      type="text"
                      class="ml-5"
                      label="Available_reason"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="re_employable"
                      v-model="re_employable"
                      :items="re_employable_list"
                      item-text="name"
                      label="Re-employable?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="all_items_returned"
                      v-model="all_items_returned"
                      :items="all_items_returned_list"
                      item-text="name"
                      label="All items returned?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="list_items"
                      v-model="list_items"
                      type="text"
                      class="ml-5"
                      label="Items in possession"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-autocomplete
                      name="exit_confirmed_by"
                      v-model="exit_confirmed_by"
                      :items="users_list"
                      item-text="name"
                      class="ml-5"
                      label="Exit confirmed by?"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-dialog
                      ref="edialog"
                      v-model="exit_confirmed_on_dialog"
                      :return-value.sync="exit_confirmed_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="exit_confirmed_on"
                          label="Exit confirmed on"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="exit_confirmed_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="exit_confirmed_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.edialog.save(exit_confirmed_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                          name="status"
                          v-model="status"
                          :items="status_list"
                          item-text="name"
                          label="status"
                          :rules="requiredRules"
                          return-object
                      >
                      </v-autocomplete>
                  </v-flex>
               </v-layout> -->
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Exits",
  props: ["Exits"],
  data() {
    return {
      pageTitle: "My Exit Interview",
      hr_ids: [],
      details: [],
      detailsDialog: false,
      exit_confirmed_on_dialog: false,
      resignation_date_dialog: false,
      last_date_dialog: false,
      last_date: "",
      bank: "",
      account_number: "",
      forward_address: "",
      forward_phone: "",
      type_of_disengagement: "",
      resignation_type: "",
      resignation_date: "",
      pref_new_position: "none",
      reason_change: "",
      changes_implemented: "",
      positive_aspect_work: "",
      positive_aspect_company: "",
      negative_aspect_work: "",
      negative_aspect_company: "",
      placing: "",
      job_content: "",
      utilized: "",
      working_condition: {},
      working_condition_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      working_group: {},
      working_group_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      salary: "",
      personal_development: "",
      career_path: "",
      adjustment_company: "",
      advancement_opportunities: "",
      performance: {},
      performance_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      personnel_policy: "none",
      comm_channel: {},
      comm_channel_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      rel_supervisor: {},
      rel_supervisor_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      rel_colleagues: {},
      rel_colleagues_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      rel_subordinates: {},
      rel_subordinates_list: [
        { id: 1, name: "Great" },
        { id: 2, name: "Good" },
        { id: 3, name: "Excellent" },
        { id: 4, name: "Poor" },
        { id: 5, name: "Very Poor" },
      ],
      experience_benefit: "",
      can_reapply: {},
      can_reapply_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      recommend_to_friend: "",
      available_reason: "",
      re_employable: {},
      re_employable_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      all_items_returned: {},
      all_items_returned_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      list_items: "Item 1, Item 2",
      exit_confirmed_by: {},
      exit_confirmed_on: "",

      status: { id: 3, name: "Pending" },
      status_list: [
        { id: 1, name: "Exited" },
        { id: 2, name: "Cancelled" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      edit_item_dialog_title: "",
      editItemDialog: false,
      add_item_dialog_title: "Add an Exit",
      addItemDialog: this.Exits.addItemDialog,
      headers: [
        { text: "Type of disengagement", value: "type_of_disengagement" },
        // { text: "Resignation type", value: "resignation_type" },
        { text: "Resignation date", value: "resignation_date" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Exits.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: this.addMonth(8),
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
