import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editResItem(item) {
      this.item_in_view = item;
      this.id = item.id;
      this.user_id = item.user_id;
      this.address = item.address;
      this.nearest_busstop = item.nearest_busstop;
      this.landmark = item.landmark;
      this.lga = item.lga;
      this.state = item.state;
      this.country = item.country;
      this.house_type = item.house_type;
      this.landlord_name = item.landlord_name;
      this.years_lived = item.years_lived;
      this.former_address = item.former_address;
      this.residing_with = item.residing_with;
      this.residing_with_phone = item.residing_with_phone;
      this.status = item.status;
      this.editResAddressItemDialog = true;
    },

    updateResItemOnAPI() {
      this.processing = true;
      let payload = {
        id: this.id,
        address: this.address,
        nearest_busstop: this.nearest_busstop,
        landmark: this.landmark,
        lga: this.lga,
        state: this.state.name,
        country: this.country,
        house_type: this.house_type,
        landlord_name: this.landlord_name,
        years_lived: this.years_lived,
        former_address: this.former_address,
        residing_with: this.residing_with,
        residing_with_phone: this.residing_with_phone,
        status: this.status.name,
      };
      Axios.put(`${apiUrl}/hrresidentialaddresses/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted residential address",
            title: "Residential address edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          this.init();
          this.processing = false;
          this.editResAddressItemDialog = false;
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          this.processing = false;
        });
    },

    editPermItem(item) {
      this.item_in_view = item;
      this.id = item.id;
      this.user_id = item.user_id;
      this.address = item.address;
      this.nearest_busstop = item.nearest_busstop;
      this.landmark = item.landmark;
      this.lga = item.lga;
      this.state = item.state;
      this.country = item.country;
      this.house_type = item.house_type;
      this.landlord_name = item.landlord_name;
      this.years_lived = item.years_lived;
      this.former_address = item.former_address;
      this.residing_with = item.residing_with;
      this.residing_with_phone = item.residing_with_phone;
      this.status = item.status;
      this.editPermAddressItemDialog = true;
    },

    updatePermItemOnAPI() {
      this.processing = true;
      let payload = {
        id: this.id,
        address: this.address,
        nearest_busstop: this.nearest_busstop,
        landmark: this.landmark,
        lga: this.lga,
        state: this.state.name,
        country: this.country,
        house_type: this.house_type,
        landlord_name: this.landlord_name,
        years_lived: this.years_lived,
        former_address: this.former_address,
        residing_with: this.residing_with,
        residing_with_phone: this.residing_with_phone,
        status: this.status.name,
      };
      Axios.put(`${apiUrl}/hrpermanentaddresses/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted residential address",
            title: "Residential address edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          this.init();
          this.processing = false;
          this.editPermAddressItemDialog = false;
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          this.processing = false;
        });
    },

    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.address == "") {
        return false;
      }
      if (this.state == "") {
        return false;
      }
      if (this.country == "") {
        return false;
      }
      if (isEmpty(this.status)) {
        return false;
      }

      return true;
    },
    clearFields() {
      this.address = "";
      this.state = "";
      this.country = "";
      this.status = {};
    },

    addResItemToAPI() {
      this.processing = true;
      let payload = {
        address: this.address,
        nearest_busstop: this.nearest_busstop,
        landmark: this.landmark,
        lga: this.lga,
        state: this.state.name,
        country: this.country,
        house_type: this.house_type,
        landlord_name: this.landlord_name,
        years_lived: this.years_lived,
        former_address: this.former_address,
        residing_with: this.residing_with,
        residing_with_phone: this.residing_with_phone,
        status: this.status.name,
      };
      Axios.post(`${apiUrl}/hrresidentialaddresses`, payload, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (
            data.address == payload.address &&
            data.landlord_name == payload.landlord_name
          ) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.address == payload.address &&
                storedData.landlord_name == payload.landlord_name
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added residential address",
            title: "Residential address added",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          this.init();
          this.processing = false;
          this.addResAddressItemDialog = false;
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          payload.id = 0;
          this.items.forEach((element, index) => {
            if (element.address == payload.address && element.id == 0) {
              this.items.splice(index, 1);
            }
          });
          this.processing = false;

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },

    addPermItemToAPI() {
      this.processing = true;
      let payload = {
        address: this.address,
        nearest_busstop: this.nearest_busstop,
        landmark: this.landmark,
        lga: this.lga,
        state: this.state.name,
        country: this.country,
        house_type: this.house_type,
        landlord_name: this.landlord_name,
        years_lived: this.years_lived,
        former_address: this.former_address,
        residing_with: this.residing_with,
        residing_with_phone: this.residing_with_phone,
        status: this.status.name,
      };
      Axios.post(`${apiUrl}/hrpermanentaddresses`, payload, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          console.log("resp: " + response.data.message);
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added residential address",
            title: "Residential address added",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          this.init();
          this.processing = false;
          this.addPermAddressItemDialog = false;
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          payload.id = 0;
          this.items.forEach((element, index) => {
            if (element.address == payload.address && element.id == 0) {
              this.items.splice(index, 1);
            }
          });
          this.processing = false;

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },

    async getAllResData() {
      await Axios.get(`${apiUrl}/hrresidentialaddresses`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((element) => {
            this.userResidentialAddresses = element;
          });
          // this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    async getAllPermData() {
      await Axios.get(`${apiUrl}/hrpermanentaddresses`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          console.log(response);
          let data = response.data.data.reverse();
          data.forEach((element) => {
            this.userPermanentAddresses = element;
          });
          // this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.userResidentialAddresses.push(q);
      });
    },

    // Get States
    getStates() {
      if (this.country.name != "Nigeria") {
        this.states = [
          {
            name: "Others",
            lgas: ["Others"],
          },
        ];
      }
    },
    // Get LGAs
    getLgas() {
      // this.state = this.profile_soo.name;
      this.states.forEach((element) => {
        if (this.state.name === element.name) {
          this.lgas = element.lgas;
        }
      });
    },

    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          console.log("pre:" + response.data.message);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllResData();
      this.getAllPermData();
    },
  },
};
