import { apiUrl } from "@/config";
import Axios from "axios";

export default {
  methods: {
    // GoTo Home
    home() {
      this.$router.push({ path: "/dashboard" });
    },
    // Load Theme
    loadTheme() {
      if (this.$root.curuser.pref_theme != "light") {
        this.$vuetify.theme.dark = true;
        this.$vuetify.theme.light = false;
        this.themeLogo = "white";
      } else {
        this.$vuetify.theme.dark = false;
        this.$vuetify.theme.light = true;
        this.themeLogo = "red";
      }
    },
    // Load Profile
    loadProfile(name, id) {
      this.$router.push({ path: `/profile/${name}${id}` });
    },
    // Get Notifications
    // getNotifications() {
    //     Axios.get(`${apiUrl}/usernotifications/unread/user/${this.user_id}`, {
    //         headers: {
    //             Authorization: `Bearer ${this.$root.auth}`
    //         }
    //     })
    //         .then(response => {
    //             this.loadingProfile = false
    //             this.notifications = response.data.data;
    //             this.unread_notifications = this.notifications.length;
    //         })
    //         .catch(({ response }) => {
    //             console.log(response);
    //         });
    // },
    // Open Notification Dialog
    readNotification(id) {
      this.openNotification = true;
      this.fetching = true;
      Axios.get(`${apiUrl}/usernotifications/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.getNotifications();
          this.notification = response.data.data;
          if (this.notification) {
            this.fetching = false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Get Messages
    getUnreadMessages() {
      Axios.get(`${apiUrl}/usermessages/unread/user/${this.user_id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.messages = response.data.data;
          this.unread_messages = this.messages.length;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Open message Dialog
    readMessage(id) {
      this.openMessage = true;
      this.fetching = true;
      Axios.get(`${apiUrl}/usermessages/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.getUnreadMessages();
          this.message = response.data.data;
          if (this.message) {
            this.fetching = false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    updateDateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(cd.getSeconds(), 2);
      this.date =
        this.zeroPadding(cd.getFullYear(), 4) +
        "-" +
        this.zeroPadding(cd.getMonth() + 1, 2) +
        "-" +
        this.zeroPadding(cd.getDate(), 2) +
        " " +
        this.week[cd.getDay()];
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    getNotifications() {
      Axios.get(`${apiUrl}/hrnotifications/get/${this.$root.curuser.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.prepNotifications(data);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    prepNotifications(data) {
      let number_of_unread = 0;
      // this.notification_count = data.length
      this.notifications_list = [];
      this.notifications_list.push({
        header: "Notifications",
        status: "header",
      });
      data.forEach((el) => {
        let n = 0;
        let sender = this.users_list.filter(
          (element) => el.sender_id == element.id
        );
        this.notifications_list.push({
          id: el.id,
          status: el.status,
          sender_id: el.sender_id,
          avatar:
            sender.length > 0
              ? sender[0].avatar_id
              : "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title:
            sender.length > 0
              ? sender[0].surname + " " + sender[0].firstname
              : "No User Found",
          subtitle:
            "<span class='text--primary'>" +
            el.title +
            "</span> &mdash; " +
            el.message,
          color: n % 2 == 0 ? "primary" : "amber",
          time: el.created_at,
        });
        n++;
        this.notifications_list.push({
          divider: true,
          inset: true,
          status: "divider",
        });
        if (el.status == "Unread") number_of_unread++;
      });
      this.$root.notifications_list = this.notifications_list;
      this.notification_count = number_of_unread;
    },

    logout() {
      localStorage.removeItem("departments_list");
      this.$root.departments_list = [];
      localStorage.removeItem("staff_department");
      this.$root.staff_department = {};
      localStorage.removeItem("authenticated");
      this.$root.authenticated = false;
      localStorage.removeItem("auth");
      this.$root.auth = {};
      localStorage.removeItem("curuser");
      this.$root.curuser = {};
      localStorage.removeItem("curuserroles");
      this.$root.curuserroles = {};
      localStorage.removeItem("curprofile");
      this.$root.curprofile = {};
      localStorage.removeItem("curlocation");
      this.$root.curlocation = {};
      localStorage.removeItem("curuserpermissions");
      this.$root.curuserpermissions = {};
      localStorage.removeItem("curuseravatar");
      localStorage.removeItem("users_list");
      this.$root.users_list = [];
      localStorage.removeItem("user_position");
      this.$root.user_position = "";
      this.$root.curuseravatar = {};

      this.$notify({
        group: "platform",
        duration: 4000,
        type: "success",
        title: "Sign Out Successful",
        text:
          "You have successfully signed out of Platform. Please protect your account credentials.",
      });
      // location.reload();
      this.$router.push({ path: "/" });
    },
    checkNotifications() {
      this.intervalID = setInterval(() => {
        this.getNotifications();
      }, 1000000);
    },
    markNotifications() {
      this.markIntervalID = setInterval(() => {
        console.log("*********Updating Notifications****************");
        this.seen_notications_list.forEach((el) => {
          let item = {
            id: el.id,
            status: "Read",
          };
          if (el.status == "Unread") this.updateItemOnAPI(item);
        });
      }, 3000000);
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrnotifications/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          // this.$notify({
          //     group: 'platform',
          //     duration: 3000,
          //     type: 'success',
          //     title: "Updated Successfully!",
          //     text: response.data.message
          // });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    hoverNotification() {
      this.seen_notications_list = this.notifications_list;
      this.notification_count = 0;
    },
    init() {
      this.checkNotifications();
      this.getNotifications();
      this.markNotifications();
    },
  },
};
