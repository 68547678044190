<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" sm="4" v-for="link in gridLinks" :key="link.route">
          <v-card shaped :to="link.route" class="infobox">
            <v-row>
              <v-col cols="12" sm="4">
                <div class="pa-3">
                  <v-avatar size="100" class="mr-3">
                    <img
                      v-if="link.image != null"
                      :src="link.image"
                      width="200"
                    />
                    <img v-else :src="userAvatar" width="200" />
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" sm="8" class="text-center pa-3 mt-4">
                <h1 class="font-weight-light">{{ link.name }}</h1>
                CLICK TO VIEW
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Scripts
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "Application Console";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      gridLinks: [
        {
          name: "Staff Profile",
          image: require("@/assets/icons/staff.png"),
          route: "/account/hr",
        },
        {
          name: "Appraisal",
          image: require("@/assets/icons/appraisal.png"),
          route: "/appraisal/hr",
        },
        {
          name: "Activity Feed",
          image: require("@/assets/icons/newsfeed.png"),
          route: "/timeline",
        },
        // {name:'Disciplinaries',icon:require("@/assets/Policy Document_100px.png"),route:'/disciplinaries'},
        {
          name: "HMO",
          image: require("@/assets/icons/hospital.png"),
          route: "/hmo/hr",
        },
        // {name:'Staff Development',icon:require("@/assets/human_arrow.png"),route:'/development'},
        {
          name: "Travels",
          image: require("@/assets/icons/travel.png"),
          route: "/travels/hr",
        },
        {
          name: "Leave Advice",
          image: require("@/assets/icons/leave.png"),
          route: "/leave/hr",
        },
        {
          name: "Pension",
          image: require("@/assets/icons/pension.png"),
          route: "/pension",
        },
        {
          name: "Departments",
          image: require("@/assets/icons/home.png"),
          route: "/departments",
        },
        {
          name: "Payroll",
          image: require("@/assets/icons/payroll.png"),
          route: "/payroll",
        },
      ],
      testAvatar: require("@/assets/insurance.jpg"),
      headerData: {
        title: "Dashboard",
        subtitle: "Manage all HR activities all in one place",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
        ],
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      user_id: this.$root.curuser.id,
      user_role: this.$root.curuserroles[0],
      supportHeader: "Contact Support",
      supportContacts: [
        {
          name: "Ibeh Ernest",
          phone: "08020689069",
          email: "e.ibeh@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "John Doe",
          phone: "07120689068",
          email: "j.doe@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "Jane Shinene",
          phone: "07020689067",
          email: "j.shinene@icms.ng",
          avatar: "avatar1.png",
        },
      ],
      loading: false,
      ss: false,
      err: false,
      ssMsg: "",
      errMsg: "",
      error: false,
      errors: {},
      response: "",
      senderSurname: this.$root.curuser.surname,
      senderFirstname: this.$root.curuser.firstname,
      senderEmail: this.$root.curuser.email,
      fullname: "",
      message: "",
    };
  },

  mixins: [mounted, methods],
};
</script>
<style>
.rounded-card {
  border-radius: 30px;
  cursor: pointer;
}
</style>
