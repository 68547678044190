import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;

      this.name = item.name;
      this.dob = item.dob;
      this.gender = item.gender;
      this.blood_group = item.blood_group;
      this.genotype = item.genotype;
      this.hospital = item.hospital;
      this.attachments = item.attachments;

      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.name = this.name;
        item.dob = this.dob;
        item.hospital = this.hospital;
        item.attachments = this.attachments;
        item.gender = this.gender;
        item.blood_group = this.blood_group;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              name: item.name,
              dob: item.dob,
              hospital: item.hospital,
              blood_group: item.blood_group.name,
              gender: item.gender.name,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrhmodependants/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);

          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " marked your HMO Dependants as " +
              item.status,
            title: "HMO Dependant update from HR",
            sender_id: this.$root.curuser.id,
          };
          let receiver_ids = [];
          receiver_ids.push(this.user_in_view.id);
          notification_data.receiver_ids = receiver_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.name == "") {
        return false;
      }
      if (this.dob == "") {
        return false;
      }
      if (this.hospital == "") {
        return false;
      }
      if (isEmpty(this.gender)) {
        return false;
      }
      return true;
    },
    clearFields() {
      this.name = "";
      this.dob = "";
      this.hospital = "";
      this.gender = {};
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.HMODependants.user,
        name: this.name,
        dob: this.dob,
        gender: this.gender,
        hospital: this.hospital,
        genotype: this.genotype,
        blood_group: this.blood_group,
        attachments: this.attachments,
        status: this.status,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        name: item.name,
        dob: item.dob,
        gender: item.gender.name,
        hospital: item.hospital,
        genotype: item.genotype.name,
        blood_group: item.blood_group.name,
        attachments: item.attachments,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrhmodependants`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.surname == item.surname && data.phone_no == item.phone_no) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.surname == item.surname &&
                storedData.phone_no == item.phone_no
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.surname == item.surname && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrhmodependants`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let genotype = this.genotype_list.filter(
          (element) => q.genotype == element.name
        );
        let gender = this.gender_list.filter(
          (element) => q.gender == element.name
        );
        let blood_group = this.blood_group_list.filter(
          (element) => q.blood_group == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        q.genotype = genotype.length > 0 ? genotype[0] : {};
        q.gender = gender.length > 0 ? gender[0] : {};
        q.blood_group = blood_group.length > 0 ? blood_group[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },

    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      //     this.getAllData()
    },
  },
};
