import {
  apiUrl,
  //isEmpty,
  capitalize,
  timeNow,
  todayDate,
} from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;
      this.name = item.name;
      this.proportion = item.proportion;
      this.rel_type = item.rel_type;
      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.name = this.name;
        item.proportion = this.proportion;
        item.rel_type = this.rel_type;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              name: item.name,
              proportion: parseFloat(item.proportion),
              rel_type: item.rel_type.name,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrbeneficiaries/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted beneficiary",
            title: "Beneficiary edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    addItem(location) {
      if (location == null) {
        alert("Allow location access and continue");
        return;
      }
      let item = {
        id: 0,
        user: this.$root.curuser,
        time_in: this.time_in,
        signed_in_date: this.signed_in_date,
        signed_in_location: this.signed_in_location,
        time_out: this.time_out,
        remark: this.remark,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        time_in: item.time_in,
        signed_in_date: item.signed_in_date,
        signed_in_location: item.signed_in_location,
        time_out: item.time_out,
        remark: item.remark,
      };
      this.attendance_dates.push(item.signed_in_date);
      this.addItemToAPI(new_item);
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrattendances`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.time_in == item.time_in) {
            this.items.forEach((storedData, index) => {
              if (storedData.time_in == item.time_in) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " Just marked attendance " +
              item.signed_in_date,
            title: "Staff Attendance",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.time_in == item.time_in && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },

    async getAllData() {
      await Axios.get(`${apiUrl}/hrattendances`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((attend) => {
            this.items.push(attend);
            this.attendance_dates.push(attend.signed_in_date);
          });
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let rel_type = this.rel_type_list.filter(
          (element) => q.rel_type == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.rel_type = rel_type.length > 0 ? rel_type[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    getLocation(location) {
      if (location == null) {
        alert('Refresh your browser then Click on "Allow Location access"');
        return;
      }
      this.LoaderData.dialog = true;
      this.addressByCoordinates(location);
      this.addItemDialog = true;
    },
    addressByCoordinates(location) {
      let ENDPOINT = "https://nominatim.openstreetmap.org/reverse";
      let FORMAT = "jsonv2";
      Axios.get(ENDPOINT, {
        params: {
          format: FORMAT,
          lat: location.coords.latitude,
          lon: location.coords.longitude,
        },
      })
        .then((response) => {
          console.log("**************GETTING ADDRESS****************");
          console.log(response);
          this.display_address = response.data.display_name;
          this.signed_in_location = response.data.address.road;
          this.time_in = timeNow();
          this.signed_in_date = todayDate();
          this.remark = this.$root.curuser.surname;
          " marked attendance from " +
            this.display_address +
            " on " +
            this.signed_in_date +
            " at " +
            this.time_in;
          this.LoaderData.dialog = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.LoaderData.dialog = false;
        });
    },
    init() {
      // this.getHR_Ids()
      // this.getAllData()
      let data = this.user_in_view.hrattendances;
      data.forEach((attend) => {
        this.items.push(attend);
        this.attendance_dates.push(attend.signed_in_date);
      });
      this.loading = false;
    },
  },
};
