export default {
  computed: {
    curUserData() {
      return this.$root.curuser;
    },
    curUserAvatar() {
      return this.$root.curuseravatar;
    },
    curUserAvatarBig() {
      return this.$root.curuseravatarbig;
    },
  },
};
