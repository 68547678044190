<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-btn
                fab
                color="blue"
                class="white--text"
                @click="refreshTable()"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Truck -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip class="ma-2" color="green" text-color="white">
                          <v-icon left>person</v-icon>
                          <h2>
                            <span
                              v-if="!isNull(item.user)"
                              class="white--text myFocus"
                              @click="detailsDialogPopup(item)"
                            >
                              {{
                                item.user.surname + " " + item.user.firstname
                              }}
                            </span>
                            <span v-else>No User</span>
                          </h2>
                        </v-chip>
                      </td>

                      <td width="10">
                        <v-chip class="ma-2" color="blue" text-color="white">
                          {{ item.title }}
                        </v-chip>
                      </td>
                      <td>{{ item.destination }}</td>
                      <td>
                        <span v-html="naira"></span
                        >{{ item.total_cost | formatNumber }}
                      </td>
                      <td>
                        <v-btn
                          color="blue"
                          fab
                          small
                          dark
                          @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn
                          color="red"
                          fab
                          small
                          dark
                          @click="proceedToRemoveItem(item)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Title"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="description"
                      v-model="description"
                      type="text"
                      label="description"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="destination"
                      v-model="destination"
                      label="destination"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hotel"
                      v-model="hotel"
                      type="text"
                      label="hotel"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="number_nights"
                      v-model="number_nights"
                      :items="number_nights_list"
                      item-text="name"
                      label="number of Nights"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="hotel_cost"
                      v-model="hotel_cost"
                      type="text"
                      label="hotel cost"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="transportation_cost"
                      v-model="transportation_cost"
                      type="text"
                      label="Transportation cost"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="pocket_money"
                      v-model="pocket_money"
                      type="text"
                      label="Pocket money"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="total_cost"
                      v-model="total_cost"
                      type="text"
                      label="Total Cost"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="hrtraveleligibilitygroup"
                      v-model="hrtraveleligibilitygroup"
                      :items="hrtraveleligibilitygroup_list"
                      item-text="name"
                      label="Choose Eligibility Group"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="expensetype"
                      v-model="expensetype"
                      :items="expensetype_list"
                      item-text="name"
                      class="ml-5"
                      label="Choose expense type"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="duration"
                      v-model="duration"
                      class="ml-5"
                      :items="duration_list"
                      item-text="name"
                      label="Choose duration"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="sdialog"
                      v-model="added_on_dialog"
                      :return-value.sync="added_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="added_on"
                          label="Added On"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="added_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="added_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(added_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-flex xs12 sm6>
                      <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        class="ml-5"
                        label="Status"
                        :rules="locationRules"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit item dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="editItemDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Title"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="description"
                      v-model="description"
                      type="text"
                      label="description"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="destination"
                      v-model="destination"
                      label="destination"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hotel"
                      v-model="hotel"
                      type="text"
                      label="hotel"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="number_nights"
                      v-model="number_nights"
                      :items="number_nights_list"
                      item-text="name"
                      label="number of Nights"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="hotel_cost"
                      v-model="hotel_cost"
                      type="text"
                      label="hotel cost"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="transportation_cost"
                      v-model="transportation_cost"
                      type="text"
                      label="Transportation cost"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="pocket_money"
                      v-model="pocket_money"
                      type="text"
                      label="Pocket money"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="total_cost"
                      v-model="total_cost"
                      type="text"
                      label="Total Cost"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="hrtraveleligibilitygroup"
                      v-model="hrtraveleligibilitygroup"
                      :items="hrtraveleligibilitygroup_list"
                      item-text="name"
                      label="Choose Eligibility Group"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="expensetype"
                      v-model="expensetype"
                      :items="expensetype_list"
                      item-text="name"
                      class="ml-5"
                      label="Choose expense type"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="duration"
                      v-model="duration"
                      class="ml-5"
                      :items="duration_list"
                      item-text="name"
                      label="Choose duration"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="added_on_dialog"
                      :return-value.sync="added_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="added_on"
                          label="Added On"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="added_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="added_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(added_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="approved_by"
                      v-model="approved_by"
                      :items="users_list"
                      item-text="name"
                      label="Approved by"
                      class="ml-5"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="ddialog"
                      v-model="approved_on_dialog"
                      :return-value.sync="approved_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="approved_on"
                          label="Approved On"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="approved_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="approved_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.ddialog.save(approved_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="approval_comment"
                      v-model="approval_comment"
                      type="text"
                      label="Approval comment"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="isNull(item_in_view) == false"
      v-model="detailsDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <v-avatar v-if="item_in_view.user != null" size="50">
            <span v-if="item_in_view.user.avatar_id" class="msg-not">
              <img :src="item_in_view.user.avatar_id" alt />
            </span>
          </v-avatar>
          <span v-if="item_in_view.user != null">{{
            item_in_view.user.surname + " " + item_in_view.user.firstname
          }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <br />
            <h2>Title: {{ item_in_view.title }}</h2>
            <br />
            <h2>Description: {{ item_in_view.description }}</h2>
            <br />
            <h2 v-if="isNull(item_in_view.hrtraveleligibilitygroup) == false">
              Eligibility group:
              {{ item_in_view.hrtraveleligibilitygroup.name }}
            </h2>
            <br />
            <h2 v-if="isNull(item_in_view.expensetype) == false">
              Expense type: {{ item_in_view.expensetype.name }}
            </h2>
            <br />
            <h2>Destination: {{ item_in_view.destination }}</h2>
            <br />
            <h2>Hotel: {{ item_in_view.hotel }}</h2>
            <br />
            <h2>
              Total cost: <span v-html="naira"></span>
              {{ item_in_view.total_cost }}
            </h2>
            <br />
            <h2>
              Pocket money: <span v-html="naira"></span>
              {{ item_in_view.pocket_money }}
            </h2>
            <br />
            <h2>
              Transportation cost: <span v-html="naira"></span>
              {{ item_in_view.transportation_cost }}
            </h2>
            <br />
            <h2 v-if="isNull(item_in_view.number_nights) == false">
              Number of nights: {{ item_in_view.number_nights.name }} night(s)
            </h2>
            <br />
            <h2 v-if="isNull(item_in_view.duration) == false">
              Duration: {{ item_in_view.duration.name }}
            </h2>
            <br />
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Travels";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      approved_on_dialog: false,
      approved_on: "",
      approval_comment: "",
      approved_by: {},
      added_by: this.$root.curuser,
      title: "",
      description: "",
      destination: "",
      hotel: "",
      number_nights: {},
      number_nights_list: [],
      hotel_cost: 0,
      transportation_cost: 0,
      pocket_money: 0,
      total_cost: 0,
      hrtraveleligibilitygroup_list: [],
      hrtraveleligibilitygroup: {},
      expensetype: {},
      expensetype_list: [],
      duration_list: [],
      duration: {},
      leave_status: {},
      resumed_on_dialog: false,
      leave_advice_on_dialog: false,
      leave_status_list: this.$root.leave_statuses,
      detailsDialog: false,
      added_on_dialog: false,
      end_date_dialog: false,
      leave_advice_by: {},
      resumption_confirmed_by: {},
      resumption_confirmed_on_dialog: false,
      resumption_confirmed_on: "",
      hod_approved: "",
      hod_comment: "",
      leave_advice: "",
      leave_advice_on: "",
      resumed_on: "",
      added_on: "",
      start_date: "",
      end_date: "",
      no_days_entitled: "",
      no_days_taken: "",
      no_days_remaining: 0,
      hod: {},
      releaved_by: {},
      user: this.$root.curuser,
      users_list: [],
      leave_type: {},
      leave_types_list: this.$root.leave_types,
      leave_year: {},
      leave_years_list: this.$root.years,
      booked_month: {},
      booked_months_list: this.$root.months,
      attendance_dates: [],
      attendanceDialog: false,
      attendance_data: [],
      sort_item_by_vendor_dialog_title: "Search by Vendor",
      sortItemByVendorDialog: false,
      search_location: "",
      search_vendor: "",
      sort_item_dialog_title: "Search Trucks by location",
      sortItemDialog: false,
      vendorViewDialog: false,
      vendor_in_view: {},
      vendors_list: [],
      locations_list: [],
      delete_item_dialog_title: "Delete a Leave advice",
      confirmDeleteDialog: false,
      item_in_view: {},
      add_item_dialog_title: "Add Staff Travel",
      edit_item_dialog_title: "Edit Travel",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Staff Travels",
        subtitle: "View and manage all staff travels",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/hr",
          },
          {
            text: "Leave",
            disabled: true,
            href: "advice",
          },
        ],
      },
      items: [],
      itemCount: 0,
      search: null,

      status: {},
      status_list: this.$root.leave_statuses,
      headers: [
        { text: "Staff Name", value: "staff_name" },
        { text: "title", value: "title" },
        { text: "Destination", value: "destination" },
        { text: "total_cost", value: "total_cost" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: "2MB",
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      titleRules: [(v) => !!v || "Name of Truck is required"],
      plateNumberRules: [(v) => !!v || "Plate number is required"],
      departmentRules: [(v) => !!v || "Official department/unit is required"],
      locationRules: [(v) => !!v || "Location is required"],
      vendorRules: [(v) => !!v || "A Vendor is required"],
      requiredRules: [(v) => !!v || "This field is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
