<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm9>
          <v-card>
            <v-card-title class="primary--text">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn @click="roleDialog = true" class="primary">
                <v-icon left>security</v-icon>Role Management
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="disabled = (disabled + 1) % 2">
                <v-icon left>person_add</v-icon>Add New User
                <v-icon right>arrow_right_alt</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <td>
                        <span v-for="(avatar, i) in avatars" :key="i">
                          <span v-if="avatar.user_id === item.id">
                            <v-avatar color="primary" size="50">
                              <v-img
                                :src="avatar.avatar"
                                class="is-clickable"
                                @click="
                                  lightBox(item.firstname, avatar.cardImage)
                                "
                              ></v-img>
                            </v-avatar>
                          </span>
                        </span>
                      </td>
                      <td>
                        <span v-for="(role, i) in roles" :key="i">
                          <span v-if="role.user_id == item.id">
                            <v-chip
                              v-if="role.role[0] != null"
                              color="cyan"
                              dark
                              >{{ role.role[0] }}</v-chip
                            >
                          </span>
                        </span>
                      </td>
                      <td>{{ item.staff_id }}</td>
                      <td>{{ item.surname }}</td>
                      <td>{{ item.firstname }}</td>
                      <td>{{ item.phone_number }}</td>
                      <td>
                        <a :href="'mailto:' + item.email">{{ item.email }}</a>
                      </td>
                      <td width="10">
                        <v-btn
                          class="secondary--text"
                          :to="`/user/profile/${item.firstname}${item.id}`"
                        >
                          <v-icon left>link</v-icon>View Profile
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="blue-grey lighten-4"
              >Total registered users:
              {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>

        <v-flex xs12 sm3>
          <v-card flat>
            <v-card-title>
              <h3 class="text-uppercase">
                <v-icon color="black" class="mr-3">person_add</v-icon>New User
                Account
              </h3>
            </v-card-title>
            <v-card-text>
              <h4>
                Note that
                <span class="error--text">fields are required</span> and email
                must be
                <span class="error--text">unique, valid and accessible</span>.
                You are advised to use
                <span class="error--text">official email address only</span>.
              </h4>
              <v-form ref="newUserForm" @submit.prevent="saveUser">
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="surname"
                      v-model="surname"
                      label="Surname"
                      type="text"
                      :rules="surnameRules"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.surname">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.surname"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="firstname"
                      v-model="firstname"
                      label="Given Name"
                      type="text"
                      :rules="firstnameRules"
                      :disabled="disabled == 1 ? true : false"
                      class="ml-4"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.firstname">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.firstname"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      name="othernames"
                      v-model="othernames"
                      label="Other Names"
                      type="text"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.othernames">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.othernames"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm5>
                    <v-text-field
                      name="staff_id"
                      v-model="staff_id"
                      label="Staff ID"
                      type="text"
                      :rules="staffIdRules"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.staff_id">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.staff_id"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                  <v-flex xs12 sm7>
                    <v-text-field
                      name="phone_number"
                      v-model="phone_number"
                      label="Phone Number"
                      type="text"
                      v-mask="['###########']"
                      :rules="phonenumberRules"
                      :disabled="disabled == 1 ? true : false"
                      class="ml-4"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.phone_number">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.phone_number"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      prepend-icon="email"
                      name="email"
                      v-model="email"
                      :rules="emailRules"
                      label="Email Address"
                      type="text"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.email">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.email"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      prepend-icon="lock"
                      name="password"
                      label="Password"
                      v-model="password"
                      :rules="passwordRules"
                      hint="At least 8 characters"
                      type="password"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                    <div class="errors ml-4" v-if="errors.password">
                      <small
                        class="error--text"
                        :key="error"
                        v-for="error in errors.password"
                        >{{ error }}</small
                      >
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      prepend-icon="lock"
                      name="password_confirmation"
                      label="Confirm Password"
                      v-model="password_confirmation"
                      :rules="confirmpasswordRules"
                      hint="At least 8 characters"
                      type="password"
                      :disabled="disabled == 1 ? true : false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-alert v-if="ss" :value="true" type="success">{{
                ssMsg
              }}</v-alert>
            </v-card-text>
            <v-card-actions class="blue-grey lighten-4">
              <v-spacer></v-spacer>
              <v-btn
                :disabled="disabled == 1 ? true : false"
                color="primary"
                @click="saveUser()"
              >
                <v-icon left v-if="!saving">save</v-icon>
                {{ saving ? "Processing..." : "Save User" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="lightboxDialog" persistent max-width="800">
      <v-card class="mx-auto">
        <v-img class="white--text align-end" :src="lightbox_image">
          <v-card-title v-text="lightbox_title"></v-card-title>
        </v-img>
        <v-card-actions>
          <v-btn text @click="lightboxDialog = false">
            <v-icon left>close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="roleDialog" persistent max-width="800">
      <v-card class="mx-auto">
        <v-card-title class="pt-2 pb-2 primary--text"
          >User Role &amp; Permissions Management</v-card-title
        >
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-tabs v-model="tab" centered icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#assignRole">
              Assign Role
              <v-icon>person_add</v-icon>
            </v-tab>

            <v-tab href="#stripRole">
              Revoke Role
              <v-icon>person_add_disabled</v-icon>
            </v-tab>

            <v-tab href="#assignPermission">
              Assign Permissions
              <v-icon>person_add</v-icon>
            </v-tab>

            <v-tab href="#stripPermission">
              Strip Permissions
              <v-icon>person_add_disabled</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :value="'assignRole'">
              <v-card flat>
                <v-card-text>
                  <span class="subheading"
                    >Select desired user and role then hit save button.</span
                  >
                  <v-form ref="assignRoleForm" @submit.prevent="assignRole">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="selectedUser"
                          :items="items"
                          item-text="email"
                          label="Select User"
                          persistent-hint
                          return-object
                          single-line
                          @change="getSelectedUser"
                          :rules="recUserRules"
                        ></v-autocomplete>
                      </v-flex>
                      <span v-if="selectedUser != null">
                        {{ selected_user_names }} (
                        <strong>{{ selected_user_staff_id }}</strong> )
                      </span>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="selected_role"
                          :items="all_roles"
                          item-text="name"
                          label="Select User Role"
                          persistent-hint
                          return-object
                          single-line
                          :rules="recRoleRules"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-layout row wrap>
                    <v-flex xs12 v-for="(err, i) in errors" :key="i">
                      <span class="ma-2 ma-3 primary--text"
                        >This user has already been assigned a role.</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="savingrp"
                    color="primary"
                    @click="assignRole()"
                  >
                    <v-icon left v-if="!savingrp">save</v-icon>
                    {{ savingrp ? "Processing..." : "Save" }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'stripRole'">
              <v-card flat>
                <v-card-text>
                  <span class="subheading"
                    >Select desired user and role then hit save button.</span
                  >
                  <v-form ref="stripRoleForm" @submit.prevent="stripRole">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="selectedUser"
                          :items="items"
                          item-text="email"
                          label="Select User"
                          persistent-hint
                          return-object
                          single-line
                          @change="getSelectedUser"
                          :rules="recUserRules"
                        ></v-autocomplete>
                      </v-flex>
                      <span v-if="selectedUser != null">
                        {{ selected_user_names }} (
                        <strong>{{ selected_user_staff_id }}</strong> )
                      </span>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="selected_role"
                          :items="all_roles"
                          item-text="name"
                          label="Select User Role"
                          persistent-hint
                          return-object
                          single-line
                          :rules="recRoleRules"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="savingrp"
                    color="primary"
                    @click="stripRole()"
                  >
                    <v-icon left v-if="!savingrp">save</v-icon>
                    {{ savingrp ? "Processing..." : "Save" }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="roleDialog = false">
            <v-icon left>close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" x-large v-if="saving" disabled class="px-6">
            <v-progress-circular :size="25" indeterminate></v-progress-circular>
            <span class="ml-5">{{ proc_msg }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "User Account Management";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      user_id: this.$root.curuser.id,
      headerData: {
        title: "User Account Management",
        subtitle: "View and manage all user accounts.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Settings",
            disabled: false,
            href: "#",
          },
          {
            text: "User Account Management",
            disabled: true,
            href: "user-management",
          },
        ],
      },
      lightboxDialog: false,
      lightbox_image: "",
      lightbox_title: "",
      roleDialog: false,
      tab: null,
      avatars: [],
      roles: [],
      permissions: [],
      items: [],
      itemCount: 0,
      search: null,
      headers: [
        {
          text: "Picture",
          align: "left",
          sortable: false,
          value: "avatar_id",
        },
        { text: "Role (ACL)", value: "role" },
        { text: "Staff ID", value: "staff_id" },
        { text: "Surname", value: "surname" },
        { text: "First Name", value: "firstname" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Email Address", value: "email" },
        { text: "ACTION", value: "id" },
      ],
      all_roles: [],
      all_permissions: [],
      isLoading: true,
      usersItem: [],
      selected_role: "",
      selectedUser: null,
      selected_user_id: "",
      selected_user_names: "",
      selected_user_staff_id: "",
      disabled: 1,
      loading: false,
      saving: false,
      savingrp: false,
      deleting: false,
      error: false,
      errors: [],
      minp: 0,
      staff_id: "",
      surname: "",
      firstname: "",
      othernames: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirmation: "",
      checkbox: false,
      ss: false,
      valid: false,
      errMsg: "",
      ssMsg: "",
      response: "",
      recUserRules: [(v) => !!v || "User is required"],
      recRoleRules: [(v) => !!v || "Role is required"],
      nameRules: [(v) => !!v || "User is required"],
      roleRules: [(v) => !!v || "Role is required"],
      surnameRules: [(v) => !!v || "Family name is required"],
      firstnameRules: [(v) => !!v || "Given name is required"],
      // othernamesRules: [v => !!v || "Additional name is required"],
      phonenumberRules: [
        (v) => !!v || "Phone number is required",
        (v) => v.length == 11 || "11 characters required",
      ],
      emailRules: [
        (v) => !!v || "Email address is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
      ],
      confirmpasswordRules: [
        (v) => !!v || "Password confirmation is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
        (v) => v === this.password || "Passwors do not match",
      ],
      staffIdRules: [(v) => !!v || "Staff identification number is required"],
    };
  },

  mixins: [mounted, methods],
};
</script>

<style></style>
