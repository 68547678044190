<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <h3 class="font-weight-light">
                {{ pageTitle }}
              </h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <!-- <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                dark
                color="secondary"
                @click="addItemDialog = true"
              >
                <v-icon>add</v-icon> New Record
              </v-btn> -->
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <span v-for="(user, i) in users_list" :key="i">
                          <span v-if="user.id == item.hod">
                            <v-chip
                              class="clickable"
                              pill
                              color="blue-grey darken-2"
                              dark
                              outlined
                            >
                              <v-avatar left>
                                <v-img :src="user.avatar_id"></v-img>
                              </v-avatar>
                              {{ user.firstname }} {{ user.surname }}
                              {{ user.othernames }}
                            </v-chip>
                          </span>
                        </span>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>
                        <v-btn
                          color="blue-grey"
                          rounded
                          outlined
                          small
                          dark
                          @click="viewItem(item)"
                        >
                          <v-icon>visibility</v-icon> View
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addItemDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Department"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      name="hod"
                      v-model="hod"
                      :items="users_list"
                      item-text="name"
                      label="Choose Head of Department"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded dark class="px-6" @click="addItem()">
            <v-icon left>save</v-icon> Save record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="departmentModal" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <h4 class="font-weight-light">{{ currentItem.name }}</h4>
        </v-card-title>
        <v-divider class="primary my-2"></v-divider>
        <v-card-text>
          <v-list three-line>
            <template v-for="(item, i) in currentItemMembers">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="i"
                :inset="item.inset"
              ></v-divider>

              <v-list-item v-else :key="item.user_id">
                <v-list-item-avatar>
                  <span v-for="(user, i) in users_list" :key="i">
                    <span v-if="user.id == item.user_id">
                      <v-img :src="user.avatar_id"></v-img
                    ></span>
                  </span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <span v-for="(user, i) in users_list" :key="i">
                      <span v-if="user.id == item.user_id">
                        {{ user.firstname }} {{ user.surname }}
                        {{ user.othernames }}</span
                      >
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-for="(user, i) in users_list" :key="i">
                      <span v-if="user.id == item.user_id">
                        {{ user.staff_id }} <br />
                        {{ user.phone_number }} <br />
                        {{ user.email }}</span
                      >
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded dark color="primary" @click="closeModal">
            <v-icon left>close</v-icon> Close & Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Departments";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      pageTitle: "Departments and Staff",
      name: "",
      count: 0,
      hod: {},
      addItemDialog: false,
      users_list: this.$root.users_list,
      add_item_dialog_title: "Add a department",
      item_in_view: {},
      edit_item_dialog_title: "Edit Pension",
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Departments",
        subtitle: "Departments and Staff members",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Departments",
            disabled: true,
            href: "staff",
          },
        ],
      },
      loading: true,
      departmentModal: false,
      hodModal: false,
      itemCount: 0,
      search: null,
      status: {},
      headers: [
        { text: "HOD", value: "hod" },
        { text: "Department", value: "name" },
        { text: "ACTIONS", value: "id" },
      ],
      currentItemMembers: [],
      currentItem: [],
      biographies: [],
      items: [],
      title: "",
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      requiredRules: [(v) => !!v || "This field is required"],
      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
