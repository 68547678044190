<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ pageTitle }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn rounded text @click="refreshTable()">
              <v-icon>refresh</v-icon> Refresh
            </v-btn>
            <v-btn
              rounded
              darke
              color="primary"
              outlined
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      {{ item.institution }}
                    </td>
                    <td>{{ item.date_obtained }}</td>
                    <td>
                      {{ item.qualification_obtained }}
                    </td>
                    <td width="10">
                      {{ item.status.name }}
                    </td>
                    <td>
                      <v-btn
                        color="orange darken-2"
                        dark
                        outlined
                        rounded
                        small
                        class="mr-3"
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon> Edit
                      </v-btn>
                      <v-btn
                        color="cyan darken-2"
                        dark
                        outlined
                        rounded
                        small
                        @click="viewDetail(item)"
                      >
                        <v-icon>visibility</v-icon> View
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="institution"
                      v-model="institution"
                      label="institution"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="date_obtained"
                      v-model="date_obtained"
                      type="text"
                      label="Date obtained"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="qualification_obtained"
                      v-model="qualification_obtained"
                      label="Qualification obtained"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded dark class="pa-4" @click="addItem()">
            <v-icon left>save</v-icon> Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Edit Academic Qualification</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="institution"
                      v-model="institution"
                      label="institution"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="date_obtained"
                      v-model="date_obtained"
                      type="text"
                      label="Date obtained"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="qualification_obtained"
                      v-model="qualification_obtained"
                      label="Qualification obtained"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            dark
            class="pa-4"
            @click="editItemArray(item_in_view)"
          >
            <v-icon left>save</v-icon> Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          Academic Qualification Detail
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="50" color="primary">apartment</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.institution"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Institution Attended
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="50" color="primary">school</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.qualification_obtained"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Qualification Obtained
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="50" color="primary">event_available</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.date_obtained"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      Year Graduated
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="50" color="primary">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.status"
                    ></v-list-item-title>
                    <v-list-item-subtitle> Status </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded text @click="detailsDialog = false"> close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Qualification",
  props: ["Qualification"],
  data() {
    return {
      pageTitle: "My Academic Qualifications",
      detailsDialog: false,
      details: [],
      currentItem: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      institution: "",
      date_obtained: "",
      qualification_obtained: "",
      status: { id: 1, name: "Pending" },
      status_list: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Suspended" },
      ],
      add_item_dialog_title: "Add a Qualification",
      addItemDialog: this.Qualification.addItemDialog,
      headers: [
        { text: "Institution", value: "institution" },
        { text: "Date obtained", value: "date_obtained" },
        { text: "Qualification obtained", value: "qualification_obtained" },
        { text: "Status", value: "status" },
        { text: "ACTIONS", value: "id" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Qualification.users_list,
      loading: false,
      item_in_view: {},
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
