<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title> My activities </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-timeline>
                <v-timeline-item
                  v-for="(year, i) in items"
                  :key="i"
                  :color="year.color"
                  small
                >
                  <template v-slot:opposite>
                    <span
                      :class="`headline font-weight-bold ${year.color}--text`"
                      v-text="year.time"
                    ></span>
                  </template>
                  <div class="py-4">
                    <h2
                      :class="`headline font-weight-light mb-4 ${year.color}--text`"
                    >
                      {{ year.title }}
                    </h2>
                    <div v-html="year.subtitle"></div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Pension";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      years: [
        {
          color: "cyan",
          year: "1960",
        },
        {
          color: "green",
          year: "1970",
        },
        {
          color: "pink",
          year: "1980",
        },
        {
          color: "amber",
          year: "1990",
        },
        {
          color: "orange",
          year: "2000",
        },
      ],
      item_in_view: {},
      edit_item_dialog_title: "Edit Pension",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Activity feed",
        subtitle: "Timelines of activities in my account",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Timeline",
            disabled: true,
            href: "pension",
          },
        ],
      },
      items: [],
      search: null,
      title: "",
      headers: [{ text: "Staff Name", value: "staff_name" }],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],

      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
