import { apiUrl } from "@/config";
import Axios from "axios";

export default {
  methods: {
    // Get users
    getUsers() {
      Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.items = response.data.data;
          this.itemCount = this.items.length;
          this.roles = response.data.roles;
          this.permissions = response.data.permissions;
          this.avatars = response.data.avatars;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Get Roles
    getRoles() {
      Axios.get(`${apiUrl}/roles/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.all_roles = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response.data);
        });
    },
    // Get Permissions
    getPermissions() {
      Axios.get(`${apiUrl}/permissions/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.all_permissions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response.data);
        });
    },
    // Light Box
    lightBox(title, image) {
      this.lightbox_title = title;
      this.lightbox_image = image;
      this.lightboxDialog = true;
    },
    // Save User
    saveUser() {
      if (this.$refs.newUserForm.validate()) {
        this.saving = true;
        this.disabled = 1;
        Axios.post(`${apiUrl}/register`, {
          staff_id: this.staff_id,
          surname: this.surname,
          firstname: this.firstname,
          othernames: this.othernames,
          phone_number: this.phone_number,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
          .then((response) => {
            this.saving = false;
            this.ss = true;
            this.ssMsg = response.data.message;
            this.getUsers();
            this.$refs.newUserForm.reset();
            setTimeout(() => (this.ss = false), 4000);
          })
          .catch(({ response }) => {
            this.disabled = 0;
            this.saving = false;
            this.errors = response.data.error;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: response.data.message,
              text:
                "User registration failed, ensure you filled all required fields.",
            });
          });
      }
    },
    // get selected user name
    getSelectedUser() {
      this.selected_user_names =
        this.selectedUser.surname + " " + this.selectedUser.firstname;
      this.selected_user_id = this.selectedUser.id;

      this.selected_user_staff_id = this.selectedUser.staff_id;
    },
    // Assing Role To User
    assignRole() {
      if (this.$refs.assignRoleForm.validate()) {
        this.savingrp = true;
        Axios.post(
          `${apiUrl}/assign/role-to-user`,
          {
            user_id: this.selected_user_id,
            role: this.selected_role,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.savingrp = false;
            this.getUsers();
            this.$refs.assignRoleForm.reset();
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Request Successful",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            this.savingrp = false;
            console.log(response.data);
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: response.data.message,
              text:
                "User registration failed, ensure you filled all required fields.",
            });
          });
      }
    },
    // Assing Role To User
    stripRole() {
      if (this.$refs.stripRoleForm.validate()) {
        this.savingrp = true;
        Axios.post(
          `${apiUrl}/revoke/role-from-user`,
          {
            user_id: this.selected_user_id,
            role: this.selected_role,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.savingrp = false;
            this.getUsers();
            this.$refs.stripRoleForm.reset();
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: "Request Successful",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            this.savingrp = false;
            console.log(response.data);
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: response.data.message,
              text:
                "User registration failed, ensure you filled all required fields.",
            });
          });
      }
    },
  },
};
