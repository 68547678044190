import Axios from "axios";
import { apiUrl } from "@/config";

export default {
  methods: {
    resetPassword() {
      if (this.$refs.resetform.validate()) {
        this.loading = true;

        Axios.post(`${apiUrl}/password/create`, {
          email: this.email,
        })
          .then((response) => {
            this.loading = false;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "success",
              title: response.data.message,
              text:
                "You have successfully changed your password. Protect your password at all time.",
            });
            setTimeout(() => this.$router.push({ path: "/" }), 3000);
          })
          .catch(({ response }) => {
            this.loading = false;
            this.error = true;
            this.errors = response.data.errors;
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text: response.data.message,
            });
          });
      }
    },
  },
};
