<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm12>
          <template>
            <v-container class="grey lighten-5">
              <v-row no-gutters>
                <v-col
                  v-for="link in gridLinks"
                  :key="link.name"
                  cols="12"
                  sm="3"
                >
                  <v-card
                    class="pa-2 ma-2 rounded-card elevation-2"
                    tile
                    router
                    :to="link.route"
                  >
                    <v-layout column align-center justify-center>
                      <img :src="link.icon" style="width: 60%; height: 120px" />
                      {{ link.name }}
                    </v-layout>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Scripts
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "Application Console";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      gridLinks: [
        {
          name: "My Account",
          icon: require("@/assets/new/User Male_104px.png"),
          route: "/account/staff",
        },
        {
          name: "Appraisal",
          icon: require("@/assets/System Report_100px.png"),
          route: "/appraisal/hod",
        },

        // {name:'Staff Attendance',icon:require("@/assets/Pass Fail_100px.png"),route:'/staff-attendance'},
        {
          name: "Staff Insurance",
          icon: require("@/assets/Lease_100px.png"),
          route: "/insurance/staff",
        },
        // {name:'Disciplinaries',icon:require("@/assets/Policy Document_100px.png"),route:'/disciplinaries'},
        // {name:'HMO',icon:require("@/assets/Ambulance_100px.png"),route:'/hmo/staff'},
        // {name:'Staff Development',icon:require("@/assets/human_arrow.png"),route:'/development'},
        {
          name: "Travels",
          icon: require("@/assets/Traveler_96px_1.png"),
          route: "/travels",
        },
        {
          name: "Leave Advice",
          icon: require("@/assets/hat_and_bag.png"),
          route: "/leave/hod",
        },
        {
          name: "Pension",
          icon: require("@/assets/old_people.jpg"),
          route: "/pension",
        },
        {
          name: "Activity Feed",
          icon: require("@/assets/Myspace 3_100px_1.png"),
          route: "/timeline",
        },
      ],
      testAvatar: require("@/assets/insurance.jpg"),
      headerData: {
        title: "Dashboard",
        subtitle: "Manage all HR activities all in one place",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
        ],
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      user_id: this.$root.curuser.id,
      user_role: this.$root.curuserroles[0],
      supportHeader: "Contact Support",
      supportContacts: [
        {
          name: "Ibeh Ernest",
          phone: "08020689069",
          email: "e.ibeh@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "John Doe",
          phone: "07120689068",
          email: "j.doe@icms.ng",
          avatar: "avatar1.png",
        },
        {
          name: "Jane Shinene",
          phone: "07020689067",
          email: "j.shinene@icms.ng",
          avatar: "avatar1.png",
        },
      ],
      loading: false,
      ss: false,
      err: false,
      ssMsg: "",
      errMsg: "",
      error: false,
      errors: {},
      response: "",
      senderSurname: this.$root.curuser.surname,
      senderFirstname: this.$root.curuser.firstname,
      senderEmail: this.$root.curuser.email,
      fullname: "",
      message: "",
    };
  },

  mixins: [mounted, methods],
};
</script>
<style>
.rounded-card {
  border-radius: 30px;
  cursor: pointer;
}
</style>
