import { apiUrl, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;

      this.surname = item.surname;
      this.othernames = item.othernames;
      this.phone_no = item.phone_no;
      this.address = item.address;
      this.state = item.state;
      this.country = item.country;
      this.profession = item.profession;
      this.rel_type = item.rel_type;
      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.surname = this.surname;
        item.othernames = this.othernames;
        item.phone_no = this.phone_no;
        item.profession = this.profession;
        item.state = this.state;
        item.address = this.address;
        item.country = this.country;
        item.rel_type = this.rel_type;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              surname: item.surname,
              othernames: item.othernames,
              phone_no: item.phone_no,
              address: item.address,
              state: item.state,
              country: item.country,
              profession: item.profession,
              rel_type: item.rel_type.name,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrnexofkins/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);

          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " marked your Next of kin as " +
              item.status,
            title: "Next of kin update from HR",
            sender_id: this.$root.curuser.id,
          };
          let receiver_ids = [];
          receiver_ids.push(this.user_in_view.id);
          notification_data.receiver_ids = receiver_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.surname == "") {
        return false;
      }
      if (this.address == "") {
        return false;
      }
      if (this.phone_no == "") {
        return false;
      }
      if (this.othernames == "") {
        return false;
      }
      return true;
    },
    clearFields() {
      this.surname = "";
      this.address = "";
      this.phone_no = "";
      this.address = "";
      this.othernames = "";
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,
        surname: this.surname,
        othernames: this.othernames,
        phone_no: this.phone_no,
        address: this.address,
        state: this.state,
        country: this.country,
        profession: this.profession,
        rel_type: this.rel_type,
        status: this.status,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,

        surname: item.surname,
        othernames: item.othernames,
        phone_no: item.phone_no,
        address: item.address,
        state: item.state,
        country: item.country,
        profession: item.profession,
        rel_type: item.rel_type.name,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrnexofkins`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.surname == item.surname && data.phone_no == item.phone_no) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.surname == item.surname &&
                storedData.phone_no == item.phone_no
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.surname == item.surname && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrnexofkins`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },

    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      //         this.getAllData()
    },
  },
};
