<template class="cover my-4">
  <div>
    <v-container class="fill-height auth-bg" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card class="elevation-12">
            <v-row class="px-3">
              <v-col
                cols="12"
                md="6"
                class="auth-inner-bg"
                style="padding: 0px"
              >
              </v-col>
              <v-col cols="12" md="6">
                <v-card-text class="mt-4">
                  <div class="text-center">
                    <img :src="images.logo" alt="Staff Portal" />
                  </div>
                  <h2
                    class="text-center display2 blue-grey--text text--darken-4 text-uppercase font-weight-medium section-title mt-6"
                  >
                    <v-icon color="blue-grey darken-4" class="pa-2" size="22">{{
                      signInIcon
                    }}</v-icon>
                    {{ signInTitle }} <span></span>
                  </h2>
                  <div class="text-center ma-6">
                    {{ signInWelcome }}
                  </div>
                  <v-form ref="loginform" @submit.prevent="login">
                    <v-text-field
                      id="email"
                      label="Email Address"
                      name="Email"
                      prepend-icon="email"
                      type="email"
                      color="blue darken-4"
                      v-model="email"
                      :rules="emailRules"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      label="Password"
                      name="Password"
                      prepend-icon="lock"
                      color="blue darken-4"
                      v-model="password"
                      :value="password"
                      :rules="passwordRules"
                      :append-icon="value ? 'visibility' : 'visibility_off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <span class="blue-grey--text">
                    New to this portal? <br />Contact HR for ACCOUNT CREATION
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="loading"
                    rounded
                    color="primary"
                    @click="login()"
                  >
                    {{ loading ? "Authenticating..." : "Sign In" }}
                    <v-icon right v-if="!loading">exit_to_app</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="footer-data grey--text">
            <v-col cols="12" sm="6" md="6">
              @{{ new Date().getFullYear() }} {{ ownedBy }} {{ copyRight }}
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pr-7">
              {{ developerIntro }}<a :href="developerUrl">{{ developedBy }}</a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <Loader :LoaderDialog="LoaderData"></Loader>

    <v-dialog v-model="loginAccess" persistent max-width="350">
      <v-card class="mx-auto" tile>
        <v-card-title>
          <span class="text-uppercase"> Choose your access mode </span>
        </v-card-title>
        <v-list rounded>
          <v-list-item-group v-model="item" color="primary">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="proceed(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
import Loader from "@/components/Global/Loader/page";

export default {
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("auth")) {
      return next({ path: "/dashboard/staff" });
    }
    next();
  },

  created() {
    document.title = "Authentication";
  },
  // components: { Password },
  props: {
    source: String,
  },

  components: {
    Loader,
  },
  data() {
    return {
      cur_staff: {},
      value: String,
      item: 1,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      loginAccess: false,
      department: [],
      department_list: [],
      biography_list: [],
      LoaderData: {
        dialog: false,
        message: "Please wait while we prepare your dashboard...",
      },
      users_list: [],
      drawer: null,
      forgotPassword:
        "Forgotten your password? Click to here to contact SUPPORT",
      signInWelcome:
        "Welcome back, enter your credentials to access your portal.",
      signInTitle: "Authentication",
      signInButton: "Sign In",
      signInIcon: "security",
      developerIntro: "Powered By: ",
      developedBy: "Integrated Cash Management Services Ltd.",
      developerUrl: "https://icms.ng/",
      ownedBy: "Staff Portal.",
      copyRight: "All right reserved",
      images: {
        sideImage: require("@/assets/vault.png"),
        logo: require("@/assets/logo.png"),
      },
      supportIntro: "Send mail to ",
      supportOutro: "requesting for a password reset",
      supportEmail: "support@icms.ng",
      modalOpen: false,
      saving: false,
      valid: false,
      loading: false,
      errors: {},
      location: "",
      sel_shift: "",
      response: "",
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "Email address name is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
      ],
      shiftRules: [(v) => !!v || "Shift is required"],
      locationRules: [(v) => !!v || "Location is required"],
    };
  },

  mixins: [methods, mounted],
};
</script>

<style>
.ln {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 60%;
  margin: -25px 0 0 -25px;
}
.forgot-text {
  font-size: 13px;
}
.auth-bg {
  height: 100%;
  background-color: #fff;
  /* background-color: #eee; */
}
.auth-bg-in {
  height: 100%;
  background-image: url("../../assets/vault.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.auth-inner-bg {
  /* // display: flex;
  // justify-content: center;
  // align-items: center; */
  width: 100%;
  height: 600px;
  background-image: url("../../assets/vault.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.footer-data {
  font-size: 16px !important;
}
.footer-data a {
  color: #333;
}
div.absolute {
  position: absolute;
  bottom: 20px;
  width: 28%;
}
/* a {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}
a:visited {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}
a:active {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg.png") no-repeat;
  box-sizing: border-box;
}

.leftDiv {
  position: absolute;
  top: 0;
  right: 0;
  padding: 110px 30px 50px 30px;
  width: auto;
  min-height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
/* color: white; */
/* box-sizing: border-box;
} */
</style>
