<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <!-- <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn fab color="secondary" @click="addItemDialog = true">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td class="myFocus" @click="viewDetail(item)">
                      {{ item.title }}
                    </td>
                    <!-- <td>{{ item.description }}</td> -->
                    <!-- <td>{{ item.from_location }}</td>
                      <td>{{ item.date_invited }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.appeared }}</td>
                      <td>{{ item.panel_members }}</td>
                      <td>{{ item.panel_decision }}</td>
                      <td>{{ item.management_decision }}</td> -->

                    <!-- <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <h2>
                            <span >
                              {{item.invited_by.surname }}
                            </span>
                            
                          </h2>
                        </v-chip>
                      </td> -->
                    <td>
                      <v-chip
                        class="ma-2"
                        :color="item.status.name == 'Rejected' ? 'red' : 'blue'"
                        text-color="white"
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Title"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="description"
                      v-model="description"
                      type="text"
                      label="description"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="invited_by"
                      v-model="invited_by"
                      :items="users_list"
                      item-text="name"
                      label="Invited by"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="invited_on_dialog"
                      :return-value.sync="invited_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="invited_on"
                          label="Invited on"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="invited_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invited_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(invited_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="appeared"
                      v-model="appeared"
                      :items="appeared_list"
                      item-text="name"
                      label="Appeared?"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="panel_members"
                      v-model="panel_members"
                      type="text"
                      label="panel_members"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="panel_decision"
                      v-model="panel_decision"
                      type="text"
                      label="Panel decision"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="management_decision"
                      v-model="management_decision"
                      type="text"
                      class="ml-5"
                      label="Management decision"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="rdialog"
                      v-model="date_invited_dialog"
                      :return-value.sync="date_invited"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date_invited"
                          label="Date invited"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="date_invited"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="date_invited_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rdialog.save(date_invited)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="title"
                      v-model="title"
                      label="Title"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="description"
                      v-model="description"
                      type="text"
                      label="description"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="invited_by"
                      v-model="invited_by"
                      :items="users_list"
                      item-text="name"
                      label="Invited by"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="invited_on_dialog"
                      :return-value.sync="invited_on"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="invited_on"
                          label="Invited on"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="invited_on"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invited_on_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(invited_on)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="appeared"
                      v-model="appeared"
                      :items="appeared_list"
                      item-text="name"
                      label="Appeared?"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm8>
                    <v-text-field
                      name="panel_members"
                      v-model="panel_members"
                      type="text"
                      label="panel_members"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="panel_decision"
                      v-model="panel_decision"
                      type="text"
                      label="Panel decision"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="management_decision"
                      v-model="management_decision"
                      type="text"
                      class="ml-5"
                      label="Management decision"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="rdialog"
                      v-model="date_invited_dialog"
                      :return-value.sync="date_invited"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date_invited"
                          label="Date invited"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="date_invited"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="date_invited_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rdialog.save(date_invited)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Disciplinary",
  props: ["Disciplinary"],
  data() {
    return {
      user_in_view: this.Disciplinary.user,
      details: [],
      hr_ids: [],
      detailsDialog: false,
      title: "",
      description: "",
      from_location: "1",
      date_invited: "",
      date_invited_dialog: false,
      appeared: {},
      appeared_list: [
        { id: 1, name: "YES" },
        { id: 2, name: "NO" },
      ],
      panel_members: "",
      panel_decision: null,
      management_decision: null,
      invited_by: {},
      invited_on_dialog: false,
      invited_on: "",
      status: {},
      status_list: [
        { id: 1, name: "Accepted" },
        { id: 2, name: "Rejected" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      edit_item_dialog_title: "",
      editItemDialog: false,
      add_item_dialog_title: "Add a Disciplinary",
      addItemDialog: this.Disciplinary.addItemDialog,
      headers: [
        { text: "Title", value: "title" },
        // { text: "Description", value: "description" },
        // { text: "from_location", value: "from_location" },
        // { text: "date_invited", value: "date_invited" },
        // { text: "Status", value: "status" },
        // { text: "Appeared", value: "appeared" },
        // { text: "panel_members", value: "panel_members" },
        // { text: "panel_decision", value: "panel_decision" },
        // { text: "management_decision", value: "management_decision" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      items: this.Disciplinary.user.hrdisciplinaries,
      itemCount: 0,
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: this.addMonth(8),
      search: null,
      users_list: this.Disciplinary.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
