import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    //Get all Departments
    async getAllDepartmentss() {
      await Axios.get(`${apiUrl}/departments`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.items = data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    // Fetch all biographies
    async getAllBiographies() {
      await Axios.get(`${apiUrl}/hrbiographies`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          this.biographies = data;
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
        });
    },

    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          this.users_list = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    viewItem(item) {
      this.currentItem = item;
      this.biographies.forEach((element) => {
        if (element.department_id == item.id) {
          this.currentItemMembers.push(element);
        }
      });
      this.departmentModal = true;
    },

    closeModal() {
      this.currentItemMembers = [];
      this.departmentModal = false;
    },

    viewHod(user) {
      this.hod = user;
      this.hodModal = true;
    },

    validateForm() {
      if (this.name == "") {
        return false;
      }

      if (isEmpty(this.hod)) {
        return false;
      }
      return true;
    },
    clearFields() {
      this.name = "";
      this.hod = {};
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        name: this.name,
        hod: this.hod,
        children: [],
      };
      // let member = item.hod
      // member.name = member.name + '(HOD)'
      item.children.push(item.hod);
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        name: item.name,
        hod: item.hod.id,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/departments`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.name == item.name) {
            this.items.forEach((storedData, index) => {
              if (storedData.name == item.name) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });

          let notification_data = {
            message: "You have been assign the role of HOD to " + item.name,
            title: "HEAD OF DEPARTMENT ROLE",
            sender_id: this.$root.curuser.id,
          };
          let receiver_ids = [];
          receiver_ids.push(item.hod);
          notification_data.receiver_ids = receiver_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          //   item.id = 0
          this.items.forEach((element, index) => {
            if (element.name == item.name && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    prepTree() {
      this.items = [];
      this.$root.departments_list.forEach((department) => {
        department.children = department.members_list;
        this.items.push(department);
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.prepTree();
      this.getAllUsers();
      this.getAllBiographies();
      this.getAllDepartmentss();
    },
  },
};
