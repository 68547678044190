import { apiUrl, timeNow, todayDate, mapApiKey } from "@/config";
import Axios from "axios";

export default {
  methods: {
    // Get User Lat Long
    getUserLatLong() {
      this.clockingIn = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          for (let i = 0; i < this.officeLatLong.length; i++) {
            console.log(this.officeLatLong[i].lat, this.officeLatLong[i].lng);

            if (
              this.officeLatLong[i].lat === lat &&
              this.officeLatLong[i].lng
            ) {
              console.log("Matched Office");
              this.clockInObj.officeLocation = this.officeLatLong[i].location;
              this.clockInObj.date = todayDate();
              this.clockInObj.time = timeNow();
              this.clockInObj.title = "Attendance Marked";
              break;
            } else {
              this.clockInObj.date = todayDate();
              this.clockInObj.time = timeNow();
              this.clockInObj.title = "Attendance Marked";
              this.notOfficeLocation = true;
            }
          }
          // this.officeLatLong.forEach((office) => {
          //   if (lat == office.lat && lng == office.lng) {
          //     console.log("Matched Office");
          //     console.log(office.lat, office.lng);
          //     this.clockInObj.officeLocation = office.location;
          //     this.clockInObj.date = todayDate();
          //     this.clockInObj.time = timeNow();
          //     this.clockInObj.title = "Attendance Marked";
          //     // this.events.push(this.clockInObj);
          //   } else {
          //     console.log("Not  Matched Office");
          //     console.log(office.lat, office.lng);
          //     this.clockInObj.date = todayDate();
          //     this.clockInObj.time = timeNow();
          //     this.clockInObj.title = "Attendance Marked";
          //     this.notOfficeLocation = true;
          //   }
          // });
          this.fetchAddressFromGeocoder(lat, lng);
          this.attendanceModal = true;
          this.clockingIn = false;
        },
        (error) => {
          this.clockingIn = false;
          console.log(error.message);
          this.$notify({
            group: "platform",
            duration: 6000,
            type: "error",
            title: "Clock In Failed",
            text:
              "Your attendance for today was not marked. Make sure you are clocking in from any of our approved office or adhoc locations.",
          });
        }
      );
    },

    // Fetch address from GeoCoder
    async fetchAddressFromGeocoder(lat, long) {
      await Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${mapApiKey}`
      )
        .then((response) => {
          this.streetRealAddress = response.data.results[0];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    addItem(item, location) {
      this.saving = true;
      let data = null;
      if (item.officeLocation == "") {
        data = {
          user_id: this.$root.curuser.id,
          time_in: item.time,
          signed_in_date: item.date,
          signed_in_location: location,
          remark: this.remark,
        };
      } else {
        data = {
          user_id: this.$root.curuser.id,
          time_in: item.time,
          signed_in_date: item.date,
          signed_in_location: item.officeLocation,
          remark: this.remark,
        };
      }

      Axios.post(`${apiUrl}/hrattendances`, data, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data.message);
          this.init();
          this.$notify({
            group: "platform",
            duration: 6000,
            type: "success",
            title: "Clock In Successful",
            text: "Your attendance for today has been successfully marked.",
          });
          this.saving = false;
          this.attendanceModal = false;
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response);
          this.saving = false;
        });
    },

    async getAllData() {
      this.loading = true;
      await Axios.get(`${apiUrl}/hrattendances`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let res = response.data.data;
          // let data = response.data.data.reverse();
          let elm = {};
          res.forEach((attend) => {
            this.items.push(attend);
            this.attendance_dates.push(attend.signed_in_date);
            // check if user has signed in today
            if (attend.signed_in_date == this.currentDate) {
              this.attendanceNotMarked = false;
            }
            elm = {
              title: "Attendance Marked",
              allDay: true,
              editable: false,
              backgroundColor: "TEAL",
              textColor: "#FFFFFF",
              officeLocation: attend.signed_in_location,
              date: attend.signed_in_date,
              time: attend.time_in,
              remark: attend.remark,
            };
            this.clockInObjs.push(elm);
          });
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = true;
          console.log(response);
        });
    },

    init() {
      this.getAllData();
    },
  },
};
