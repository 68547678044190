import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    isNull(data) {
      return isEmpty(data);
    },

    clearFields() {
      this.user = {};
      this.hmo_id = "";
      this.hospital = "";
      this.plan = "";
      this.provider = "";
    },
    removeItemFromAPI(item) {
      Axios.delete(`${apiUrl}/hrhmos/${item.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Truck Deleted!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Delete Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    proceedToRemoveItem(item) {
      this.item_in_view = item;
      this.confirmDeleteDialog = true;
    },
    removeItem(item) {
      this.items.forEach((element, index) => {
        if (element == item) {
          this.removeItemFromAPI(item);
          this.items.splice(index, 1);
        }
      });
      this.confirmDeleteDialog = false;
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrhmos/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.user = this.user;
        item.plan = this.plan;
        item.hospital = this.hospital;
        item.provider = this.provider;
        item.hmo_id = this.hmo_id;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              user_id: item.user.id,
              plan: item.plan,
              hospital: item.hospital,
              provider: item.provider,
              hmo_id: item.hmo_id,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    editItem(item) {
      this.item_in_view = item;
      this.user = item.user;
      this.hmo_id = item.hmo_id;
      this.hospital = item.hospital;
      this.provider = item.provider;
      this.plan = item.plan;
      this.status = item.status;
      this.editItemDialog = true;
    },
    validateForm() {
      if (this.provider == "") {
        return false;
      }
      if (this.hmo_id == "") {
        return false;
      }
      if (this.plan == "") {
        return false;
      }
      if (this.hospital == "") {
        return false;
      }

      if (isEmpty(this.status)) {
        return false;
      }

      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.user,
        hospital: this.hospital,
        plan: this.plan,
        hmo_id: this.hmo_id,
        provider: this.provider,
        created_at: "Just Now",
        updated_at: "Just Now",
        status: this.status,
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        plan: item.plan,
        hospital: item.hospital,
        hmo_id: item.hmo_id,
        provider: item.provider,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrhmos`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.hmo_id == item.hmo_id && data.user_id == item.user_id) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.hmo_id == item.hmo_id &&
                storedData.user.id == item.user_id
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "HMO Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.hmo_id == item.hmo_id && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    publishTable(data) {
      data.forEach((hmo) => {
        let user = this.users_list.filter(
          (element) => hmo.user_id == element.id
        );
        let status = this.status_list.filter(
          (element) => hmo.status == element.name
        );

        hmo.status = status.length > 0 ? status[0] : {};
        hmo.user = user.length > 0 ? user[0] : {};
        this.items.push(hmo);
      });
    },
    detailsDialogPopup(item) {
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.getAllUsers();
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrhmos`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });
          this.getAllData();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getAllEligibilityGroups() {
      await Axios.get(`${apiUrl}/hrtraveleligibilitygroups`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.hrtraveleligibilitygroup_list = response.data.data.reverse();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },
    init() {
      this.getAllUsers();
    },
  },
};
