<template>
  <div class="account">
    <v-container fluid>
      <inner-header :headerData="headerData"></inner-header>
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="general_tab"
            background-color="white"
            centered
            light
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
              v-for="mtab in general_tab_items"
              :key="mtab.title"
              :href="mtab.href"
              @click="openTabs(mtab)"
            >
              {{ mtab.title }}
              <v-icon>{{ mtab.icon }}</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="general_tab">
            <v-tab-item
              v-for="(mtab, i) in general_tab_items"
              :key="i"
              :value="'tab-' + i"
            >
              <v-card flat>
                <!-- <v-divider class="primary my-4"></v-divider> -->
                <v-card-text>
                  <div v-if="mtab.title == 'Biography'">
                    <Biography :Biography="BiographyData" />
                  </div>
                  <div v-if="mtab.title == 'Qualifications'">
                    <Qualification :Qualification="QualificationData" />
                  </div>
                  <div v-if="mtab.title == 'Trainings'">
                    <Training :Training="TrainingData" />
                  </div>
                  <div v-if="mtab.title == 'Last Employer'">
                    <LastEmployer :LastEmployer="LastEmployerData" />
                  </div>
                  <div v-if="mtab.title == 'Beneficiaries'">
                    <Beneficiaries :Beneficiaries="BeneficiariesData" />
                  </div>
                  <div v-if="mtab.title == 'Addresses'">
                    <Addresses :Addresses="AddressesData" />
                  </div>
                  <div v-if="mtab.title == 'Next of Kin'">
                    <Nextofkin :Nextofkin="NextofkinData" />
                  </div>
                  <div v-if="mtab.title == 'Guarantor'">
                    <Guarantor :Guarantor="GuarantorData" />
                  </div>
                  <div v-if="mtab.title == 'Query'">
                    <Query :Query="QueryData" />
                  </div>
                  <div v-if="mtab.title == 'HMO Dependants'">
                    <HMODependants :HMODependants="HMODependantsData" />
                  </div>
                  <div v-if="mtab.title == 'Development Plan'">
                    <DevelopmentPlan :DevelopmentPlan="DevelopmentPlanData" />
                  </div>
                  <div v-if="mtab.title == 'Exit'">
                    <Exits :Exits="ExitsData" />
                  </div>
                  <div v-if="mtab.title == 'HMO'">
                    <HMO :HMO="HMOData" />
                  </div>
                  <div v-if="mtab.title == 'Attendance'">
                    <Attendance :Attendance="AttendanceData" />
                  </div>
                  {{ mtab.data }}
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <Loader :LoaderDialog="LoaderData"></Loader>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import Loader from "@/components/Global/Loader/page";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";
import Biography from "./Biography/page";
import Qualification from "./Qualifications/page";
import Training from "./Training/page";
import LastEmployer from "./LastEmployer/page";
import Beneficiaries from "./Beneficiaries/page";
import Addresses from "./Addresses/page";
import Nextofkin from "./Nextofkin/page";
import Guarantor from "./Guarantor/page";
import Query from "./Query/page";
import HMODependants from "./HMODependants/page";
import DevelopmentPlan from "./DevelopmentPlan/page";
import Exits from "./Exits/page";
import HMO from "./HMO/page";
import Attendance from "./Attendance/page";

export default {
  created() {
    document.title = "My Account";
  },

  components: {
    InnerHeader,
    Biography,
    Training,
    LastEmployer,
    Beneficiaries,
    Addresses,
    Nextofkin,
    Guarantor,
    Query,
    Qualification,
    DevelopmentPlan,
    HMODependants,
    Exits,
    Attendance,
    Loader,
    HMO,
  },

  data() {
    return {
      LoaderData: {
        dialog: true,
        message: "Establishing secure connection..",
      },
      AttendanceData: {
        title: "Attendance",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      ExitsData: {
        title: "Exit",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      HMOData: {
        title: "Staff HMO",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      DevelopmentPlanData: {
        title: "Development Plan",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      HMODependantsData: {
        title: "HMO Dependants",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      QueryData: {
        title: "Query",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      GuarantorData: {
        title: "Guarantor",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      BeneficiariesData: {
        title: "Beneficiaries",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      NextofkinData: {
        title: "Next of kin",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      AddressesData: {
        title: "Staff Addresses",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      BiographyData: {
        title: "Staff Biography",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      LastEmployerData: {
        title: "Last Employer",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      QualificationData: {
        title: "Staff Qualification",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      TrainingData: {
        title: "Staff Training",
        dialog: false,
        button_loading: false,
        button_disabled: false,
        users_list: [],
        addItemDialog: false,
      },
      general_tab: null,
      info:
        "Always ensure that all your profiles are updated because your job appraisal depends on it",
      general_tab_items: [
        {
          title: "Biography",
          href: "#tab-0",
          icon: "mdi-phone",
          data:
            "Ensure that your biography information is updated to enable HR work smarter.",
        },
        {
          title: "Attendance",
          href: "#tab-1",
          icon: "mdi-heart",
          data:
            "Ensure that your attendance is marked whenever you are at the office.",
        },
        {
          title: "Qualifications",
          href: "#tab-2",
          icon: "mdi-heart",
          data:
            "Ensure that all your academic qualifications are uploaded to enable HR make a clear decision about you.",
        },
        {
          title: "Trainings",
          href: "#tab-3",
          icon: "mdi-heart",
          data:
            "Ensure that you attend all trainings as approved by management. This is for your personal growth.",
        },
        {
          title: "Last Employer",
          href: "#tab-4",
          icon: "mdi-heart",
          data:
            "Ensure that all your previous employment(s) information is uploaded to enable HR work smarter.",
        },
        {
          title: "Beneficiaries",
          href: "#tab-5",
          icon: "mdi-heart",
          data: "Ensure that all your beneficiaries are added to the portal.",
        },
        {
          title: "Addresses",
          href: "#tab-6",
          icon: "mdi-heart",
          data:
            "Failure to update your addresses could lead to sanction. Please update it now.",
        },
        {
          title: "Guarantor",
          href: "#tab-7",
          icon: "mdi-heart",
          data: "Ensure that all your gaurantors are added to the portal.",
        },
        {
          title: "Next of Kin",
          href: "#tab-8",
          icon: "mdi-heart",
          data: "Ensure that all your next of kin are added to the portal.",
        },
        {
          title: "Query",
          href: "#tab-9",
          icon: "mdi-heart",
          data: "Ensure that you attend queries as at when scheduled.",
        },
        {
          title: "HMO",
          href: "#tab-10",
          icon: "mdi-heart",
          data:
            "Ensure that your HMO information is accurate, remember Health is Wealth.",
        },
        {
          title: "HMO Dependants",
          href: "#tab-11",
          icon: "mdi-heart",
          data: "Ensure that all your HMO dependants are added to the portal.",
        },
        {
          title: "Development Plan",
          href: "#tab-12",
          icon: "mdi-heart",
          data:
            "Always follow up on your development plan to stand a chance of being recognized by management.",
        },
        {
          title: "Exit",
          href: "#tab-13",
          icon: "mdi-heart",
          data: "Exit interview is a key part of your stay with us.",
        },
      ],

      leave_status_list: this.$root.leave_statuses,
      user: this.$root.curuser,
      users_list: [],
      item_in_view: {},
      headerData: {
        title: "My staff account",
        subtitle: "Update your company profile",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/staff",
          },
          {
            text: "account",
            disabled: true,
            href: "account",
          },
        ],
      },
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
