import { apiUrl } from "@/config";
import Axios from "axios";

export default {
  methods: {
    clearSearchTerm() {
      this.search = "";
    },
    // get Users
    getUsers() {
      Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.users = response.data.data;
          this.avatars = response.data.avatars;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    activeBox(el) {
      this.is_active = el;
      if (el == 2) {
        this.inbox_icon_color = "grey darken-1";
        this.outbox_icon_color = "grey darken-1";
        this.outbox_icon_color = "primary";
        this.active_box_title = "Outbox";
        this.active_box_icon = "inbox";
        this.active_box_notice = "Sent Messages";
        this.message_type = "sent";
        this.openedMessage = false;
        this.getMessages(this.message_type);
      } else if (el == 3) {
        this.inbox_icon_color = "grey darken-1";
        this.outbox_icon_color = "grey darken-1";
        this.unread_icon_color = "primary";
        this.active_box_title = "Unread Messages";
        this.active_box_icon = "move_to_inbox";
        this.active_box_notice = "Unread Messages";
        this.message_type = "unread";
        this.openedMessage = false;
        this.getMessages(this.message_type);
      } else {
        this.unread_icon_color = "grey darken-1";
        this.outbox_icon_color = "grey darken-1";
        this.inbox_icon_color = "primary";
        this.active_box_title = "Inbox";
        this.active_box_icon = "all_inbox";
        this.active_box_notice = "Messages";
        this.message_type = "all";
        this.openedMessage = false;
        this.getMessages(this.message_type);
      }
    },
    // Get All Messages
    getMessages(type) {
      Axios.get(`${apiUrl}/usermessages/${type}/user/${this.user_id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.messages = response.data.data;
          this.total_messages = this.messages.length;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Get Unread Message Count
    getUnreadMessages() {
      Axios.get(`${apiUrl}/usermessages/unread/user/${this.user_id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.total_unread_messages = response.data.data.length;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Open message Dialog
    readMessage(id) {
      this.openedMessage = true;
      this.fetching = true;
      Axios.get(`${apiUrl}/usermessages/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.message_contains = response.data.data;
          if (this.message_contains) {
            this.fetching = false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Get Message ID and activate reply message component
    replyMessage(id, msg_id, title, sent_by) {
      this.replyMessageComponent = true;
      this.$refs.repMsg.focus();
      this.message_id = id;
      if (msg_id === null) {
        this.msg_id = id;
      } else {
        this.msg_id = msg_id;
      }
      this.message_title = "Re: " + title;
      this.users.forEach((user) => {
        if (user.id === sent_by) {
          this.selected_user_names = user.surname + " " + user.firstname;
          this.selected_user_email = user.email;
          this.sent_to = user.id;
        }
      });
    },
    forwardMessage(id, msg_id, title) {
      this.forwardMessageComponent = true;
      this.message_id = id;
      this.msg_id = msg_id;
      this.message_title = "Fw: " + title;
    },
    closeMessageComponent() {
      this.replyMessageComponent = false;
      this.forwardMessageComponent = false;
    },
    // Get selected user
    getSelectedUser() {
      this.selected_user_names =
        this.selectedUser.surname + " " + this.selectedUser.firstname;
      this.selected_user_email = this.selectedUser.email;
      this.sent_to = this.selectedUser.id;
    },
    // Reply message
    sendReply() {
      this.sending = true;
      Axios.post(
        `${apiUrl}/usermessages/reply/${this.message_id}`,
        {
          id: this.message_id,
          msg_id: this.msg_id,
          user_id: this.user_id,
          title: this.message_title,
          message: this.message,
          priority: this.priority,
          sent_by: this.user_id,
          sent_to: this.sent_to,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          this.sending = false;
          this.readMessage(this.message_id);
          this.replyMessageComponent = false;
          this.forwardMessageComponent = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Message Sent",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.log(response.data);
          this.sending = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Sendin Failed",
            text: response.data.message,
          });
        });
    },
    // Send New Message
    sendMessage() {
      this.sending = true;
      Axios.post(
        `${apiUrl}/usermessages`,
        {
          user_id: this.user_id,
          title: this.message_title,
          message: this.message,
          priority: this.priority,
          sent_by: this.user_id,
          sent_to: this.sent_to,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          this.sending = false;
          this.getMessages(this.message_type);
          this.newMessageDialog = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Message Sent",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.log(response.data);
          this.sending = false;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Sendin Failed",
            text: response.data.message,
          });
        });
    },
  },
};
