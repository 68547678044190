<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <v-card>
            <span v-if="loadingProfile">
              <v-skeleton-loader
                class="mx-auto"
                type="card"
              ></v-skeleton-loader>
            </span>
            <span v-else>
              <v-img
                v-if="curUserAvatarBig"
                :src="curUserAvatarBig"
                height="300px"
              >
                <v-layout column fill-height>
                  <v-spacer></v-spacer>

                  <v-card-title class="white--text pl-5 pt-5">
                    <div class="display-1 pl-5 pt-5">
                      {{ curUserData.firstname + " " + curUserData.surname }}
                    </div>
                  </v-card-title>
                </v-layout>
              </v-img>
              <v-img v-else :src="images.avatar" height="300px">
                <v-layout column fill-height>
                  <v-spacer></v-spacer>

                  <v-card-title class="pl-5 pt-5">
                    <div class="display-1 pl-5 pt-5">
                      {{ curUserData.firstname + " " + curUserData.surname }}
                    </div>
                  </v-card-title>
                </v-layout>
              </v-img>
            </span>

            <v-list two-line>
              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      curUserData.phone_number
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Mobile Number</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mail</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      curUserData.email
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Official Email Address</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">portrait</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      curUserData.staff_id
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Staff Identification Number</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">account_tree</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.grade }}</v-list-item-title>
                    <v-list-item-subtitle>Grade</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">school</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      profile.qualification
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Academic Qualification</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">phone_iphone</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      profile.tracker_line
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Tracker Line</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">wc</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.gender }}</v-list-item-title>
                    <v-list-item-subtitle>Gender</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">date_range</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.dob }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Date Of Birth
                      <span class="ml-5">
                        <time-ago
                          refresh
                          :datetime="profile.dob"
                          long
                          tooltip="top"
                          class="time-ago-medium"
                        ></time-ago>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">pregnant_woman</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      profile.marital_status
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Marital Status</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">home</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <p class="mt-3" v-html="profile.address"></p>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">adjust</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.lga }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Local Government Area</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">my_location</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.soo }}</v-list-item-title>
                    <v-list-item-subtitle>State Of Origin</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">room</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ profile.country }}</v-list-item-title>
                    <v-list-item-subtitle>Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">email</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      profile.secondary_email
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Secondary Email</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingProfile">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">date_range</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      curUserData.created_at
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Account created &nbsp;
                      <time-ago
                        refresh
                        :datetime="curUserData.created_at"
                        long
                        tooltip="top"
                        class="time-ago-medium"
                      ></time-ago>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </v-list>
          </v-card>
        </v-flex>

        <v-flex xs12 sm8>
          <v-card text>
            <v-card-title>
              <h3>Click Respective Button Update Account Information</h3>
            </v-card-title>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm3 v-for="(btn, index) in btns" :key="index">
                  <v-btn
                    :class="{ btnactive: is_active == btn.id }"
                    @click="activeBox(btn.id)"
                  >
                    <v-icon left>{{ btn.icon }}</v-icon>
                    {{ btn.title }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-card class="ml-3 mt-4">
            <v-card-title>
              <h3 class="primary--text">
                <v-icon medium left color="primary">{{
                  active_box_icon
                }}</v-icon>
                {{ active_box_title }}
              </h3>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 v-if="is_active == 1">
                  <v-card flat>
                    <v-card-text>
                      <h4 class="subheading my-3">
                        Kindly fill all fields with valid data
                      </h4>
                      <v-form ref="profileform">
                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_grade"
                              :items="grades"
                              item-text="name"
                              v-model="profile_grade"
                              label="Official Grade"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_qualification"
                              :items="qualifications"
                              item-text="name"
                              v-model="profile_qualification"
                              label="Academic Qualification"
                              class="ml-5"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_gender"
                              :items="genders"
                              item-text="name"
                              v-model="profile_gender"
                              label="Gender"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_marital_status"
                              :items="maritalStatus"
                              item-text="name"
                              v-model="profile_marital_status"
                              label="Marital Status"
                              class="ml-5"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-text-field
                              name="profile_tracker_line"
                              v-model="profile_tracker_line"
                              label="Official Tracker Line"
                              type="text"
                              v-mask="['###########']"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="profile_dob"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="profile_dob"
                                  label="Date Of Birth"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color="primary"
                                :min="minYear"
                                :max="maxYear"
                                v-model="profile_dob"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(profile_dob)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_country"
                              :items="countries"
                              item-text="name"
                              v-model="profile_country"
                              label="Country Of Residence"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_soo"
                              :items="states"
                              item-text="name"
                              v-model="profile_soo"
                              label="State Of Residence"
                              return-object
                              class="ml-5"
                              @change="getLgas"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-autocomplete
                              name="profile_lga"
                              :items="lgas"
                              v-model="profile_lga"
                              label="Local Government Area"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-textarea
                              name="profile_address"
                              v-model="profile_address"
                              auto-grow
                              rows="1"
                              label="Contact Address"
                              hint="How do we locate you?"
                              class="ml-5"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <v-text-field
                              name="profile_secondary_email"
                              v-model="profile_secondary_email"
                              label="Secondary Email Address"
                              type="text"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <div class="ml-5">
                              <v-layout row wrap>
                                <v-flex xs12
                                  >Choose Your Preferred Theme Color</v-flex
                                >
                                <v-flex xs12>
                                  <v-btn
                                    class="ma-2"
                                    color="white red--text"
                                    @click="changeTheme('light')"
                                    small
                                    >Light Theme</v-btn
                                  >
                                  <v-btn
                                    class="ma-2"
                                    color="grey darken-4 cyan--text"
                                    @click="changeTheme('dark')"
                                    small
                                    >Dark Theme</v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="!canEdit"
                        :disabled="saving"
                        color="primary"
                        @click="saveProfile()"
                      >
                        <v-icon left v-if="!saving">save</v-icon>
                        {{ saving ? "Processing..." : "Save Changes" }}
                      </v-btn>
                      <v-btn
                        v-else
                        :disabled="saving"
                        color="primary"
                        @click="updateProfile()"
                      >
                        <v-icon left v-if="!saving">save</v-icon>
                        {{ saving ? "Processing..." : "Update Changes" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-else-if="is_active == 2">
                  <v-card flat>
                    <v-card-text>
                      <h3>
                        Ensure the image file is
                        <span class="bold">JPG</span> or
                        <span class="bold">PNG</span> and the size should not be
                        more than
                        <span class="bold">{{ min_size }} MegaByte</span>
                      </h3>
                      <v-layout row wrap>
                        <v-flex xs12 sm3></v-flex>
                        <v-flex xs12 sm6>
                          <input
                            type="file"
                            ref="fileInput"
                            :rules="uploadRules"
                            class="no-display"
                            accept="image/jpeg"
                            @change="onFilePicked"
                          />
                          <v-card class="ma-4" shaped max-width="400">
                            <v-img
                              v-if="image_url"
                              class="white--text align-end"
                              height="400px"
                              :src="image_url"
                            ></v-img>
                            <v-img
                              v-else
                              :src="images.avatar"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                          </v-card>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <h3>
                                Name:
                                <span class="bold">{{ image_name }}</span>
                                &nbsp; Format:
                                <span class="bold">{{ image_type }}</span>
                                &nbsp; Size:
                                <span class="bold">{{ image_size }}</span>
                              </h3>
                            </v-flex>
                          </v-layout>
                          <v-alert v-if="err" :value="true" type="error">{{
                            errMsg
                          }}</v-alert>
                          <span class="ma-5">
                            <v-btn
                              x-large
                              outlined
                              color="primary"
                              @click="pickFile"
                              >Choose Image File To Upload</v-btn
                            >
                          </span>
                          <v-layout row wrap v-if="saving" class="mt-5">
                            <v-flex xs12>
                              <v-progress-linear
                                color="primary lighten-2"
                                buffer-value="0"
                                stream
                              ></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3></v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions v-if="canUpload">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="canUpload"
                        :disabled="saving"
                        color="primary"
                        @click="uploadProfileImage()"
                      >
                        {{
                          saving ? "Updating profile..." : "Upload &amp; Save"
                        }}
                        <v-icon right v-if="!saving">cloud_upload</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-else-if="is_active == 3">
                  <v-card flat>
                    <v-card-text>
                      <h4 class="subheading my-3">
                        Enter your prefered password, note that you will be
                        logged out after a successful request.
                      </h4>
                      <v-form ref="passwordform">
                        <v-text-field
                          prepend-icon="lock"
                          name="password"
                          label="Password"
                          v-model="password"
                          :rules="passwordRules"
                          hint="At least 8 characters"
                          type="password"
                        ></v-text-field>
                        <div class="errors ml-4" v-if="errors.password">
                          <small
                            class="error--text"
                            :key="error"
                            v-for="error in errors.password"
                            >{{ error }}</small
                          >
                        </div>
                        <v-text-field
                          prepend-icon="lock"
                          name="password_confirmation"
                          label="Confirm Password"
                          v-model="password_confirmation"
                          :rules="confirmpasswordRules"
                          hint="At least 8 characters"
                          type="password"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="saving"
                        color="primary"
                        @click="changePassword()"
                      >
                        <v-icon left v-if="!saving">save</v-icon>
                        {{ saving ? "Processing..." : "Save Changes" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-else-if="is_active == 4">
                  <v-card flat>
                    <v-card-text>
                      <v-layout v-if="bankForm" row wrap>
                        <v-flex xs12>
                          <v-card flat>
                            <v-card-title>
                              <h4 class="subheading my-3">
                                Enter your valid banking information
                              </h4>
                            </v-card-title>
                            <v-card-text>
                              <v-form
                                ref="newBankForm"
                                @submit.prevent="addBank"
                              >
                                <v-layout row wrap>
                                  <v-flex xs12 sm9>
                                    <v-text-field
                                      name="bank_name"
                                      v-model="bank_name"
                                      label="Bank Name"
                                      type="text"
                                      :rules="bankNameRules"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm3>
                                    <v-autocomplete
                                      v-model="account_type"
                                      :items="accountTypes"
                                      item-text="name"
                                      label="Select Account Type"
                                      :rules="accountTypeRules"
                                      single-line
                                    ></v-autocomplete>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                  <v-flex xs12 sm6>
                                    <v-text-field
                                      name="account_number"
                                      v-model="account_number"
                                      label="Account Number"
                                      type="tel"
                                      v-mask="['##########']"
                                      :rules="accountNumberRules"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6>
                                    <v-text-field
                                      name="bvn"
                                      v-model="bvn"
                                      label="Bank Verification Number"
                                      type="tel"
                                      v-mask="['###########']"
                                      :rules="bvnRules"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn text @click="bankForm = false">
                                <v-icon left>cancel</v-icon>Close
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                :disabled="saving"
                                color="primary"
                                @click="addBank()"
                              >
                                <v-icon left v-if="!saving">save</v-icon>
                                {{ saving ? "Processing..." : "Save" }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap v-else>
                        <v-flex xs12>
                          <v-card flat>
                            <v-card-title>
                              Registered Banks: {{ bankCount | formatNumber }}
                              <v-spacer></v-spacer>
                              <v-btn class="primary" @click="bankForm = true">
                                <v-icon left>account_balance_wallet</v-icon>Add
                                Bank Account
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-if="banks"
                                  class="to-center ma-3 warning--text"
                                >
                                  <h3>
                                    Verify before clicking the delete button,
                                    this step is irreversible.
                                  </h3>
                                </v-flex>
                                <v-flex
                                  xs12
                                  sm6
                                  v-for="(bank, index) in banks"
                                  :key="index"
                                >
                                  <span v-if="bank.is_preferred == 'YES'">
                                    <v-card shaped class="secondary">
                                      <v-card-text>
                                        <v-layout row wrap>
                                          <v-flex xs12 sm2>
                                            <v-icon x-large color="white"
                                              >account_balance_wallet</v-icon
                                            >
                                          </v-flex>
                                          <v-flex xs12 sm10>
                                            <v-layout
                                              row
                                              wrap
                                              class="white--text"
                                            >
                                              <v-flex xs12>
                                                <h2>{{ bank.name }}</h2>
                                              </v-flex>
                                              <v-flex xs12
                                                >Account Type:
                                                {{ bank.account_type }}</v-flex
                                              >
                                              <v-flex xs12
                                                >Account Number:
                                                {{
                                                  bank.account_number
                                                }}</v-flex
                                              >
                                              <v-flex
                                                xs12
                                                v-if="
                                                  bank.account_status ==
                                                  'Active'
                                                "
                                                >Account Status:
                                                {{
                                                  bank.account_status
                                                }}</v-flex
                                              >
                                              <v-flex
                                                xs12
                                                v-else
                                                class="red--text"
                                                >Account Status:
                                                {{
                                                  bank.account_status
                                                }}</v-flex
                                              >
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-btn
                                          :disabled="saving"
                                          small
                                          text
                                          @click="deleteBank(bank.id)"
                                        >
                                          <v-icon left v-if="!saving"
                                            >delete_forever</v-icon
                                          >
                                          {{
                                            saving ? "Processing..." : "Delete"
                                          }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <span
                                          v-if="bank.is_preferred == 'YES'"
                                          class="text-uppercase primary--text"
                                        >
                                          <v-chip pill>
                                            <v-avatar
                                              left
                                              color="secondary"
                                              class="accent--text"
                                              >P</v-avatar
                                            >Preferred Bank
                                          </v-chip>
                                        </span>
                                      </v-card-actions>
                                    </v-card>
                                  </span>
                                  <span v-else>
                                    <v-card shaped>
                                      <v-card-text>
                                        <v-layout row wrap>
                                          <v-flex xs12 sm2>
                                            <v-icon x-large color="primary"
                                              >account_balance_wallet</v-icon
                                            >
                                          </v-flex>
                                          <v-flex xs12 sm10>
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <h2>{{ bank.name }}</h2>
                                              </v-flex>
                                              <v-flex xs12
                                                >Account Type:
                                                {{ bank.account_type }}</v-flex
                                              >
                                              <v-flex xs12
                                                >Account Number:
                                                {{
                                                  bank.account_number
                                                }}</v-flex
                                              >
                                              <v-flex xs12
                                                >Account Status:
                                                {{
                                                  bank.account_status
                                                }}</v-flex
                                              >
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-btn
                                          :disabled="saving"
                                          small
                                          text
                                          @click="deleteBank(bank.id)"
                                        >
                                          <v-icon left v-if="!saving"
                                            >delete_forever</v-icon
                                          >
                                          {{
                                            saving ? "Processing..." : "Delete"
                                          }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <span
                                          v-if="bank.is_preferred == 'YES'"
                                          class="text-uppercase primary--text"
                                        >
                                          <v-chip pill>
                                            <v-avatar
                                              left
                                              color="secondary"
                                              class="accent--text"
                                              >P</v-avatar
                                            >Preferred Bank
                                          </v-chip>
                                        </span>
                                        <span v-else>
                                          <v-btn
                                            :disabled="setting"
                                            small
                                            text
                                            @click="setPreferredBank(bank.id)"
                                          >
                                            <v-icon left v-if="!setting"
                                              >thumb_up</v-icon
                                            >
                                            {{
                                              setting
                                                ? "Processing..."
                                                : "Set As Preffered Bank"
                                            }}
                                          </v-btn>
                                        </span>
                                      </v-card-actions>
                                    </v-card>
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-else-if="is_active == 5">
                  <v-card flat>
                    <v-card-text>
                      <h4 class="subheading my-3">
                        Enter your OLD PIN and your new PREFERRED PIN.
                      </h4>
                      <v-form ref="pinform" @submit.prevent="changePin">
                        <v-layout row wrap>
                          <v-flex xs12 sm4>
                            <v-text-field
                              name="old_pin"
                              v-model="old_pin"
                              label="Old Pin"
                              type="password"
                              v-mask="['######']"
                              :rules="oldPinRules"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm8>
                            <v-layout row wrap>
                              <v-flex xs12 sm6>
                                <v-text-field
                                  class="ml-8"
                                  name="new_pin"
                                  v-model="new_pin"
                                  label="New Pin"
                                  type="password"
                                  v-mask="['######']"
                                  :rules="newPinRules"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-text-field
                                  class="ml-8"
                                  name="confirm_new_pin"
                                  v-model="confirm_new_pin"
                                  label="Confirm New Pin"
                                  type="password"
                                  v-mask="['######']"
                                  :rules="confirmNewPinRules"
                                  @change="comparePins"
                                ></v-text-field>
                              </v-flex>
                              <v-alert
                                class="ml-8"
                                v-if="err"
                                :value="true"
                                type="error"
                                >{{ errMsg }}</v-alert
                              >
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="canChange"
                        :disabled="saving"
                        color="primary"
                        @click="changePin()"
                      >
                        <v-icon left v-if="!saving">save</v-icon>
                        {{ saving ? "Processing..." : "Save Changes" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-else>Invalid Request</v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Profile";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      minYear: "1950-01-01",
      maxYear: "2003-12-31",
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Profile",
        subtitle: "View and manage profile information.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Profile",
            disabled: true,
            href: "profile",
          },
          {
            text:
              this.$root.curuser.surname + " " + this.$root.curuser.firstname,
            disabled: true,
            href: "profile",
          },
        ],
      },
      images: {
        avatar: require("@/assets/avatar.png"),
      },
      btns: [
        {
          id: 1,
          icon: "person_pin",
          title: "Profile Info",
        },
        {
          id: 2,
          icon: "add_a_photo",
          title: "Display Picture",
        },
        {
          id: 3,
          icon: "transform",
          title: "Password",
        },
        {
          id: 4,
          icon: "account_balance_wallet",
          title: "Bank Info",
        },
        {
          id: 5,
          icon: "apps",
          title: "PIN",
        },
      ],
      min_size: 0,
      canEdit: false,
      dialog: false,
      canUpload: false,
      image_name: "",
      image_size: "",
      image_type: "",
      image_url: "",
      upload_progress: 0,
      bankLoading: true,
      banks: [],
      bankCount: 0,
      bankForm: false,
      modal: false,
      checkPinDialog: false,
      canChange: false,
      saving: false,
      setting: false,
      is_active: 1,
      mrgn: "ma-4",
      active_box_title: "Profile Information",
      active_box_icon: "person_pin",
      image: null,
      loading: false,
      error: false,
      errors: {},
      genders: ["Male", "Female", "Trans Gender", "Others"],
      full_name:
        this.$root.curuser.surname + " " + this.$root.curuser.firstname,
      surname: this.$root.curuser.surname,
      firstname: this.$root.curuser.firstname,
      othernames: this.$root.curuser.othernames,
      password: "",
      password_confirmation: "",
      old_pin: "",
      new_pin: "",
      confirm_new_pin: "",
      pin: "",
      loadingProfile: true,
      grades: [
        { name: "Grade 1" },
        { name: "Grade 2" },
        { name: "Grade 3" },
        { name: "Grade 4" },
      ],
      qualifications: [
        { name: "SSCE Degree" },
        { name: "NCE Degree" },
        { name: "OND Degree" },
        { name: "HND Degree" },
        { name: "Associate Degree" },
        { name: "Bachelor's Degree" },
        { name: "Master's Degree" },
        { name: "Doctoral Degree" },
        { name: "Others" },
      ],
      states: [],
      maritalStatus: [
        { name: "Single" },
        { name: "Married" },
        { name: "Complicated" },
        { name: "Others" },
      ],
      countries: [],
      lgas: [],
      profile: [],
      profile_state: "",
      profile_theme: "light",
      profile_id: "",
      profile_grade: "",
      profile_qualification: "",
      profile_tracker_line: "",
      profile_gender: "",
      profile_dob: "2003-12-31",
      profile_marital_status: "",
      profile_address: "",
      profile_country: "",
      profile_soo: "",
      profile_lga: "",
      profile_secondary_email: "",
      response: "",
      err: false,
      errMsg: "",
      accountTypes: [{ name: "Savings" }, { name: "Current" }],
      bank_name: "",
      account_type: "",
      account_number: "",
      bvn: "",
      uploadRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      bankNameRules: [(v) => !!v || "Bank name is required"],
      accountTypeRules: [(v) => !!v || "Account type is required"],
      accountNumberRules: [(v) => !!v || "Account number is required"],
      bvnRules: [(v) => !!v || "Bank verification number is required"],
      oldPinRules: [(v) => !!v || "Old pin is required"],
      newPinRules: [(v) => !!v || "New pin is required"],
      confirmNewPinRules: [(v) => !!v || "Confirm new pin is required"],
      surnameRules: [(v) => !!v || "Family name is required"],
      firstnameRules: [(v) => !!v || "Given name is required"],
      othernamesRules: [(v) => !!v || "Additional name is required"],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
      ],
      confirmpasswordRules: [
        (v) => !!v || "Password confirmation is required",
        (v) => v.length >= 8 || "Minimum of 8 characters required",
        (v) => v === this.password || "Passwors do not match",
      ],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
</style>
