import {
  apiUrl,
  capitalize,
  //    ,isEmpty
} from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;

      this.last_date = item.last_date;
      this.bank = item.bank;
      this.account_number = item.account_number;
      this.forward_address = item.forward_address;
      this.forward_phone = item.forward_phone;
      this.type_of_disengagement = item.type_of_disengagement;
      this.resignation_type = item.resignation_type;
      this.resignation_date = item.resignation_date;
      this.pref_new_position = item.pref_new_position;
      this.reason_change = item.reason_change;
      this.changes_implemented = item.changes_implemented;
      this.positive_aspect_work = item.positive_aspect_work;
      this.positive_aspect_company = item.positive_aspect_company;
      this.negative_aspect_work = item.negative_aspect_work;
      this.negative_aspect_company = item.negative_aspect_company;
      this.placing = item.placing;
      this.job_content = item.job_content;
      this.utilized = item.utilized;
      this.working_condition = item.working_condition;
      this.working_group = item.working_group;
      this.salary = item.salary;
      this.personal_development = item.personal_development;
      this.career_path = item.career_path;
      this.adjustment_company = item.adjustment_company;
      this.advancement_opportunities = item.advancement_opportunities;
      this.performance = item.performance;
      this.personnel_policy = item.personnel_policy;
      this.comm_channel = item.comm_channel;
      this.rel_supervisor = item.rel_supervisor;
      this.rel_colleagues = item.rel_colleagues;
      this.rel_subordinates = item.rel_subordinates;
      this.experience_benefit = item.experience_benefit;
      this.can_reapply = item.can_reapply;
      this.recommend_to_friend = item.recommend_to_friend;
      this.available_reason = item.available_reason;
      this.re_employable = item.re_employable;
      this.all_items_returned = item.all_items_returned;
      this.list_items = item.list_items;
      this.exit_confirmed_by = item.exit_confirmed_by;
      this.exit_confirmed_on = item.exit_confirmed_on;
      this.status = item.status;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.last_date = this.last_date;
        item.bank = this.bank;
        item.account_number = this.account_number;
        item.forward_address = this.forward_address;
        item.forward_phone = this.forward_phone;
        item.type_of_disengagement = this.type_of_disengagement;
        item.resignation_type = this.resignation_type;
        item.resignation_date = this.resignation_date;
        item.pref_new_position = this.pref_new_position;
        item.reason_change = this.reason_change;
        item.changes_implemented = this.changes_implemented;
        item.positive_aspect_work = this.positive_aspect_work;
        item.positive_aspect_company = this.positive_aspect_company;
        item.negative_aspect_work = this.negative_aspect_work;
        item.negative_aspect_company = this.negative_aspect_company;
        item.placing = this.placing;
        item.job_content = this.job_content;
        item.utilized = this.utilized;
        item.working_condition = this.working_condition;
        item.working_group = this.working_group;
        item.salary = this.salary;
        item.personal_development = this.personal_development;
        item.career_path = this.career_path;
        item.adjustment_company = this.adjustment_company;
        item.advancement_opportunities = this.advancement_opportunities;
        item.performance = this.performance;
        item.personnel_policy = this.personnel_policy;
        item.comm_channel = this.comm_channel;
        item.rel_supervisor = this.rel_supervisor;
        item.rel_colleagues = this.rel_colleagues;
        item.rel_subordinates = this.rel_subordinates;
        item.experience_benefit = this.experience_benefit;
        item.can_reapply = this.can_reapply;
        item.recommend_to_friend = this.recommend_to_friend;
        item.available_reason = this.available_reason;
        item.re_employable = this.re_employable;
        item.all_items_returned = this.all_items_returned;
        item.list_items = this.list_items;
        item.exit_confirmed_by = this.exit_confirmed_by;
        item.exit_confirmed_on = this.exit_confirmed_on;

        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              last_date: item.last_date,
              bank: item.bank,
              account_number: item.account_number,
              forward_address: item.forward_address,
              forward_phone: item.forward_phone,
              type_of_disengagement: item.type_of_disengagement,
              resignation_type: item.resignation_type,
              resignation_date: item.resignation_date,
              pref_new_position: item.pref_new_position,
              reason_change: item.reason_change,
              changes_implemented: item.changes_implemented,
              positive_aspect_work: item.positive_aspect_work,
              positive_aspect_company: item.positive_aspect_company,
              negative_aspect_work: item.negative_aspect_work,
              negative_aspect_company: item.negative_aspect_company,
              placing: item.placing,
              job_content: item.job_content,
              utilized: item.utilized,
              working_condition: item.working_condition.name,
              working_group: item.working_group.name,
              salary: item.salary,
              personal_development: item.personal_development,
              career_path: item.career_path,
              adjustment_company: item.adjustment_company,
              advancement_opportunities: item.advancement_opportunities,
              performance: item.performance.name,
              personnel_policy: item.personnel_policy,
              comm_channel: item.comm_channel.name,
              rel_supervisor: item.rel_supervisor.name,
              rel_colleagues: item.rel_colleagues.name,
              rel_subordinates: item.rel_subordinates.name,
              experience_benefit: item.experience_benefit,
              can_reapply: item.can_reapply.name,
              recommend_to_friend: item.recommend_to_friend,
              available_reason: item.available_reason,
              re_employable: item.re_employable.name,
              all_items_returned: item.all_items_returned.name,
              list_items: item.list_items,
              exit_confirmed_by: item.exit_confirmed_by.id,
              exit_confirmed_on: item.exit_confirmed_on,

              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrexits/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted exit form",
            title: "Exit form edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },

    validateForm() {
      if (this.bank == "") {
        return false;
      }
      if (this.last_date == "") {
        return false;
      }
      if (this.account_number == "") {
        return false;
      }
      if (this.type_of_disengagement == "") {
        return false;
      }

      return true;
    },
    clearFields() {
      this.bank = "";
      this.last_date = "";
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,

        last_date: this.last_date,
        bank: this.bank,
        account_number: this.account_number,
        forward_address: this.forward_address,
        forward_phone: this.forward_phone,
        type_of_disengagement: this.type_of_disengagement,
        resignation_type: this.resignation_type,
        resignation_date: this.resignation_date,
        pref_new_position: this.pref_new_position,
        reason_change: this.reason_change,
        changes_implemented: this.changes_implemented,
        positive_aspect_work: this.positive_aspect_work,
        positive_aspect_company: this.positive_aspect_company,
        negative_aspect_work: this.negative_aspect_work,
        negative_aspect_company: this.negative_aspect_company,
        placing: this.placing,
        job_content: this.job_content,
        utilized: this.utilized,
        working_condition: this.working_condition,
        working_group: this.working_group,
        salary: this.salary,
        personal_development: this.personal_development,
        career_path: this.career_path,
        adjustment_company: this.adjustment_company,
        advancement_opportunities: this.advancement_opportunities,
        performance: this.performance,
        personnel_policy: this.personnel_policy,
        comm_channel: this.comm_channel,
        rel_supervisor: this.rel_supervisor,
        rel_colleagues: this.rel_colleagues,
        rel_subordinates: this.rel_subordinates,
        experience_benefit: this.experience_benefit,
        can_reapply: this.can_reapply,
        recommend_to_friend: this.recommend_to_friend,
        available_reason: this.available_reason,
        re_employable: this.re_employable,
        all_items_returned: this.all_items_returned,
        list_items: this.list_items,
        exit_confirmed_by: this.exit_confirmed_by,
        exit_confirmed_on: this.exit_confirmed_on,

        status: this.status,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        last_date: item.last_date,
        bank: item.bank,
        account_number: item.account_number,
        forward_address: item.forward_address,
        forward_phone: item.forward_phone,
        type_of_disengagement: item.type_of_disengagement,
        resignation_type: item.resignation_type,
        resignation_date: item.resignation_date,
        pref_new_position: item.pref_new_position,
        reason_change: item.reason_change,
        changes_implemented: item.changes_implemented,
        positive_aspect_work: item.positive_aspect_work,
        positive_aspect_company: item.positive_aspect_company,
        negative_aspect_work: item.negative_aspect_work,
        negative_aspect_company: item.negative_aspect_company,
        placing: item.placing,
        job_content: item.job_content,
        utilized: item.utilized,
        working_condition: item.working_condition.name,
        working_group: item.working_group.name,
        salary: item.salary,
        personal_development: item.personal_development,
        career_path: item.career_path,
        adjustment_company: item.adjustment_company,
        advancement_opportunities: item.advancement_opportunities,
        performance: item.performance.name,
        personnel_policy: item.personnel_policy,
        comm_channel: item.comm_channel.name,
        rel_supervisor: item.rel_supervisor.name,
        rel_colleagues: item.rel_colleagues.name,
        rel_subordinates: item.rel_subordinates.name,
        experience_benefit: item.experience_benefit,
        can_reapply: item.can_reapply.name,
        recommend_to_friend: item.recommend_to_friend,
        available_reason: item.available_reason,
        re_employable: item.re_employable.name,
        all_items_returned: item.all_items_returned.name,
        list_items: item.list_items,
        exit_confirmed_by: item.exit_confirmed_by.id,
        exit_confirmed_on: item.exit_confirmed_on,

        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrexits`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.bank == item.bank && data.last_date == item.last_date) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.bank == item.bank &&
                storedData.last_date == item.last_date
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " Filled the exit form",
            title: "Exit form filled",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.last_date == item.last_date && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrexits`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        let exit_confirmed_by = this.users_list.filter(
          (element) => q.exit_confirmed_by == element.id
        );
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );
        let working_condition = this.working_condition_list.filter(
          (element) => q.working_condition == element.name
        );
        let working_group = this.working_group_list.filter(
          (element) => q.working_group == element.name
        );
        let performance = this.performance_list.filter(
          (element) => q.performance == element.name
        );
        let comm_channel = this.comm_channel_list.filter(
          (element) => q.comm_channel == element.name
        );
        let rel_supervisor = this.rel_supervisor_list.filter(
          (element) => q.rel_supervisor == element.name
        );
        let rel_colleagues = this.rel_colleagues_list.filter(
          (element) => q.rel_colleagues == element.name
        );
        let rel_subordinates = this.rel_subordinates_list.filter(
          (element) => q.rel_subordinates == element.name
        );
        let can_reapply = this.can_reapply_list.filter(
          (element) => q.can_reapply == element.name
        );
        let re_employable = this.re_employable_list.filter(
          (element) => q.re_employable == element.name
        );
        let all_items_returned = this.all_items_returned_list.filter(
          (element) => q.all_items_returned == element.name
        );

        q.all_items_returned =
          all_items_returned.length > 0 ? all_items_returned[0] : {};
        q.re_employable = re_employable.length > 0 ? re_employable[0] : {};
        q.can_reapply = can_reapply.length > 0 ? can_reapply[0] : {};
        q.rel_subordinates =
          rel_subordinates.length > 0 ? rel_subordinates[0] : {};
        q.rel_colleagues = rel_colleagues.length > 0 ? rel_colleagues[0] : {};
        q.rel_supervisor = rel_supervisor.length > 0 ? rel_supervisor[0] : {};
        q.comm_channel = comm_channel.length > 0 ? comm_channel[0] : {};
        q.performance = performance.length > 0 ? performance[0] : {};
        q.working_condition =
          working_condition.length > 0 ? working_condition[0] : {};
        q.working_group = working_group.length > 0 ? working_group[0] : {};
        q.working_condition =
          working_condition.length > 0 ? working_condition[0] : {};
        q.status = status.length > 0 ? status[0] : {};
        q.user = user.length > 0 ? user[0] : {};
        q.exit_confirmed_by =
          exit_confirmed_by.length > 0 ? exit_confirmed_by[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllData();
    },
  },
};
