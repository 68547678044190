<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              dark
              color="blue-grey darken-2"
              @click="refreshTable()"
              class="mr-2"
            >
              <v-icon>refresh</v-icon> Refresh
            </v-btn>
            <v-btn
              rounded
              outlined
              dark
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td class="clickable" @click="viewDetail(item)">
                      {{ item.name }}
                    </td>
                    <td>{{ item.rel_type.name }}</td>
                    <td width="10">{{ item.proportion }}</td>
                    <td width="10">{{ item.status.name }}</td>
                    <td>
                      <v-btn
                        color="orange darken-2"
                        rounded
                        outlined
                        small
                        dark
                        @click="editItem(item)"
                        class="ma-2"
                      >
                        <v-icon>edit</v-icon> update
                      </v-btn>
                      <v-btn
                        color="blue-grey darken-2"
                        rounded
                        outlined
                        small
                        dark
                        @click="viewDetail(item)"
                        class="ma-1"
                      >
                        <v-icon>visibility</v-icon> view
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Beneficiary"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="proportion"
                      v-model="proportion"
                      type="number"
                      label="Percentage Proportion to be benefitted"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      class="ml-5"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded dark class="px-6" @click="addItem()">
            <v-icon left>save</v-icon> Save Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Beneficiary"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="proportion"
                      v-model="proportion"
                      type="number"
                      label="Percentage Proportion to be benefitted"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      name="rel_type"
                      v-model="rel_type"
                      :items="rel_type_list"
                      item-text="name"
                      class="ml-5"
                      label="Type of Relationship"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            dark
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <v-icon left>save</v-icon> Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          Beneficiary Detail
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.name"
                ></v-list-item-title>
                <v-list-item-subtitle> Name </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">view_module</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.proportion"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Percentage Assigned
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">people</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.rel_type"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      Relationship Type
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.status"
                    ></v-list-item-title>
                    <v-list-item-subtitle> Status </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded text @click="detailsDialog = false"> close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Beneficiaries",
  props: ["Beneficiaries"],
  data() {
    return {
      pageTitle: "My Beneficiaries",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      years_experience: "",
      email_address: "",
      phone_number: "",
      address: "",
      name: "",
      proportion: 0,
      status: {},
      status_list: [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      rel_type: {},
      rel_type_list: [
        { id: 1, name: "Brother" },
        { id: 2, name: "Sister" },
        { id: 3, name: "Mother" },
        { id: 4, name: "Father" },
        { id: 5, name: "Uncle" },
        { id: 6, name: "Aunt" },
        { id: 7, name: "Niece" },
        { id: 8, name: "Nephew" },
        { id: 10, name: "Other" },
      ],
      add_item_dialog_title: "Add Last Employer",
      addItemDialog: this.Beneficiaries.addItemDialog,
      headers: [
        { text: "Name", value: "name" },
        { text: "Relationship", value: "relationship" },
        { text: "Proportion", value: "proportion" },
        { text: "Status", value: "status" },
        { text: "ACTIONS", value: "id" },
      ],
      currentItem: [],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Beneficiaries.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
