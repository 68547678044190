<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <!-- <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="calendarDialog = true">
              Attendance Calendar
            </v-btn>

            <v-spacer></v-spacer>
            <!-- <v-btn fab color="secondary" @click="getLocation(location)">
                <v-icon>add</v-icon>
              </v-btn> -->
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span class="myFocus" @click="viewDetail(item)">
                          {{ item.signed_in_date }}
                        </span>
                      </h2>
                    </td>
                    <td>{{ item.signed_in_location }}</td>
                    <!-- <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <h1>Your current location is calculated at:</h1>

                <div id="app" v-cloak>
                  <div v-if="errorStr">
                    Sorry, but the following error occurred: {{ errorStr }}
                  </div>

                  <div v-if="gettingLocation">
                    <i>Getting your location...</i>
                  </div>
                  <br />
                  <div v-if="location">
                    Latitude: <strong>{{ location.coords.latitude }}</strong>
                    <br />
                    Longitude: <strong>{{ location.coords.longitude }}</strong>
                    <br />
                    Current Address: <strong>{{ display_address }}</strong>
                  </div>
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="primary"
            x-large
            class="px-6"
            @click="addItem(location)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Mark
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="calendarDialog" persistent max-width="300">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Calendar </span>
          <v-spacer></v-spacer>
          <v-btn text @click="calendarDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-date-picker
          v-model="attendance_dates"
          multiple
          color="primary"
          :input-props="{ placeholder: '', readonly: true }"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-card>
    </v-dialog>

    <Loader :LoaderDialog="LoaderData"></Loader>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
import Loader from "@/components/Global/Loader/page";

export default {
  name: "Attendance",
  props: ["Attendance"],
  components: {
    Loader,
  },
  data() {
    return {
      user_in_view: this.Attendance.user,
      calendarDialog: false,
      time_in: "",
      signed_in_date: "",
      signed_in_location: "",
      time_out: "",
      remark: "",
      LoaderData: {
        dialog: false,
        message: "Confirming your current location",
      },
      attendance_dates: [],
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,

      add_item_dialog_title: "Mark attendance",
      addItemDialog: this.Attendance.addItemDialog,
      headers: [
        { text: "Date", value: "date" },
        { text: "Sign in Location", value: "Sign in Location" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Attendance.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      location: null,
      gettingLocation: false,
      errorStr: null,
      display_address: "",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  created() {
    //do we support geolocation
    // if(!("geolocation" in navigator)) {
    //   this.errorStr = 'Geolocation is not available.';
    //   return;
    // }
    // this.gettingLocation = true;
    // // get position
    // navigator.geolocation.getCurrentPosition(pos => {
    //   this.gettingLocation = false;
    //   this.location = pos;
    // }, err => {
    //   this.gettingLocation = false;
    //   this.errorStr = err.message;
    // })
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
