<template>
  <div class="text-center">
    <!-- hide-overlay -->
    <v-dialog v-model="LoaderDialog.dialog" persistent width="350">
      <v-card color="white" light>
        <v-card-text>
          {{ LoaderDialog.message }}
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
export default {
  name: "LoaderDialog",
  props: ["LoaderDialog"],
  data() {
    return {
      dialog: true,
    };
  },

  mixins: [
    //mounted, computed,
    methods,
  ],
};
</script>
