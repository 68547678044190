import { apiUrl, isEmpty } from "@/config";
import Axios from "axios";

export default {
  methods: {
    goBack() {
      this.headerData.breadcrumbLinks[0].text = "Home";
      this.headerData.breadcrumbLinks[0].disabled = true;
      this.headerData.breadcrumbLinks[1].text = "Staff profile";
      this.user_in_view = {};
      this.headerData.title = "Staff profiles";
      this.headerData.subtitle = "Manage the company profile of all staff";
    },
    viewStaff(item) {
      this.user_in_view = item;
      this.headerData.title = item.surname + " " + item.firstname;
      this.headerData.subtitle = "Manage " + item.surname + "'s staff profile";
      this.headerData.breadcrumbLinks[0].text = "All Staff";
      this.headerData.breadcrumbLinks[1].text = item.firstname;
      this.GuarantorData.user = item;
      this.BeneficiariesData.user = item;
      this.NextofkinData.user = item;
      this.AddressesData.user = item;
      this.BiographyData.user = item;
      this.LastEmployerData.user = item;
      this.QualificationData.user = item;
      this.TrainingData.user = item;
      this.DisciplinaryData.user = item;
      this.HMODependantsData.user = item;
      this.DevelopmentPlanData.user = item;
      this.InsuranceBenefitsData.user = item;
      this.HMOData.user = item;
      this.AttendanceData.user = item;
    },
    openTabs(tab) {
      console.log(tab);
    },
    isNull(data) {
      return isEmpty(data);
    },

    removeItemFromAPI(item) {
      Axios.delete(`${apiUrl}/hrhmos/${item.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Truck Deleted!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Delete Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    proceedToRemoveItem(item) {
      this.item_in_view = item;
      this.confirmDeleteDialog = true;
    },
    removeItem(item) {
      this.items.forEach((element, index) => {
        if (element == item) {
          this.removeItemFromAPI(item);
          this.items.splice(index, 1);
        }
      });
      this.confirmDeleteDialog = false;
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrhmos/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        //    item.user = item.user
        item.plan = this.plan;
        item.hospital = this.hospital;
        item.provider = this.provider;
        item.hmo_id = this.hmo_id;
        item.status = this.status;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              user_id: item.user.id,
              plan: item.plan,
              hospital: item.hospital,
              provider: item.provider,
              hmo_id: item.hmo_id,
              status: item.status.name,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    editItem(item) {
      this.item_in_view = item;
      this.user = item.user;
      this.hmo_id = item.hmo_id;
      this.hospital = item.hospital;
      this.provider = item.provider;
      this.plan = item.plan;
      this.status = item.status;
      this.editItemDialog = true;
    },
    validateForm() {
      if (this.provider == "") {
        return false;
      }
      if (this.hmo_id == "") {
        return false;
      }
      if (this.plan == "") {
        return false;
      }
      if (this.hospital == "") {
        return false;
      }

      if (isEmpty(this.status)) {
        return false;
      }

      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.user,
        hospital: this.hospital,
        plan: this.plan,
        hmo_id: this.hmo_id,
        provider: this.provider,
        created_at: "Just Now",
        updated_at: "Just Now",
        status: this.status,
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        plan: item.plan,
        hospital: item.hospital,
        hmo_id: item.hmo_id,
        provider: item.provider,
        status: item.status.name,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrhmos`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.hmo_id == item.hmo_id && data.user_id == item.user_id) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.hmo_id == item.hmo_id &&
                storedData.user.id == item.user_id
              ) {
                alert("found");
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "HMO Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.hmo_id == item.hmo_id && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    publishTable(data) {
      data.forEach((hmo) => {
        let user = this.users_list.filter(
          (element) => hmo.user_id == element.id
        );
        let status = this.status_list.filter(
          (element) => hmo.status == element.name
        );

        hmo.status = status.length > 0 ? status[0] : {};
        hmo.user = user.length > 0 ? user[0] : {};
        if (this.$root.curuser.id == hmo.user.id) this.items.push(hmo);
      });
    },
    detailsDialogPopup(item) {
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.getAllUsers();
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrhmos`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          console.log(data);
          //this.publishTable(data)
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    loadAllUsers() {
      this.users_list = this.$root.users_list;
      this.items = this.users_list;

      this.QualificationData.users_list = this.users_list;
      this.BiographyData.users_list = this.users_list;
      this.GuarantorData.users_list = this.users_list;
      this.BeneficiariesData.users_list = this.users_list;
      this.NextofkinData.users_list = this.users_list;
      this.AddressesData.users_list = this.users_list;
      this.LastEmployerData.users_list = this.users_list;
      this.TrainingData.users_list = this.users_list;
      this.DisciplinaryData.users_list = this.users_list;
      this.HMODependantsData.users_list = this.users_list;
      this.DevelopmentPlanData.users_list = this.users_list;
      this.InsuranceBenefitsData.users_list = this.users_list;
      this.HMOData.users_list = this.users_list;
      this.AttendanceData.users_list = this.users_list;
      this.loading = false;
    },
    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
            this.items.push(user);
          });

          this.QualificationData.users_list = this.users_list;
          this.BiographyData.users_list = this.users_list;
          this.GuarantorData.users_list = this.users_list;
          this.BeneficiariesData.users_list = this.users_list;
          this.NextofkinData.users_list = this.users_list;
          this.AddressesData.users_list = this.users_list;
          this.LastEmployerData.users_list = this.users_list;
          this.TrainingData.users_list = this.users_list;
          this.DisciplinaryData.users_list = this.users_list;
          this.HMODependantsData.users_list = this.users_list;
          this.DevelopmentPlanData.users_list = this.users_list;
          this.InsuranceBenefitsData.users_list = this.users_list;
          this.HMOData.users_list = this.users_list;
          this.AttendanceData.users_list = this.users_list;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },
    init() {
      this.loadAllUsers();
      // this.getAllUsers()
    },
    editStaffItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.staffDialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // replace '/users/${this.editedItem.id}' with your own RESTful route
      // you have access to all the data of the item being edited in editedItem
      Axios.post(
        `${apiUrl}/user/account/delete`,
        {
          user_id: this.editedItem.id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "user deleted",
            text: "Action was successful",
          });
          this.refreshTable();
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
      this.closeDelete();
    },

    close() {
      this.staffDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.userAddForm.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.$refs.userAddForm.validate()) return;
      let data = this.editedItem;
      if (this.editedIndex > -1) {
        data = {
          user_id: this.editedItem.id,
          new_password: this.editedItem.password, // replace 'new_password' with your field name
          confirm_new_password: this.editedItem.password_confirmation, // replace 'confirm_new_password' with your field name
        };
      }
      const registerEnpoint = `/register`; // replace '/register' with your endpoint
      const updateEnpoint = `/user/change-password`; // replace '/change-password' with your endpoint
      Axios.post(
        `${apiUrl}${this.editedIndex > -1 ? updateEnpoint : registerEnpoint}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: `${
              this.editedIndex > -1 ? "password changed" : "user created"
            }`,

            text: "Action was successful",
          });
          // if (this.editedIndex > -1) {
          //   Object.assign(this.items[this.editedIndex], this.editedItem);
          // } else {
          //   this.items.push(this.editedItem);
          // }
          this.refreshTable();

          this.close();
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    suspendUser(item) {
      // replace '/users/${item.id}' with your own RESTful route
      // you have access to all the data of the item being edited in "item"
      const data = {
        user_id: item.id,
      };
      Axios.post(
        `${apiUrl}/user/account/${item.is_suspended ? "un" : ""}suspend`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.$root.auth}`,
          },
        }
      )
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: `user ${item.is_suspended ? "un" : ""}suspended`,
            text: "Action was successful",
          });
          this.refreshTable();
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
  },
};
