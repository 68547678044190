<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <v-btn fab color="blue" class="white--text" @click="refreshTable()">
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn fab color="secondary" @click="proceedToAddItem()">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span
                          v-if="item.department == undefined"
                          class="myFocus"
                          @click="viewDetail(item)"
                        >
                          Checking Department..
                        </span>
                        <span
                          v-else-if="!isNull(item.department)"
                          class="myFocus"
                          @click="viewDetail(item)"
                        >
                          {{ item.department.name }}
                        </span>
                        <span v-else> No department </span>
                      </h2>
                    </td>

                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="department"
                      v-model="department"
                      :items="department_list"
                      item-text="name"
                      label="Department"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="job_title"
                      v-model="job_title"
                      :items="job_title_list"
                      item-text="name"
                      label="Job title"
                      class="ml-5"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="job_grade"
                      v-model="job_grade"
                      :items="job_grade_list"
                      item-text="name"
                      class="ml-5"
                      label="Job grade"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="maiden_name"
                      v-model="maiden_name"
                      label="Maiden name"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="mother_maiden_name"
                      v-model="mother_maiden_name"
                      type="text"
                      label="Mother's maiden name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="dob"
                      v-model="dob"
                      type="text"
                      label="Date of Birth"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="pob"
                      v-model="pob"
                      label="Place of Birth"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="gender"
                      :items="gender_list"
                      item-text="name"
                      class="ml-5"
                      label="Gender"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="user_height"
                      v-model="user_height"
                      label="Height"
                      class="ml-5"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="marital_status"
                      v-model="marital_status"
                      :items="marital_status_list"
                      item-text="name"
                      label="Marital status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="blood_group"
                      v-model="blood_group"
                      :items="blood_group_list"
                      item-text="name"
                      class="ml-5"
                      label="Blood group"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="genotype"
                      v-model="genotype"
                      :items="genotype_list"
                      item-text="name"
                      class="ml-5"
                      label="Genotype"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-text-field
                      name="nationality"
                      v-model="nationality"
                      label="Nationality"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="soo"
                      v-model="soo"
                      label="State of Origin"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="lga"
                      v-model="lga"
                      label="LGA"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3>
                    <v-text-field
                      name="town"
                      v-model="town"
                      label="Town"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="entry_location"
                      v-model="entry_location"
                      label="Entry location"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      name="number_children"
                      v-model="number_children"
                      label="Number of children"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="date_employed"
                      v-model="date_employed"
                      label="Date employed"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      name="date_exited"
                      v-model="date_exited"
                      label="Date exited"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text> </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Biography",
  props: ["Biography"],
  data() {
    return {
      user_in_view: this.Biography.user,
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      job_title: {},
      job_title_list: [],
      job_grade: {},
      job_grade_list: [],
      department: {},
      department_list: this.$root.departments_list,
      dob: "",
      maiden_name: null,
      mother_maiden_name: null,
      pob: null,
      gender: {},
      gender_list: [
        { id: 1, name: "Male" },
        { id: 2, name: "Female" },
      ],
      user_height: null,
      marital_status: {},
      marital_status_list: [
        { id: 1, name: "Married" },
        { id: 2, name: "Single" },
        { id: 3, name: "Engaged" },
        { id: 4, name: "Widowed" },
        { id: 5, name: "In an entanglement" },
      ],
      blood_group: {},
      blood_group_list: [
        { id: 1, name: "AB-negative" },
        { id: 2, name: "B-negative" },
        { id: 3, name: "AB-positive" },
        { id: 4, name: "A-negative" },
        { id: 5, name: "O-negative" },
        { id: 6, name: "B-positive" },
        { id: 7, name: "A-positive" },
        { id: 8, name: "O-positive" },
        { id: 9, name: "O-" },
        { id: 10, name: "O+" },
      ],
      genotype: {},
      genotype_list: [
        { id: 1, name: "AA" },
        { id: 2, name: "AS" },
        { id: 3, name: "SS" },
      ],
      nationality: null,
      soo: null,
      lga: null,
      town: null,
      entry_location: null,
      number_children: 3,
      department_id: 1,
      date_employed: "",
      date_exited: "",
      hrjobtitle_id: "",
      hrjobgrade_id: "",
      status: { id: 1, name: "Pending" },
      status_list: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Suspended" },
      ],
      add_item_dialog_title: "Add Biography",
      addItemDialog: this.Biography.addItemDialog,
      headers: [
        { text: "Department", value: "department" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Biography.users_list,
      loading: false,
      item_in_view: {},
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
