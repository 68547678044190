<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light text-capitalize">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              dark
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Record
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-row v-if="loading">
              <v-col cols="12">
                <v-progress-linear
                  indeterminate
                  color="primary darken-3"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row
              v-else
              class="profile-card"
              v-for="(item, i) in items"
              :key="i"
            >
              <v-col cols="12" sm="9">
                <v-card>
                  <v-card-title>
                    <span class="font-weight-light blue-grey--text"
                      >My Data</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.gender }} <br />
                          <small>Gender</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.maiden_name }} <br />
                          <small>Maiden Name</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.mother_maiden_name }} <br />
                          <small>Mother Maiden Name</small>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.dob }} <br />
                          <small>Date Of Birth</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.pob }} <br />
                          <small>Place Of Birth</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.user_height }} <br />
                          <small>Height</small>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.marital_status }} <br />
                          <small>Marital Status</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.number_children }} <br />
                          <small>Number Of Children</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.blood_group }} <br />
                          <small>Blood Group</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.genotype }} <br />
                          <small>Genotype</small>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.town }} <br />
                          <small>Home Town</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.lga }} <br />
                          <small>Local Government Area</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.soo }} <br />
                          <small>State Of Origin</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <p>
                          {{ item.nationality }} <br />
                          <small>Nationality</small>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.entry_location }} <br />
                          <small>Entry Location</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          <span
                            v-for="(department, i) in department_list"
                            :key="i"
                          >
                            <span v-if="department.id == item.department_id">
                              {{ department.name }}
                            </span>
                          </span>
                          <br />
                          <small>Department</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.date_employed }} <br />
                          <small>Date Employed</small>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <p>
                          <span v-for="(jt, i) in job_title_list" :key="i">
                            <span v-if="jt.id == item.hrjobtitle_id">
                              {{ jt.title }}
                            </span>
                          </span>
                          <br />
                          <small>Job Title</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          <span v-for="(jg, i) in job_grade_list" :key="i">
                            <span v-if="jg.id == item.hrjobgrade_id">
                              {{ jg.title }}
                            </span>
                          </span>
                          <br />
                          <small>Job Grade</small>
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <p>
                          {{ item.date_exited }} <br />
                          <small>Date Exited</small>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text rounded light @click="refreshTable()">
                      <v-icon left>refresh</v-icon> Refresh
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="items.length == 0"
                      rounded
                      outlined
                      color="primary"
                      dark
                      @click="addItemDialog = true"
                    >
                      <v-icon left>add</v-icon> New Record
                    </v-btn>
                    <v-btn
                      v-else
                      rounded
                      outlined
                      color="primary"
                      dark
                      @click="editItem(item)"
                    >
                      <v-icon left>edit</v-icon> Edit Record
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="ml-1">
                  <v-card flat>
                    <v-card-title>
                      <span class="font-weight-light blue-grey--text">
                        My HOD Data
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-card shaped class="infobox">
                            <img
                              :src="item.department.hod.avatar_id"
                              width="240"
                            />
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <p>
                            {{ item.department.hod.surname }}
                            {{ item.department.hod.firstname }}
                            {{ item.department.hod.othernames }} <br />
                            <small>Full Name</small>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <p>
                            {{ item.department.hod.email }} <br />
                            <small>Email Address</small>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <p>
                            {{ item.department.hod.phone_number }} <br />
                            <small>Phone Number</small>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="department"
                      v-model="department"
                      :items="department_list"
                      item-text="name"
                      label="Department"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="job_title"
                      v-model="job_title"
                      label="Job title"
                      class="ml-5"
                      :rules="requiredRules"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="job_grade"
                      v-model="job_grade"
                      class="ml-5"
                      label="Job grade"
                      :rules="requiredRules"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="maiden_name"
                      v-model="maiden_name"
                      label="Maiden name"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="mother_maiden_name"
                      v-model="mother_maiden_name"
                      type="text"
                      label="Mother's maiden name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-dialog
                      ref="sdialog"
                      v-model="dob_dialog"
                      :return-value.sync="dob"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dob"
                          label="Date of birth"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="dob"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dob_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(dob)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                    <!--<v-text-field
                      name="dob"
                      v-model="dob"
                      type="text"
                      label="Date of Birth"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>-->
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="pob"
                      v-model="pob"
                      label="Place of Birth"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="status"
                      v-model="gender"
                      :items="gender_list"
                      item-text="name"
                      class="ml-5"
                      label="Gender"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="user_height"
                      v-model="user_height"
                      label="Height"
                      class="ml-5"
                      type="number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="marital_status"
                      v-model="marital_status"
                      :items="marital_status_list"
                      item-text="name"
                      label="Marital status"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="blood_group"
                      v-model="blood_group"
                      :items="blood_group_list"
                      item-text="name"
                      class="ml-5"
                      label="Blood group"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="genotype"
                      v-model="genotype"
                      :items="genotype_list"
                      item-text="name"
                      class="ml-5"
                      label="Genotype"
                      :rules="objRequiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      name="nationality"
                      v-model="nationality"
                      label="Nationality"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      name="soo"
                      v-model="soo"
                      label="State of Origin"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      name="lga"
                      v-model="lga"
                      label="LGA"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      name="town"
                      v-model="town"
                      label="Town"
                      type="text"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="entry_location"
                      v-model="entry_location"
                      label="Entry location"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="number_children"
                      v-model="number_children"
                      label="Number of children"
                      type="number"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date_employed"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date_employed"
                          label="Date Employed"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                          class="ml-5"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        v-model="date_employed"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(date_employed)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                    <!-- <v-text-field
                      name="date_employed"
                      v-model="date_employed"
                      label="Date employed"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field> -->
                  </v-col>

                  <!-- <v-col cols="12" sm="6">
                    <v-text-field
                      name="date_exited"
                      v-model="date_exited"
                      label="Date exited"
                      type="text"
                      class="ml-5"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="addItemDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click="addItem()">
            <v-icon left>save</v-icon> Save Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Edit Item</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              Kindly contact Human Resources Department for this action.
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="editItemDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn rounded color="primary">
            <v-icon left>save</v-icon> Save Changes
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "Biography",
  props: ["Biography"],
  data() {
    return {
      minYear: "1950-01-01",
      modal: false,
      detailsDialog: false,
      pageTitle: "My Biography",
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      job_title: "",
      job_title_list: [],
      job_grade: "",
      job_grade_list: [],
      department: {},
      department_list: this.$root.departments_list,
      dob: "",
      maiden_name: null,
      mother_maiden_name: null,
      pob: null,
      gender: {},
      gender_list: [
        { id: 1, name: "Male" },
        { id: 2, name: "Female" },
      ],
      user_height: null,
      marital_status: {},
      marital_status_list: [
        { id: 1, name: "Married" },
        { id: 2, name: "Single" },
      ],
      blood_group: {},
      blood_group_list: [
        { id: 1, name: "AB-negative" },
        { id: 2, name: "B-negative" },
        { id: 3, name: "AB-positive" },
        { id: 4, name: "A-negative" },
        { id: 5, name: "O-negative" },
        { id: 6, name: "B-positive" },
        { id: 7, name: "A-positive" },
        { id: 8, name: "O-positive" },
      ],
      genotype: {},
      genotype_list: [
        { id: 1, name: "AA" },
        { id: 2, name: "AS" },
        { id: 2, name: "AC" },
        { id: 2, name: "SS" },
        { id: 2, name: "SC" },
        { id: 2, name: "CC" },
      ],
      nationality: null,
      soo: null,
      lga: null,
      town: null,
      entry_location: null,
      number_children: 3,
      department_id: 1,
      date_employed: "",
      date_exited: "empty",
      hrjobtitle_id: "",
      hrjobgrade_id: "",
      status: { id: 1, name: "Pending" },
      status_list: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Suspended" },
      ],
      add_item_dialog_title: "Add Biography",
      addItemDialog: this.Biography.addItemDialog,
      headers: [
        { text: "Department", value: "department" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.Biography.users_list,
      loading: false,
      item_in_view: {},
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
      objRequiredRules: [(v) => !!v.name || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
<style scoped>
.profile-card p {
  font-size: 16px;
}
.profile-card p small {
  font-size: 12px;
}
</style>
