import Vue from "vue";
import Vuetify from "vuetify/lib";
// import './plugins/vuetify'
import App from "./App.vue";
import router from "./router";

import Authentication from "./layouts/Authentication.vue";
import Default from "./layouts/Default.vue";

import Notifications from "vue-notification";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import TimeAgo from "vue2-timeago";
import VueSlimScroll from "vue-slimscroll";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueTheMask from "vue-the-mask";
import JsonExcel from "vue-json-excel";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import FullCalendar from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.min.css";

// import Vuetify from 'vuetify'

const opts = {
  theme: {
    light: true,
    dark: false,
    themes: {
      light: {
        primary: "#af0000",
        secondary: "#607d8b",
        accent: "#f5f5f5",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#009688",
      },
      dark: {
        primary: "#EC407A",
        secondary: "#00E5FF",
        accent: "#616161",
        error: "#af0000",
        warning: "#FFB300",
        info: "#40C4FF",
        success: "#00BFA5",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
};
Vue.use(Vuetify);

Vue.component("Authentication-layout", Authentication);
Vue.component("default-layout", Default);

Vue.component("TimeAgo", TimeAgo);

var converter = require("number-to-words");
Vue.filter("toWords", function (value) {
  if (!value) return "";
  return converter.toWords(value);
});

var numeral = require("numeral");

Vue.component("downloadExcel", JsonExcel);

Vue.use(VueTheMask);

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.use(VueSlimScroll);

Vue.use(CKEditor);

Vue.use(Notifications);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mm");
  }
});

Vue.filter("getAge", function (value) {
  if (value) {
    return moment(String(value)).fromNow();
  }
});

Vue.use(FullCalendar);

momentTimeZone.tz.setDefault("Africa/Lagos");

Vue.config.productionTip = false;

const authenticatedData = localStorage.getItem("authenticated");
const authData = localStorage.getItem("auth");
const authDataUser = localStorage.getItem("curuser");
const authDataProfile = localStorage.getItem("curprofile");
const authRoles = localStorage.getItem("curuserroles");
const authPermissions = localStorage.getItem("curuserpermissions");
const authLocation = localStorage.getItem("curlocation");
const authAvatar = localStorage.getItem("curuseravatar");
const authAvatarBig = localStorage.getItem("curuseravatarbig");
const users_list = localStorage.getItem("users_list");
const user_position = localStorage.getItem("user_position");
const departments_list = localStorage.getItem("departments_list");
const staff_department = localStorage.getItem("staff_department");

new Vue({
  vuetify: new Vuetify(opts),
  router,
  data: {
    authenticated: authenticatedData ? true : false,
    auth: authData ? JSON.parse(authData) : {},
    curuser: authDataUser ? JSON.parse(authDataUser) : {},
    staff_department: staff_department ? JSON.parse(staff_department) : {},
    curprofile: authDataProfile ? JSON.parse(authDataProfile) : {},
    curlocation: authLocation ? JSON.parse(authLocation) : {},
    curuseravatar: authAvatar ? JSON.parse(authAvatar) : {},
    curuseravatarbig: authAvatarBig ? JSON.parse(authAvatarBig) : {},
    curuserroles: authRoles ? JSON.parse(authRoles) : {},
    curuserpermissions: authPermissions ? JSON.parse(authPermissions) : {},
    users_list: users_list ? JSON.parse(users_list) : [],
    departments_list: departments_list ? JSON.parse(departments_list) : [],
    user_position: user_position ? user_position : "",
    notifications_list: [],
    vehicle_states: [
      { id: 1, name: "Available" },
      { id: 2, name: "Damaged" },
      { id: 3, name: "Undergoing Repairs" },
      { id: 4, name: "Discarded" },
      { id: 5, name: "Sold" },
      { id: 6, name: "Awaiting Dispatch" },
      { id: 7, name: "In Transit" },
    ],
    client_statuses: [
      { id: 1, name: "active" },
      { id: 2, name: "inactive" },
    ],
    leave_statuses: [
      { id: 1, name: "Awaiting Approval" },
      { id: 2, name: "Approved" },
      { id: 3, name: "Denied" },
      { id: 4, name: "Cancelled" },
      { id: 5, name: "Initiated" },
      { id: 6, name: "Initated" },
      { id: 7, name: "Active" },
    ],
    leave_types: [
      { id: 1, name: "Annual" },
      { id: 2, name: "Quarterly" },
      { id: 3, name: "Monthly" },
      { id: 4, name: "Weekly" },
      { id: 4, name: "Daily" },
    ],
    months: [
      { id: 1, name: "January", value: 1 },
      { id: 2, name: "February", value: 2 },
      { id: 3, name: "March", value: 3 },
      { id: 4, name: "April", value: 4 },
      { id: 5, name: "May", value: 5 },
      { id: 6, name: "June", value: 6 },
      { id: 7, name: "July", value: 7 },
      { id: 8, name: "August", value: 8 },
      { id: 9, name: "September", value: 9 },
      { id: 10, name: "October", value: 10 },
      { id: 11, name: "November", value: 11 },
      { id: 12, name: "December", value: 12 },
    ],
    years: [
      { id: 1, name: "2019" },
      { id: 2, name: "2020" },
      { id: 3, name: "2021" },
      { id: 4, name: "2022" },
      { id: 5, name: "2023" },
    ],
  },
  render: (h) => h(App),
}).$mount("#app");
