import Axios from "axios";
import { apiUrl } from "@/config";

export default {
  methods: {
    // Reload Just Once
    reloadOnce() {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    },
    changeTheme(theme) {
      this.profile_theme = theme;
      console.log(this.profile_theme);
      if (this.profile_theme != "light") {
        this.$vuetify.theme.dark = true;
        this.$vuetify.theme.light = false;
      } else {
        this.$vuetify.theme.dark = false;
        this.$vuetify.theme.light = true;
      }
    },
    // Send Message
    sendMessage() {
      if (this.message != "") {
        this.fullname = this.senderSurname + " " + this.senderFirstname;
        this.loading = true;
        Axios.post(
          `${apiUrl}/contactsupports`,
          {
            name: this.fullname,
            email: this.senderEmail,
            message: this.message,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.loading = false;
            this.ss = true;
            this.ssMsg = response.data.message;
            setTimeout(() => (this.ss = false), 2000);
            this.message = "";
          })
          .catch(({ response }) => {
            this.loading = false;
            console.log(response);
            this.$notify({
              group: "platform",
              duration: 3000,
              type: "error",
              title: "Request failed",
              text:
                "We are unable to send your message at the moment, ensure you have entered all the required fields and try again.",
            });
          });
      } else {
        this.err = true;
        this.errMsg = "You have to enter a message to proceed.";
        setTimeout(() => (this.err = false), 4000);
      }
    },

    getNotifications() {
      Axios.get(`${apiUrl}/hrnotifications/get/${this.$root.curuser.id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.prepNotifications(data);
        })
        .catch((response) => {
          console.log(response);
        });
    },

    prepNotifications(data) {
      // let number_of_unread = 0;
      // this.notification_count = data.length
      this.notifications_list = [];
      this.notifications_list.push({
        header: "Notifications",
        status: "header",
      });
      data.forEach((el) => {
        let n = 0;
        let sender = this.users_list.filter(
          (element) => el.sender_id == element.id
        );
        this.notifications_list.push({
          id: el.id,
          status: el.status,
          sender_id: el.sender_id,
          avatar:
            sender.length > 0
              ? sender[0].avatar_id
              : "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          sentBy:
            sender.length > 0
              ? sender[0].surname + " " + sender[0].firstname
              : "No User Found",
          title: el.title,
          message: el.message,
          color: n % 2 == 0 ? "primary" : "amber",
          time: el.created_at,
        });
        n++;
        this.notifications_list.push({
          divider: true,
          inset: true,
          status: "divider",
        });
        // if (el.status == "Unread") number_of_unread++;
      });
      // this.$root.notifications_list = this.notifications_list;
      // this.notification_count = number_of_unread;
    },

    init() {
      this.getNotifications();
    },
  },
};
