<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="expenseDialog = true">
                <v-icon left>person</v-icon>Add a User
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <span v-if="item.concluded != 'YES'">
                          <v-btn
                            class="secondary--text"
                            @click="preConclude(item.id)"
                          >
                            <v-icon left>thumb_up</v-icon>Conclude
                          </v-btn>
                        </span>
                        <span v-else>
                          <v-chip color="success" dark>{{
                            expense_concluded
                          }}</v-chip>
                        </span>
                      </td>
                      <td>{{ item.title }}</td>
                      <td>
                        <span v-for="(department, i) in departments" :key="i">
                          <span v-if="department.id === item.department">{{
                            department.name
                          }}</span>
                        </span>
                      </td>
                      <td>
                        <span v-for="(expenseType, i) in expenseTypes" :key="i">
                          <span
                            v-if="expenseType.id === item.transaction_type"
                            >{{ expenseType.name }}</span
                          >
                        </span>
                      </td>
                      <td>{{ item.transaction_date }}</td>
                      <td>{{ item.transaction_number }}</td>
                      <td class="bold">
                        <h2 v-if="item.approved_amount > 0">
                          <small v-html="naira"></small>
                          <span
                            v-if="item.total_amount > item.approved_amount"
                            class="strike-through"
                            >{{ item.total_amount | formatNumber }}</span
                          ><span v-else>{{
                            item.total_amount | formatNumber
                          }}</span>
                        </h2>
                        <h2 v-else>
                          <small v-html="naira"></small
                          >{{ item.total_amount | formatNumber }}
                        </h2>
                      </td>
                      <td class="bold success--text">
                        <h2>
                          <small v-html="naira"></small
                          >{{ item.approved_amount | formatNumber }}
                        </h2>
                      </td>
                      <td>
                        <span v-if="item.receipt_id === null">
                          <v-btn @click="loadUploader(item.id)">
                            <v-icon left>cloud_upload</v-icon>Upload
                          </v-btn>
                        </span>
                        <span v-else>
                          <span v-for="(avatar, i) in avatars" :key="i">
                            <span v-if="avatar.expense_id == item.id">
                              <v-avatar color="orange" size="60">
                                <v-img
                                  :src="avatar.avatar"
                                  class="is-clickable"
                                  @click="
                                    lightBox(item.title, avatar.cardImage)
                                  "
                                ></v-img>
                              </v-avatar>
                            </span>
                          </span>
                        </span>
                      </td>
                      <td>
                        <span v-for="(user, i) in users" :key="i">
                          <span v-if="user.id === item.hod"
                            >{{ user.surname }} {{ user.firstname }}</span
                          >
                        </span>
                      </td>
                      <td>
                        <span v-if="item.hod_approval != 'YES'">
                          <v-chip color="error" dark>{{ not_approved }}</v-chip>
                        </span>
                        <span v-else>
                          <v-chip color="success" dark>{{
                            is_approved
                          }}</v-chip>
                        </span>
                      </td>
                      <td>{{ item.hod_comment }}</td>
                      <td>
                        <span v-if="item.ermc_approval != 'YES'">
                          <v-chip color="error" dark>{{ not_approved }}</v-chip>
                        </span>
                        <span v-else>
                          <v-chip color="success" dark>{{
                            is_approved
                          }}</v-chip>
                        </span>
                      </td>
                      <td>{{ item.ermc_comment }}</td>
                      <td>
                        <span v-if="item.approved != 'YES'">
                          <v-chip color="error" dark>{{ not_approved }}</v-chip>
                        </span>
                        <span v-else>
                          <v-chip color="success" dark>{{
                            is_approved
                          }}</v-chip>
                        </span>
                      </td>
                      <td>{{ item.approval_comment }}</td>
                      <td>
                        <span v-if="item.payed != 'YES'">
                          <v-chip color="error" dark>{{ not_payed }}</v-chip>
                        </span>
                        <span v-else>
                          <v-chip color="success" dark>{{ is_payed }}</v-chip>
                        </span>
                      </td>
                      <td>{{ item.payment_comment }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="expenseDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Add a User account</span>
          <v-spacer></v-spacer>
          <v-btn text @click="expenseDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <h3 class="black--text">
                    Fill all fields with accurate information
                    <span class="error--text text-uppercase ml-3"
                      >All Fields are required</span
                    >
                  </h3>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs4 sm12>
                  <v-text-field
                    name="first_name"
                    v-model="first_name"
                    label="First Name"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4 sm12>
                  <v-text-field
                    name="last_name"
                    v-model="last_name"
                    label="Last Name"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4 sm12>
                  <v-text-field
                    name="other_names"
                    v-model="other_names"
                    label="Other Names"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs4 sm12>
                  <v-text-field
                    name="email"
                    v-model="email"
                    label="Email Address"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4 sm12>
                  <v-text-field
                    name="phone"
                    v-model="phone"
                    label="Phone Number"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4 sm12>
                  <v-text-field
                    name="user_access"
                    v-model="user_access"
                    label="User Access"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" @click="addUser()" x-large class="px-6">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            <span class="ml-5">
              <!-- {{ proc_msg }} -->
              Add
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text"
          >File Uploader</v-card-title
        >
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <h3>
                Ensure the image file is
                <span class="bold">JPG</span> or
                <span class="bold">PNG</span> and the size should not be more
                than
                <span class="bold">{{ min_size }} MegaByte</span>
              </h3>
              <v-layout row wrap>
                <v-flex xs12 sm2></v-flex>
                <v-flex xs12 sm8>
                  <input
                    type="file"
                    ref="fileInput"
                    accept="image/jpeg"
                    class="no-display"
                    @change="onFilePicked"
                  />
                  <v-card class="ma-4" shaped max-width="400">
                    <v-img
                      v-if="image_url"
                      class="white--text align-end"
                      height="400px"
                      :src="image_url"
                    ></v-img>
                    <v-img
                      v-else
                      :src="images.avatar"
                      @click="pickFile"
                      class="is-clickable"
                    ></v-img>
                  </v-card>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <h3>
                        Name:
                        <span class="bold">{{ image_name }}</span> &nbsp;
                        Format:
                        <span class="bold">{{ image_type }}</span> &nbsp; Size:
                        <span class="bold">{{ image_size }}</span>
                      </h3>
                    </v-flex>
                  </v-layout>
                  <v-alert v-if="err" :value="true" type="error">{{
                    errMsg
                  }}</v-alert>
                  <span class="ma-5">
                    <v-btn x-large outlined color="primary" @click="pickFile"
                      >Choose Image File To Upload</v-btn
                    >
                  </span>
                  <v-layout row wrap v-if="saving" class="mt-5">
                    <v-flex xs12>
                      <v-progress-linear
                        color="primary lighten-2"
                        buffer-value="0"
                        stream
                      ></v-progress-linear>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm2></v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4" v-if="canUpload">
          <v-btn text @click="uploadDialog = false">
            <v-icon left>close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="canUpload"
            :disabled="saving"
            color="primary"
            @click="uploadProfileImage()"
          >
            {{ saving ? "Updating profile..." : "Upload &amp; Save" }}
            <v-icon right v-if="!saving">cloud_upload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lightboxDialog" persistent max-width="800">
      <v-card class="mx-auto">
        <v-img class="white--text align-end" :src="lightbox_image">
          <v-card-title v-text="lightbox_title"></v-card-title>
        </v-img>
        <v-card-actions>
          <v-btn text @click="lightboxDialog = false">
            <v-icon left>close</v-icon> Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="concludeDialog" persistent max-width="500">
      <v-card class="mx-auto">
        <v-card-title class="pt-2 pb-2 primary--text"
          >Conclude Expense Confirmation</v-card-title
        >
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <h3 class="mt-5 text-capitalize">
            Ensure you have been payed the approved amount before proceeding
            with this step.
            <span class="error--text">THIS STEP IS NOT REVERSIBLE</span>. Click
            the <span class="bold">yes conclude</span> button to proceed.
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="concludeDialog = false">
            <v-icon left>close</v-icon> Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" :disabled="saving" @click="concludeExpense()">
            <v-icon left v-if="!saving">save</v-icon>
            {{ saving ? "Processing..." : "YES Conclude" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Users";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      first_name: "",
      last_name: "",
      other_names: "",
      phone: "",
      email: "",
      password: "",
      user_access: "",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Users",
        subtitle: "View and manage Users",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard",
          },
          {
            text: "Users",
            disabled: true,
            href: "users",
          },
        ],
      },
      is_payed: "Expense Payed",
      not_payed: "Expense Not Payed",
      is_approved: "Approved",
      not_approved: "Not Approved",
      concludeDialog: false,
      conclude_id: "",
      expense_concluded: "Transaction Concluded",
      lightboxDialog: false,
      lightbox_title: "",
      lightbox_image: "",
      items: [],
      itemCount: 0,
      search: null,
      headers: [
        { text: "ACTION", value: "id" },
        { text: "Title", value: "title" },
        { text: "Departmant", value: "department" },
        { text: "Type", value: "transaction_type" },
        { text: "Date", value: "transaction_date" },
        { text: "Expense Number", value: "transaction_number" },
        { text: "Amount", value: "total_amount" },
        { text: "Amount Approved", value: "approved_amount" },
        { text: "Evidence", value: "receipt_id" },
        { text: "HOD", value: "hod" },
        { text: "HOD Approval", value: "hod_approval" },
        { text: "HOD Comment", value: "hod_comment" },
        { text: "ERM&C Approval", value: "ermce_approval" },
        { text: "ERM&C Comment", value: "ermce_comment" },
        { text: "Approved", value: "approved" },
        { text: "Approval Comment", value: "approval_comment" },
        { text: "Payed", value: "payed" },
        { text: "Payment Comment", value: "payment_comment" },
      ],
      avatars: [],
      uploadDialog: false,
      expense_id: "",
      naira: "&#8358;",
      expenseTypes: [],
      departments: [],
      hods: [],
      users: [],
      expense: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      disabled: 1,
      disablePins: false,
      canEdit: false,
      loading: true,
      saving: false,
      min_size: "2MB",
      checking: false,
      canUpload: false,
      images: "",
      image_name: "",
      image_size: "",
      image_type: "",
      image_url: "",
      upload_progress: 0,
      expenses: [],
      expenseCount: 0,
      expenseDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      hodName: "",
      title: "",
      department: "",
      transaction_type: "",
      transaction_date: "",
      total_amount: 0,
      amount_payed: "",
      hod: "",
      concluded: "",
      concluded_by: "",
      concluded_on: "",
      pin: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      pin5: "",
      pin6: "",
      transactionTypes: [],
      response: "",
      err: false,
      errMsg: "",
      ss: false,
      ssMsg: "",
      titleRules: [(v) => !!v || "Expense title is required"],
      departmentRules: [(v) => !!v || "Official department/unit is required"],
      transactionTypeRules: [(v) => !!v || "Expense type is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
      totalAmountRules: [(v) => !!v || "Amount spent is required"],
      hodRules: [(v) => !!v || "Head of department/unit is required"],
      pin1Rules: [(v) => !!v || "Invalid"],
      pin2Rules: [(v) => !!v || "Invalid"],
      pin3Rules: [(v) => !!v || "Invalid"],
      pin4Rules: [(v) => !!v || "Invalid"],
      pin5Rules: [(v) => !!v || "Invalid"],
      pin6Rules: [(v) => !!v || "Invalid"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
</style>
