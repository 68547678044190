import { apiUrl } from "@/config";
import Axios from "axios";

export default {
  methods: {
    // Get Roles
    getRoles() {
      Axios.get(`${apiUrl}/roles/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.items = response.data.data;
          this.itemCount = this.items.length;
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response.data);
        });
    },
    // Get Permissions
    getPermissions() {
      Axios.get(`${apiUrl}/permissions/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.permissions = response.data.data;
          this.permissionCount = this.permissions.length;
          this.loadingPermissions = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response.data);
        });
    },
    // Save Roles
    saveRole() {
      if (this.$refs.roleform.validate()) {
        this.savingRole = true;
        this.disabled = 1;
        Axios.post(
          `${apiUrl}/create/role`,
          {
            name: this.role_name,
            guard_name: this.guard,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.savingRole = false;
            this.disabled = 0;
            this.getRoles();
            this.$refs.roleform.reset();
            this.ss = true;
            this.ssMsg = response.data.message;
            setTimeout(() => (this.ss = false), 3000);
          })
          .catch(({ response }) => {
            console.log(response.data);
            this.savingRole = false;
            this.disabled = 0;
            this.err = true;
            this.errMsg = response.data;
            setTimeout(() => (this.err = false), 5000);
          });
      }
    },
    // Save Roles
    savePermission() {
      if (this.$refs.permissionform.validate()) {
        this.savingPermission = true;
        this.disabled2 = 1;
        Axios.post(
          `${apiUrl}/create/permission`,
          {
            name: this.permission_name,
            guard_name: this.guard,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.auth}`,
            },
          }
        )
          .then((response) => {
            this.savingPermission = false;
            this.disabled2 = 0;
            this.getPermissions();
            this.$refs.permissionform.reset();
            this.ss2 = true;
            this.ss2Msg = response.data.message;
            setTimeout(() => (this.ss = false), 3000);
          })
          .catch(({ response }) => {
            console.log(response.data);
            this.savingPermission = false;
            this.disabled2 = 0;
            this.err2 = true;
            this.err2Msg = response.data;
            setTimeout(() => (this.err = false), 5000);
          });
      }
    },
  },
};
