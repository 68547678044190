<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title>
            <!-- <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer> -->
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <!-- <v-spacer></v-spacer>
              <v-btn fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
              </v-btn> -->
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>
                      <h2>
                        <span class="myFocus" @click="viewDetail(item)">
                          {{ item.plan }}
                        </span>
                      </h2>
                    </td>
                    <!-- <td>{{ item.type }}</td>
                      <td>{{ item.provider }}</td>
                      <td>{{ item.rules }}</td> -->

                    <td>
                      <v-chip
                        class="ma-2"
                        :color="item.status.name == 'Rejected' ? 'red' : 'blue'"
                        text-color="white"
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="blue"
                        fab
                        small
                        dark
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="users_list"
                        item-text="name"
                        label="Choose a staff"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout> -->

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="provider"
                      v-model="provider"
                      label="provider"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="plan"
                      label="Plan"
                      prepend-icon="event"
                      :rules="requiredRules"
                      class="ml-5"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="type"
                      v-model="type"
                      type="text"
                      label="Type"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="rules"
                      v-model="rules"
                      type="text"
                      label="Rules"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="users_list"
                        item-text="name"
                        label="Choose a staff"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout> -->

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="provider"
                      v-model="provider"
                      label="provider"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="plan"
                      label="Plan"
                      prepend-icon="event"
                      :rules="requiredRules"
                      class="ml-5"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="type"
                      v-model="type"
                      type="text"
                      label="Type"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="rules"
                      v-model="rules"
                      type="text"
                      label="Rules"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span> Detail </span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card class="mx-auto">
          <v-list two-line subheader>
            <v-list-item v-for="item in details" :key="item.key">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.value"></v-list-item-title>
                <v-list-item-subtitle v-text="item.key"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "InsuranceBenefits",
  props: ["InsuranceBenefits"],
  data() {
    return {
      user_in_view: this.InsuranceBenefits.user,
      detailsDialog: false,
      details: [],
      hr_ids: [],
      editItemDialog: false,
      addItemDialog: this.InsuranceBenefits.addItemDialog,
      items: this.InsuranceBenefits.user.hrinsurancebenefits,
      itemCount: 0,
      search: null,
      users_list: this.InsuranceBenefits.users_list,
      loading: false,
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],

      plan_dialog: false,
      plan: "",
      provider: "",
      type: "",
      rules: "",
      approval_comment: "",
      approved_by: {},
      added_by: this.$root.curuser,

      user: this.$root.curuser,

      sortItemDialog: false,
      delete_item_dialog_title: "Delete a Insurance Benefit",
      confirmDeleteDialog: false,
      item_in_view: {},
      add_item_dialog_title: "Add Insurance Benefit",
      edit_item_dialog_title: "Edit Insurance Benefit",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      // maxYear: new Date().toISOString().substr(0, 10),
      maxYear: this.addMonth(8),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Staff Insurance Benefit",
        subtitle: "View and manage Insurance Benefit",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/hr",
          },
          {
            text: "Leave",
            disabled: true,
            href: "advice",
          },
        ],
      },
      status: {},
      status_list: [
        { id: 1, name: "Accepted" },
        { id: 2, name: "Rejected" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      headers: [
        //   { text: "Staff Name", value: "staff_name" },
        { text: "Plan", value: "plan" },
        // { text: "Type", value: "type" },
        // { text: "Provider", value: "provider" },
        // { text: "Rules", value: "rules" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      dialog: false,
      modal: false,
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
