<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="teal white--text">
          <v-card-title>
            <h2 class="font-weight-light">Residential Address</h2>
          </v-card-title>
          <v-divider class="teal lighten-5"></v-divider>
          <v-card-text class="white--text">
            <div v-if="userResidentialAddresses.length < 1" class="white--text">
              No record found, kindly click the add address button to update
              your record
            </div>
            <div v-else class="address-card">
              <v-row class="mbr">
                <v-col cols="12" sm="4">
                  <p>
                    {{ userResidentialAddresses.house_type }} <br />
                    <small>House Type</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="5">
                  <p>
                    {{ userResidentialAddresses.landlord_name }} <br />
                    <small>Landloard's Name</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p>
                    {{ userResidentialAddresses.years_lived }} <br />
                    <small>Years Lived</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12">
                  <p>
                    {{ userResidentialAddresses.address }} <br />
                    <small>House Address</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12" sm="5">
                  <p>
                    {{ userResidentialAddresses.nearest_busstop }} <br />
                    <small>Nearest Bus Stop</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="7">
                  <p>
                    {{ userResidentialAddresses.landmark }} <br />
                    <small>Landmark</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12" sm="5">
                  <p>
                    {{ userResidentialAddresses.lga }} <br />
                    <small>Local Government Area</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userResidentialAddresses.state }} <br />
                    <small>State of Residence</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p>
                    {{ userResidentialAddresses.country }} <br />
                    <small>Country of Residence</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12">
                  <p>
                    {{ userResidentialAddresses.former_address }} <br />
                    <small>Previous Address</small>
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userResidentialAddresses.residing_with }} <br />
                    <small>Residing With</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userResidentialAddresses.residing_with_phone }}
                    <br />
                    <small>Phone Number</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userResidentialAddresses.status }} <br />
                    <small>Residential Address Status</small>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="userResidentialAddresses.length < 1"
              rounded
              outlined
              dark
              color="white"
              @click="addResAddressItemDialog = true"
            >
              <v-icon left>add</v-icon> Add Address
            </v-btn>
            <v-btn
              v-else
              rounded
              outlined
              dark
              color="white"
              @click="editResItem(userResidentialAddresses)"
            >
              <v-icon left>edit</v-icon> Edit Address
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card class="orange darken-3 white--text">
          <v-card-title>
            <h2 class="font-weight-light">Permanent Address</h2>
          </v-card-title>
          <v-divider class="orange lighten-5"></v-divider>
          <v-card-text class="white--text">
            <div v-if="userPermanentAddresses.length < 1" class="white--text">
              No record found, kindly click the add address button to update
              your record
            </div>
            <div v-else class="address-card">
              <v-row class="mbr">
                <v-col cols="12" sm="4">
                  <p>
                    {{ userPermanentAddresses.house_type }} <br />
                    <small>House Type</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="5">
                  <p>
                    {{ userPermanentAddresses.landlord_name }} <br />
                    <small>Landloard's Name</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p>
                    {{ userPermanentAddresses.years_lived }} <br />
                    <small>Years Lived</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12">
                  <p>
                    {{ userPermanentAddresses.address }} <br />
                    <small>House Address</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12" sm="5">
                  <p>
                    {{ userPermanentAddresses.nearest_busstop }} <br />
                    <small>Nearest Bus Stop</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="7">
                  <p>
                    {{ userPermanentAddresses.landmark }} <br />
                    <small>Landmark</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12" sm="5">
                  <p>
                    {{ userPermanentAddresses.lga }} <br />
                    <small>Local Government Area</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userPermanentAddresses.state }} <br />
                    <small>State of Residence</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p>
                    {{ userPermanentAddresses.country }} <br />
                    <small>Country of Residence</small>
                  </p>
                </v-col>
              </v-row>

              <v-row class="mbr">
                <v-col cols="12">
                  <p>
                    {{ userPermanentAddresses.former_address }} <br />
                    <small>Previous Address</small>
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userPermanentAddresses.residing_with }} <br />
                    <small>Residing With</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userPermanentAddresses.residing_with_phone }}
                    <br />
                    <small>Phone Number</small>
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <p>
                    {{ userPermanentAddresses.status }} <br />
                    <small>Permanent Address Status</small>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="userPermanentAddresses.length < 1"
              rounded
              outlined
              dark
              color="white"
              @click="addPermAddressItemDialog = true"
            >
              <v-icon left>add</v-icon> Add Address
            </v-btn>
            <v-btn
              v-else
              rounded
              outlined
              dark
              color="white"
              @click="editPermItem(userPermanentAddresses)"
            >
              <v-icon left>edit</v-icon> Edit Address
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addResAddressItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="py-2 primary--text">
          Add Residential Address
          <v-spacer></v-spacer>
          <v-btn text @click="addResAddressItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addResItemToAPI">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="country"
                      :items="countries"
                      item-text="name"
                      v-model="country"
                      label="Country Of Residence"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="state"
                      :items="states"
                      item-text="name"
                      v-model="state"
                      label="State Of Residence"
                      return-object
                      class="ml-5"
                      @change="getLgas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="lga"
                      :items="lgas"
                      v-model="lga"
                      label="Local Government Area"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="house_type"
                      v-model="house_type"
                      label="House type"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landlord_name"
                      v-model="landlord_name"
                      type="text"
                      label="Landlord name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="years_lived"
                      v-model="years_lived"
                      type="text"
                      label="No. of years Lived"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="former_address"
                      v-model="former_address"
                      label="Former address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="7">
                    <v-text-field
                      name="residing_with"
                      v-model="residing_with"
                      type="text"
                      label="Residing with"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-text-field
                      name="residing_with_phone"
                      v-model="residing_with_phone"
                      type="text"
                      label="Residing with phone"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="nearest_busstop"
                      v-model="nearest_busstop"
                      type="text"
                      label="nearest_busstop"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landmark"
                      v-model="landmark"
                      type="text"
                      label="Landmark"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="addResAddressItemDialog = false"></v-btn>
          <v-spacer></v-spacer>
          <span v-if="processing">
            <v-btn color="grey" dark rounded> Processing... </v-btn>
          </span>
          <span v-else>
            <v-btn color="primary" dark rounded @click="addResItemToAPI()">
              <v-icon left>save</v-icon>
              Save Changes
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editResAddressItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Update Residential Address</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editResAddressItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editItemForm" @submit.prevent="updateResItemOnAPI()">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm12>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="country"
                      :items="countries"
                      item-text="name"
                      v-model="country"
                      label="Country Of Residence"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="state"
                      :items="states"
                      item-text="name"
                      v-model="state"
                      label="State Of Residence"
                      return-object
                      class="ml-5"
                      @change="getLgas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="lga"
                      :items="lgas"
                      v-model="lga"
                      label="Local Government Area"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="house_type"
                      v-model="house_type"
                      label="House type"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landlord_name"
                      v-model="landlord_name"
                      type="text"
                      label="Landlord name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="years_lived"
                      v-model="years_lived"
                      type="text"
                      label="No. of years Lived"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="former_address"
                      v-model="former_address"
                      label="Former address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="residing_with"
                      v-model="residing_with"
                      type="text"
                      label="Residing with"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="residing_with_phone"
                      v-model="residing_with_phone"
                      type="text"
                      label="Residing with phone"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      name="nearest_busstop"
                      v-model="nearest_busstop"
                      type="text"
                      label="nearest_busstop"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-text-field
                      name="landmark"
                      v-model="landmark"
                      type="text"
                      label="Landmark"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="editResAddressItemDialog = false"></v-btn>
          <v-spacer></v-spacer>
          <span v-if="processing">
            <v-btn color="grey" dark rounded> Processing... </v-btn>
          </span>
          <span v-else>
            <v-btn color="primary" dark rounded @click="updateResItemOnAPI()">
              <v-icon left>save</v-icon>
              Save Changes
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPermAddressItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="py-2 primary--text">
          Add Permanent Address
          <v-spacer></v-spacer>
          <v-btn text @click="addPermAddressItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addPermItemToAPI">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="country"
                      :items="countries"
                      item-text="name"
                      v-model="country"
                      label="Country Of Residence"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="state"
                      :items="states"
                      item-text="name"
                      v-model="state"
                      label="State Of Residence"
                      return-object
                      class="ml-5"
                      @change="getLgas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="lga"
                      :items="lgas"
                      v-model="lga"
                      label="Local Government Area"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="house_type"
                      v-model="house_type"
                      label="House type"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landlord_name"
                      v-model="landlord_name"
                      type="text"
                      label="Landlord name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="years_lived"
                      v-model="years_lived"
                      type="text"
                      label="No. of years Lived"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="former_address"
                      v-model="former_address"
                      label="Former address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <!-- <v-row>
                  <v-col cols="12" sm="7">
                    <v-text-field
                      name="residing_with"
                      v-model="residing_with"
                      type="text"
                      label="Residing with"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-text-field
                      name="residing_with_phone"
                      v-model="residing_with_phone"
                      type="text"
                      label="Residing with phone"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      label="Status"
                      :rules="requiredRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="nearest_busstop"
                      v-model="nearest_busstop"
                      type="text"
                      label="nearest_busstop"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landmark"
                      v-model="landmark"
                      type="text"
                      label="Landmark"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="addPermAddressItemDialog = false"></v-btn>
          <v-spacer></v-spacer>
          <span v-if="processing">
            <v-btn color="grey" dark rounded> Processing... </v-btn>
          </span>
          <span v-else>
            <v-btn color="primary" dark rounded @click="addPermItemToAPI()">
              <v-icon left>save</v-icon>
              Save Changes
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPermAddressItemDialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>Update Permanent Address</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editPermAddressItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editItemForm" @submit.prevent="updatePermItemOnAPI()">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm12>
                    <v-text-field
                      name="address"
                      v-model="address"
                      label="Address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="country"
                      :items="countries"
                      item-text="name"
                      v-model="country"
                      label="Country Of Residence"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="state"
                      :items="states"
                      item-text="name"
                      v-model="state"
                      label="State Of Residence"
                      return-object
                      class="ml-5"
                      @change="getLgas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      name="lga"
                      :items="lgas"
                      v-model="lga"
                      label="Local Government Area"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="house_type"
                      v-model="house_type"
                      label="House type"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="landlord_name"
                      v-model="landlord_name"
                      type="text"
                      label="Landlord name"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="years_lived"
                      v-model="years_lived"
                      type="text"
                      label="No. of years Lived"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="former_address"
                      v-model="former_address"
                      label="Former address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="residing_with"
                      v-model="residing_with"
                      type="text"
                      label="Residing with"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field
                      name="residing_with_phone"
                      v-model="residing_with_phone"
                      type="text"
                      label="Residing with phone"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      name="nearest_busstop"
                      v-model="nearest_busstop"
                      type="text"
                      label="nearest_busstop"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-text-field
                      name="landmark"
                      v-model="landmark"
                      type="text"
                      label="Landmark"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn text @click="editPermAddressItemDialog = false"></v-btn>
          <v-spacer></v-spacer>
          <span v-if="processing">
            <v-btn color="grey" dark rounded> Processing... </v-btn>
          </span>
          <span v-else>
            <v-btn color="primary" dark rounded @click="updatePermItemOnAPI()">
              <v-icon left>save</v-icon>
              Save Changes
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      rounded
      :timeout="msgTimeout"
      :color="msgColor"
      v-model="snackbar"
      :multi-line="multiLine"
    >
      <v-icon>{{ msgIcon }}</v-icon>
      {{ msg }}
    </v-snackbar>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";

export default {
  name: "Addresses",
  props: ["Addresses"],
  data() {
    return {
      edit_item_dialog_title: "",
      addResAddressItemDialog: false,
      editResAddressItemDialog: false,
      addPermAddressItemDialog: false,
      editPermAddressItemDialog: false,
      addItemDialog: this.Addresses.addItemDialog,
      countries: [],
      states: [],
      lgas: [],
      add_item_dialog_title: "Add Residential address",
      processing: false,
      active_button: "Choose an address type",
      PermanentData: {
        title: "Permanent Address",
        users_list: this.Addresses.users_list,
      },
      ResidentialData: {
        title: "Residential Address",
        users_list: this.Addresses.users_list,
      },
      userResidentialAddresses: [],
      userPermanentAddresses: [],
      itemCount: 0,
      search: null,
      users_list: this.Addresses.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
      snackbar: false,
      multiLine: true,
      msgTimeout: 4000,
      msgColor: "primary",
      msgIcon: "done_all",
      msg: "",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      editItemDialog: false,
      id: "",
      address: "",
      nearest_busstop: "",
      landmark: "",
      lga: "",
      state: "",
      country: "",
      house_type: "",
      landlord_name: "",
      years_lived: "",
      former_address: "",
      residing_with: "",
      residing_with_phone: "",
      status: { id: 3, name: "Pending" },
      status_list: [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
        { id: 3, name: "Pending" },
        { id: 4, name: "Completed" },
      ],
      items: [],
    };
  },
  mixins: [mounted, methods],
};
</script>
<style lang="css" scoped>
.mbr {
  margin-bottom: -35px !important;
}
.mbr small {
  font-size: 9px !important;
}
</style>
