import { apiUrl, isEmpty, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    goBack() {
      // this.headerData.breadcrumbLinks[0].text = 'Home'
      // this.headerData.breadcrumbLinks[0].disabled = true
      // this.headerData.breadcrumbLinks[1].text = 'Staff profile'
      this.appraisal_in_view = {};
      // this.headerData.title = 'Staff profiles'
      // this.headerData.subtitle = 'Manage the company profile of all staff'
    },
    viewAppraisal(item) {
      this.appraisal_in_view = item;
      this.ScalevaluationData.data = item;
      this.ParametersData.data = item;
      this.PresentpostquestionsData.data = item;
      this.CareerdevelopmentquestionsData.data = item;
    },
    tabInfo() {
      return this.info;
    },
    openTabs(tab) {
      console.log(tab);
    },
    isNull(data) {
      return isEmpty(data);
    },
    addScaleItem(item) {
      this.item_in_view = item;
      this.addScaleItemDialog = true;
    },

    addScaleEvalautionItem(item_in_view) {
      this.$refs.addScaleItemForm.validate();
      if (this.validateScaleForm() == false) {
        return;
      }
      let item = {
        id: 0,
        added_by: this.$root.curuser,
        title: this.title,
        description: this.description,
        performanceappraisal_id: item_in_view.id,
        point: this.point,
      };

      item_in_view.scaleevaluations = item;
      this.addScaleItemDialog = false;
      let new_item = {
        added_by: item.added_by.id,
        title: item.title,
        description: item.description,
        performanceappraisal_id: item.performanceappraisal_id,
        point: item.point.id,
      };
      this.addItemScaleToAPI(new_item, item_in_view);
    },

    addItemScaleToAPI(item, item_in_view) {
      Axios.post(`${apiUrl}/scaleevaluations`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          item_in_view.scaleevaluations.id = data.id;
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          item_in_view.scaleevaluations = {};
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    loadAllUsers() {
      this.users_list = this.$root.users_list;
      this.ScalevaluationData.users_list = this.users_list;
      this.ParametersData.users_list = this.users_list;
      this.PresentpostquestionsData.users_list = this.users_list;
      this.CareerdevelopmentquestionsData.users_list = this.users_list;
      this.LoaderData.dialog = false;
    },
    async getAllUsers() {
      await Axios.get(`${apiUrl}/users/all`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          data.forEach((user) => {
            user.name = user.surname + " " + user.firstname;
            this.users_list.push(user);
          });

          this.ScalevaluationData.users_list = this.users_list;
          this.ParametersData.users_list = this.users_list;
          this.PresentpostquestionsData.users_list = this.users_list;
          this.CareerdevelopmentquestionsData.users_list = this.users_list;
          this.LoaderData.dialog = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    addMonth(m) {
      let d = new Date();
      let years = Math.floor(m / 12);
      let months = m - years * 12;
      if (years) d.setFullYear(d.getFullYear() + years);
      if (months) d.setMonth(d.getMonth() + months);
      return d.toISOString().substr(0, 10);
    },
    init() {
      this.loadAllUsers();
      //    this.getAllUsers()

      this.loading = true;
      this.items = [];
      this.getHR_Ids();
      this.getAllData();
    },

    refreshTable() {
      this.init();
    },
    validateScaleForm() {
      if (this.title == "") {
        return false;
      }
      if (this.description == "") {
        return false;
      }
      if (isEmpty(this.point)) {
        return false;
      }
      return true;
    },
    editItem(item) {
      this.item_in_view = item;
      this.status = item.status;
      this.title = item.title;
      this.starts = item.starts;
      this.ends = item.ends;
      this.editItemDialog = true;
    },
    editItemArray(item) {
      // this.$refs.editItemForm.validate()
      // if(this.validateForm()== false){
      //     return
      // }
      if (item != null) {
        item.title = this.title;
        item.starts = this.starts;
        item.ends = this.ends;
        item.status = this.status;
        item.ended_by = this.ended_by;
        item.ended_on = this.ended_on;

        let put = {
          id: item.id,
          starts: item.starts,
          ends: item.ends,
          status: item.status.name,
          ended_by: item.ended_by.id,
          ended_on: item.ended_on,
        };
        this.updateItemOnAPI(put);
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/performanceappraisals/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });
          console.log(response);
          if (item.status == "Completed" && item.ended_on != "") {
            if (item.ended_by != undefined) {
              let notification_data = {
                message:
                  this.$root.curuser.surname +
                  " " +
                  this.$root.curuser.firstname +
                  " has ended the performance appraisal and marked the status as: " +
                  item.status,
                title: "End of Appraisal",
                sender_id: this.$root.curuser.id,
              };
              let staff_list = [];
              this.users_list.forEach((user) => {
                if (user != null) {
                  staff_list.push(user.id);
                }
              });
              notification_data.receiver_ids = staff_list;
              this.postNotification(notification_data);
            }
          }
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    validateForm() {
      if (this.title == "") {
        return false;
      }
      if (this.starts == "") {
        return false;
      }
      if (this.ends == "") {
        return false;
      }
      if (isEmpty(this.status)) {
        return false;
      }
      return true;
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }
      let item = {
        id: 0,
        started_by: this.$root.curuser,
        title: this.title,
        starts: this.starts,
        ends: this.ends,
        status: this.status,
        hrsubsidiary_id: this.hrsubsidiary_id + 1,
        scaleevaluations: {},
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        started_by: item.started_by.id,
        title: item.title,
        starts: item.starts,
        ends: item.ends,
        status: item.status.name,
        hrsubsidiary_id: item.hrsubsidiary_id,
      };
      this.addItemToAPI(new_item);
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/performanceappraisals`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (data.title == item.title && data.ends == item.ends) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.title == item.title &&
                storedData.ends == item.ends
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });
          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " has started the performance appraisal with start date as: " +
              item.starts +
              " and end date as: " +
              item.ends,
            title: "Staff Appraisal",
            sender_id: this.$root.curuser.id,
          };
          let staff_list = [];
          this.users_list.forEach((user) => {
            if (user != null) {
              staff_list.push(user.id);
            }
          });
          notification_data.receiver_ids = staff_list;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.title == item.title && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },

    async getAllData() {
      await Axios.get(`${apiUrl}/performanceappraisals`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let started_by = this.users_list.filter(
          (element) => q.started_by == element.id
        );
        let status = this.status_list.filter(
          (element) => q.status == element.name
        );

        q.status = status.length > 0 ? status[0] : {};
        q.started_by = started_by.length > 0 ? started_by[0] : {};
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },
    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    proceedToAdd() {
      this.addItemDialog = true;
    },
  },
};
