<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-btn
                fab
                color="blue"
                class="white--text"
                @click="refreshTable()"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>
              <!-- <v-btn v-if="locations_list != {}" fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
              </v-btn> -->
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <h2>
                          <span
                            class="myFocus"
                            @click="detailsDialogPopup(item)"
                          >
                            {{ item.user.surname + " " + item.user.firstname }}
                          </span>
                        </h2>
                      </td>

                      <td width="10">
                        <v-chip class="ma-2" color="blue" text-color="white">
                          {{ item.start_date + " TO " + item.end_date }}
                        </v-chip>
                      </td>
                      <td>{{ item.booked_month.name }}</td>
                      <td>
                        <v-btn
                          color="blue"
                          fab
                          small
                          dark
                          @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <!-- <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="leave_type"
                      v-model="leave_type"
                      :items="leave_types_list"
                      item-text="name"
                      label="Choose Leave type"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="leave_year"
                      v-model="leave_year"
                      :items="leave_years_list"
                      item-text="name"
                      class="ml-5"
                      label="Choose Year"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="booked_month"
                      v-model="booked_month"
                      :items="booked_months_list"
                      item-text="name"
                      class="ml-5"
                      label="Booked Month"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_entitled"
                      v-model="no_days_entitled"
                      label="No. of days entitled"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_taken"
                      v-model="no_days_taken"
                      type="text"
                      label="No. of days taken"
                      class="ml-5"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_remaining"
                      v-model="no_days_remaining"
                      type="text"
                      label="No. of days remaining"
                      class="ml-5"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="sdialog"
                      v-model="start_date_dialog"
                      :return-value.sync="start_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="start_date"
                          label="Start Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="start_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="start_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(start_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="edialog"
                      v-model="end_date_dialog"
                      :return-value.sync="end_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="end_date"
                          label="End Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="end_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="end_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.edialog.save(end_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="hod"
                      v-model="hod"
                      :items="users_list"
                      item-text="name"
                      label="Staff HOD"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="releaved_by"
                      v-model="releaved_by"
                      :items="users_list"
                      item-text="name"
                      class="ml-5"
                      label="Relieving Staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit item dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="editItemDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="users_list"
                        item-text="name"
                        label="Choose a staff"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout> -->
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="leave_type"
                      v-model="leave_type"
                      :items="leave_types_list"
                      item-text="name"
                      label="Choose Leave type"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="leave_year"
                      v-model="leave_year"
                      :items="leave_years_list"
                      item-text="name"
                      class="ml-5"
                      label="Choose Year"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="booked_month"
                      v-model="booked_month"
                      :items="booked_months_list"
                      item-text="name"
                      class="ml-5"
                      label="Booked Month"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_entitled"
                      v-model="no_days_entitled"
                      label="No. of days entitled"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_taken"
                      v-model="no_days_taken"
                      type="text"
                      label="No. of days taken"
                      class="ml-5"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="no_days_remaining"
                      v-model="no_days_remaining"
                      type="text"
                      label="No. of days remaining"
                      class="ml-5"
                      :rules="plateNumberRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="sdialog"
                      v-model="start_date_dialog"
                      :return-value.sync="start_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="start_date"
                          label="Start Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="start_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="start_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(start_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="edialog"
                      v-model="end_date_dialog"
                      :return-value.sync="end_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="end_date"
                          label="End Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          class="ml-5"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="end_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="end_date_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.edialog.save(end_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="hod"
                      v-model="hod"
                      :items="users_list"
                      item-text="name"
                      label="Staff HOD"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-autocomplete
                      name="releaved_by"
                      v-model="releaved_by"
                      :items="users_list"
                      item-text="name"
                      class="ml-5"
                      label="Relieving Staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="hod_approved"
                      v-model="hod_approved"
                      :items="leave_status_list"
                      item-text="name"
                      label="HOD Approved?"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm8>
                    <v-text-field
                      name="hod_comment"
                      class="ml-5"
                      v-model="hod_comment"
                      label="HOD Comment"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <!-- <v-flex xs12 sm4>
                        <v-autocomplete
                        name="leave_status"
                        v-model="leave_status"
                        :items="leave_status_list"
                        item-text="name"
                        class="ml-5"
                        label="Leave Status"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex> -->
                </v-layout>

                <!-- <v-layout row wrap>
                    <v-flex xs12 sm4>
                        <v-autocomplete
                        name="leave_advice_by"
                        v-model="leave_advice_by"
                        :items="users_list"
                        item-text="name"
                        label="Leave advice by"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                              ref="leave_advice_onedialog"
                              v-model="leave_advice_on_dialog"
                              :return-value.sync="leave_advice_on"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="leave_advice_on"
                                  label="Leave adviced on"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                  :rules="requiredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="leave_advice_on" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="leave_advice_on = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.leave_advice_onedialog.save(leave_advice_on)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                
                </v-flex>

                    <v-flex xs12 sm4>
                        <v-text-field
                          name="leave_advice"
                          class="ml-5"
                          v-model="leave_advice"
                          label="Leave advice"
                          type="text"
                          :rules="titleRules"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                 -->

                <!-- 
                    <v-layout row wrap>
                    <v-flex xs12 sm4>
                        <v-autocomplete
                        name="resumption_confirmed_by"
                        v-model="resumption_confirmed_by"
                        :items="users_list"
                        item-text="name"
                        label="Resumption confirmed by"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                              ref="resumption_confirmed_onedialog"
                              v-model="resumption_confirmed_on_dialog"
                              :return-value.sync="resumption_confirmed_on"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="resumption_confirmed_on"
                                  label="Resumption confirmed on"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                  :rules="requiredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="resumption_confirmed_on" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resumption_confirmed_on_dialog = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.resumption_confirmed_onedialog.save(resumption_confirmed_on)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                
                </v-flex>

                    <v-flex xs12 sm4>
                      <v-dialog
                              ref="resumed_onedialog"
                              v-model="resumed_on_dialog"
                              :return-value.sync="resumed_on"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="resumed_on"
                                  label="Resumed on"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                  :rules="requiredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="resumed_on" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resumed_on_dialog = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.resumed_onedialog.save(resumed_on)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                
                </v-flex>

                    
                </v-layout> -->

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="hod_approved"
                      v-model="hod_approved"
                      :items="leave_status_list"
                      item-text="name"
                      label="HOD Approved?"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm delete dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="detailsDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <v-avatar v-if="item_in_view.user != null" size="50">
            <span v-if="item_in_view.user.avatar_id" class="msg-not">
              <img :src="item_in_view.user.avatar_id" alt />
            </span>
          </v-avatar>
          <span v-if="item_in_view.user != null">{{
            item_in_view.user.surname + " " + item_in_view.user.firstname
          }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <br />
            <h2 v-if="item_in_view.hod != null">
              HOD:
              {{ item_in_view.hod.surname + " " + item_in_view.hod.firstname }}
            </h2>
            <br />
            <h2 v-if="item_in_view.hod_approved != null">
              HOD Approved: {{ item_in_view.hod_approved }}
            </h2>
            <br />
            <h2 v-if="item_in_view.hod_comment != null">
              HOD Comment: {{ item_in_view.hod_comment }}
            </h2>
            <br />
            <h2 v-if="item_in_view.leave_advice_by != null">
              Adviced by:
              {{
                item_in_view.leave_advice_by.surname +
                " " +
                item_in_view.leave_advice_by.firstname
              }}
            </h2>
            <br />
            <h2 v-if="item_in_view.leave_advice != null">
              Leave advice: {{ item_in_view.leave_advice }}
            </h2>
            <br />
            <h2 v-if="item_in_view.releaved_by != null">
              Relieved by:
              {{
                item_in_view.releaved_by.surname +
                " " +
                item_in_view.releaved_by.firstname
              }}
            </h2>
            <br />
            <h2 v-if="item_in_view.leave_type != null">
              Leave Type: {{ item_in_view.leave_type.name }}
            </h2>
            <br />
            <h2 v-if="item_in_view.booked_month != null">
              Booked month: {{ item_in_view.booked_month.name }}
            </h2>
            <br />
            <h2 v-if="item_in_view.no_days_taken != null">
              No. days taken: {{ item_in_view.no_days_taken }}
            </h2>
            <br />
            <h2 v-if="item_in_view.no_days_remaining != null">
              No. days remaining: {{ item_in_view.no_days_remaining }}
            </h2>
            <br />
            <h2 v-if="item_in_view.no_days_entitled != null">
              No. days entitled: {{ item_in_view.no_days_entitled }}
            </h2>
            <br />
            <h2 v-if="item_in_view.start_date != null">
              Start Date: {{ item_in_view.start_date }}
            </h2>
            <br />
            <h2 v-if="item_in_view.end_date != null">
              End Date: {{ item_in_view.end_date }}
            </h2>
            <br />
            <h2 v-if="item_in_view.leave_year != null">
              Leave year: {{ item_in_view.leave_year.name }}
            </h2>
            <br />
            <h2 v-if="item_in_view.resumed_on != null">
              Resumed on: {{ item_in_view.resumed_on }}
            </h2>
            <br />
            <h2 v-if="item_in_view.status != null">
              Status: {{ item_in_view.status.name }}
            </h2>
            <br />
            <h2 v-if="item_in_view.resumption_confirmed_by != null">
              Resumption confirmed by:
              {{
                item_in_view.resumption_confirmed_by.surname +
                " " +
                item_in_view.resumption_confirmed_by.firstname
              }}
            </h2>
            <br />
            <h2 v-if="item_in_view.resumption_confirmed_on != null">
              Resumption confirmed on:
              {{ item_in_view.resumption_confirmed_on }}
            </h2>

            <!-- {{item_in_view}} -->
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Staff Leave";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      detailsDialog: false,
      details: [],
      hr_ids: [],
      leave_status: {},
      resumed_on_dialog: false,
      leave_advice_on_dialog: false,
      leave_status_list: this.$root.leave_statuses,
      start_date_dialog: false,
      end_date_dialog: false,
      leave_advice_by: {},
      resumption_confirmed_by: {},
      resumption_confirmed_on_dialog: false,
      resumption_confirmed_on: "",
      hod_approved: "",
      hod_comment: "",
      leave_advice: "",
      leave_advice_on: "",
      resumed_on: "",
      start_date: "",
      end_date: "",
      no_days_entitled: "",
      no_days_taken: "",
      no_days_remaining: 0,
      hod: {},
      releaved_by: {},
      user: {},
      users_list: [],
      leave_type: {},
      leave_types_list: this.$root.leave_types,
      leave_year: {},
      leave_years_list: this.$root.years,
      booked_month: {},
      booked_months_list: this.$root.months,
      attendance_dates: [],
      attendanceDialog: false,
      attendance_data: [],
      sort_item_by_vendor_dialog_title: "Search by Vendor",
      sortItemByVendorDialog: false,
      search_location: "",
      search_vendor: "",
      sort_item_dialog_title: "Search Trucks by location",
      sortItemDialog: false,
      vendorViewDialog: false,
      vendor_in_view: {},
      vendors_list: [],
      locations_list: [],
      delete_item_dialog_title: "Delete a Leave advice",
      confirmDeleteDialog: false,
      item_in_view: {},
      add_item_dialog_title: "Add Staff Leave advice",
      edit_item_dialog_title: "Edit Leave",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      maxYear: this.addMonth(12),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "My colleagues' leave advice",
        subtitle: "View and manage all staff Leave advice",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/hod",
          },
          {
            text: "Leave",
            disabled: true,
            href: "advice",
          },
        ],
      },
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      vehicle_status: "",
      plate_number: "",
      status: {},
      status_list: this.$root.leave_statuses,
      headers: [
        { text: "Staff Name", value: "staff_name" },
        { text: "Leave Date", value: "leave_date" },
        { text: "Booked Month", value: "Booked Month" },
        { text: "Edit", value: "edit" },
        // { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: "2MB",
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      titleRules: [(v) => !!v || "Name of Truck is required"],
      plateNumberRules: [(v) => !!v || "Plate number is required"],
      departmentRules: [(v) => !!v || "Official department/unit is required"],
      locationRules: [(v) => !!v || "Location is required"],
      vendorRules: [(v) => !!v || "A Vendor is required"],
      requiredRules: [(v) => !!v || "This field is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
