<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-btn
                fab
                color="blue"
                class="white--text"
                @click="refreshTable()"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Truck -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip class="ma-2" color="green" text-color="white">
                          <v-icon left>person</v-icon>
                          <h2>
                            <span
                              v-if="!isNull(item.user)"
                              class="white--text myFocus"
                              @click="detailsDialogPopup(item)"
                            >
                              {{
                                item.user.surname + " " + item.user.firstname
                              }}
                            </span>
                            <span v-else>No User</span>
                          </h2>
                        </v-chip>
                      </td>
                      <td>{{ item.hospital }}</td>
                      <td>{{ item.hmo_id }}</td>

                      <td width="10">
                        <v-chip class="ma-2" color="blue" text-color="white">
                          {{ item.plan }}
                        </v-chip>
                      </td>
                      <td width="10">
                        <v-chip class="ma-2" color="blue" text-color="white">
                          {{ item.status.name }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          color="blue"
                          fab
                          small
                          dark
                          @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn
                          color="red"
                          fab
                          small
                          dark
                          @click="proceedToRemoveItem(item)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              >Total: {{ itemCount | formatNumber }}</v-card-actions
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="provider"
                      v-model="provider"
                      label="provider"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="hmo_id"
                      v-model="hmo_id"
                      type="text"
                      label="HMO ID number"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="plan_dialog"
                      :return-value.sync="plan"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="plan"
                          label="Plan date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="plan"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="plan_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(plan)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hospital"
                      v-model="hospital"
                      type="text"
                      label="Hospital"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit item dialog -->
    <v-dialog
      v-if="item_in_view != {}"
      v-model="editItemDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-autocomplete
                      name="user"
                      v-model="user"
                      :items="users_list"
                      item-text="name"
                      label="Choose a staff"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="provider"
                      v-model="provider"
                      label="provider"
                      type="text"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="hmo_id"
                      v-model="hmo_id"
                      type="text"
                      label="HMO ID number"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm4>
                    <v-dialog
                      ref="sdialog"
                      v-model="plan_dialog"
                      :return-value.sync="plan"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="plan"
                          label="Plan date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        :min="minYear"
                        :max="maxYear"
                        v-model="plan"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="plan_dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.sdialog.save(plan)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-text-field
                      name="hospital"
                      v-model="hospital"
                      type="text"
                      label="Hospital"
                      class="ml-5"
                      :rules="titleRules"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-autocomplete
                      name="status"
                      v-model="status"
                      :items="status_list"
                      item-text="name"
                      class="ml-5"
                      label="Status"
                      :rules="locationRules"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn
            color="warning"
            x-large
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="isNull(item_in_view) == false"
      v-model="detailsDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <v-avatar v-if="item_in_view.user != null" size="50">
            <span v-if="item_in_view.user.avatar_id" class="msg-not">
              <img :src="item_in_view.user.avatar_id" alt />
            </span>
          </v-avatar>
          <span v-if="item_in_view.user != null">{{
            item_in_view.user.surname + " " + item_in_view.user.firstname
          }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat> </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Travels";
  },

  components: {
    InnerHeader,
  },

  data() {
    return {
      plan_dialog: false,
      plan: "",
      provider: "",
      hmo_id: "",
      hospital: "",
      approval_comment: "",
      approved_by: {},
      added_by: this.$root.curuser,

      leave_status_list: this.$root.leave_statuses,
      detailsDialog: false,
      user: this.$root.curuser,
      users_list: [],

      sort_item_dialog_title: "Search Trucks by location",
      sortItemDialog: false,
      delete_item_dialog_title: "Delete a HMO plan",
      confirmDeleteDialog: false,
      item_in_view: {},
      add_item_dialog_title: "Add Staff HMO",
      edit_item_dialog_title: "Edit Staff HMO",
      minYear: new Date().getFullYear() + "-" + "01" + "-" + "01",
      // maxYear: new Date().toISOString().substr(0, 10),
      maxYear: this.addMonth(8),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Staff HMO",
        subtitle: "View and manage all staff HMO",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard/hr",
          },
          {
            text: "Leave",
            disabled: true,
            href: "advice",
          },
        ],
      },
      items: [],
      itemCount: 0,
      search: null,

      status: {},
      status_list: this.$root.leave_statuses,
      headers: [
        { text: "Staff Name", value: "staff_name" },
        { text: "Hospital", value: "hospital" },
        { text: "HMO ID", value: "hmo_id" },
        { text: "Plan", value: "plan" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      titleRules: [(v) => !!v || "This field is required"],
      locationRules: [(v) => !!v || "This field is required"],
      requiredRules: [(v) => !!v || "This field is required"],
      transactionDateRules: [
        (v) => !!v || "Date at which expense occured is required",
      ],
      amountRules: [(v) => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods],
};
</script>

<style>
.v-time-ago__text {
  color: #0097a7 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.pinSize {
  width: 70% !important;
  font-size: 80px;
  text-align: center !important;
}
.myFocus {
  text-decoration: underline;
  cursor: pointer;
}
</style>
