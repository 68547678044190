<template>
  <nav>
    <v-app-bar app color="white" light>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img :src="images.logo" alt="Staff Portal" width="220"></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="authUser">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text rounded v-bind="attrs" v-on="on">
              <v-badge
                v-bind="attrs"
                v-on="on"
                @mouseover="hoverNotification()"
                color="primary"
                dark
                :content="notification_count"
              >
                <v-icon size="27" color="blue-grey darken-3">
                  notifications_active
                </v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list three-line max-width="450">
            <template v-for="(item, index) in notifications_list">
              <v-subheader
                v-if="item.header"
                :key="index"
                v-text="item.header"
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item v-else :key="index">
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu open-on-hover offset-y class="ml-5">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon left color="blue-grey darken-3" size="30"
                >person_pin</v-icon
              >
              <span class="hidden-xs-only blue-grey--text">{{
                curUserData.firstname
              }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <router-link
                :to="`/profile/${curUserData.firstname}${curUserData.id}`"
              >
                <v-icon left>person</v-icon>Profile
              </router-link>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon color="primary" left>power_settings_new</v-icon>Sign Out
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-dialog v-model="openNotification" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          <span>{{ notification.title }}</span>
          <v-spacer></v-spacer>
          <span>
            <time-ago
              refresh
              :datetime="notification.added_on"
              long
              tooltip="top"
              class="time-ago-small"
            ></time-ago>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <div v-if="fetching">
                <v-progress-linear
                  indeterminate
                  height="10"
                  color="loadingcolor"
                ></v-progress-linear>
              </div>
              <div v-else class="mb-4">
                <div v-html="notification.message"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openNotification = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openMessage" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          <span v-for="(avatar, index) in avatars" :key="index" class>
            <v-avatar size="50">
              <span v-if="avatar.user_id === user_id" class="msg-not">
                <img :src="avatar.avatar" alt />
              </span>
            </v-avatar>
          </span>
          <span>{{ message.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <div v-if="fetching">
                <v-progress-linear
                  indeterminate
                  height="10"
                  color="loadingcolor"
                ></v-progress-linear>
              </div>
              <div v-else class="mb-4">
                <div v-html="message.message"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="openMessage = false">Close</v-btn>
          <v-spacer></v-spacer>
          <span v-if="message.priority === 'high'">
            <v-chip pill>
              <v-avatar left color="primary">H</v-avatar>
              <span class="primary--text text-uppercase"
                >{{ message.priority }} Priority</span
              >
            </v-chip>
          </span>
          <span v-else-if="message.priority === 'medium'">
            <v-chip pill>
              <v-avatar left color="teal">M</v-avatar>
              <span class="teal--text text-uppercase"
                >{{ message.priority }} Priority</span
              >
            </v-chip>
          </span>
          <span v-else>
            <v-chip pill>
              <v-avatar left color="blue-grey">L</v-avatar>
              <span class="blue-grey--text text-uppercase"
                >{{ message.priority }} Priority</span
              >
            </v-chip>
          </span>
          <v-spacer></v-spacer>
          <span>
            <time-ago
              refresh
              :datetime="message.sent_on"
              long
              tooltip="top"
              class="time-ago-small"
            ></time-ago>
          </span>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="openMessage = false" :to="`/message`">
            <v-icon left>reply</v-icon>Reply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="elevation-3"
      color="white"
      light
    >
      <div v-if="authUser">
        <v-row
          class="pa-2 is-clickable"
          @click="loadProfile(curUserData.firstname, curUserData.id)"
        >
          <v-col cols="12" sm="5">
            <span v-if="curUserData == null">
              <v-skeleton-loader
                class="mx-auto"
                type="card"
              ></v-skeleton-loader>
            </span>
            <span v-else>
              <v-avatar size="100">
                <img v-if="curUserData" :src="curUserData.avatar_id" alt />
                <img v-else :src="images.avatar" alt />
              </v-avatar>
            </span>
          </v-col>
          <v-col cols="12" sm="7">
            <p class="sunheading mt-4">
              {{ curUserData.firstname + " " + curUserData.surname }}
              <br />
              <small>{{ curUserData.email }}</small>
              <br />
              <small>{{ curUserData.staff_id }}</small>
            </p>
          </v-col>
        </v-row>
      </div>

      <v-list v-if="user_position == 'HR'">
        <div v-for="genLink in HRLinks" :key="genLink.text">
          <v-list-item v-if="!genLink.subLinks" router :to="genLink.route">
            <v-list-item-icon>
              <v-icon>{{ genLink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ genLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="genLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ genLink.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sublink, i) in genLink.subLinks"
              :key="i"
              link
              :to="sublink.route"
            >
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <v-list v-if="user_position == 'STAFF'">
        <div v-for="genLink in StaffLinks" :key="genLink.text">
          <v-list-item v-if="!genLink.subLinks" router :to="genLink.route">
            <v-list-item-icon>
              <v-icon>{{ genLink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ genLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="genLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ genLink.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sublink, i) in genLink.subLinks"
              :key="i"
              link
              :to="sublink.route"
            >
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import navmethods from "./navmethods";
import navmounted from "./navmounted";
// import NotificationBell from "vue-notification-bell";

export default {
  components: {
    // NotificationBell, // Registering Component
  },
  data() {
    return {
      seen_notications_list: [],
      intervalID: null,
      markIntervalID: null,
      users_list: this.$root.users_list,
      notifications_list: [],
      notification_count: 0,
      drawer: true,
      images: {
        logo: require("@/assets/logo.png"),
        avatar: require("@/assets/avatar.png"),
      },
      themeLogo: "white",
      user_id: this.$root.curuser.id,
      cur_shift: this.$root.curlocation.cur_shift,
      user_signed_in_location: this.$root.curlocation.cur_location_id,
      clock: "",
      time: "",
      date: "",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      avatars: [],
      users: [],
      notifications: [],
      unread_notifications: 0,
      notification: [],
      openNotification: false,
      fetchNotitfications: "",
      messages: [],
      unread_messages: 0,
      message: [],
      loadingProfile: true,
      openMessage: false,
      fetchMessages: "",
      fetching: false,
      guestlinks: [
        {
          icon: "exit_to_app",
          text: "Sign In",
          route: "/login",
        },
        {
          icon: "group_add",
          text: "Sign Up",
          route: "/signup",
        },
      ],

      HRLinks: [
        {
          route: "/dashboard/hr",
          icon: "home",
          text: "Home",
        },
        {
          route: "/account/hr",
          icon: "people",
          text: "Staff Profile",
        },
        // {
        //   route: "/staff-attendance",
        //   icon: "people",
        //   text: "Staff Attendance"
        // },
        {
          route: "/hmo/hr",
          icon: "room",
          text: "HMO",
        },
        {
          route: "/travels/hr",
          icon: "local_shipping",
          text: "Staff Travels",
        },
        // {
        //   route: "/users",
        //   icon: "person",
        //   text: "Staff Development"
        // },

        {
          route: "/travels/hr",
          icon: "directions_walk",
          text: "Travels",
        },
        {
          route: "/leave/hr",
          icon: "commute",
          text: "Leave Advice",
        },
        {
          route: "/pension",
          icon: "assignment",
          text: "Pension",
        },
      ],
      StaffLinks: [
        {
          route: "/dashboard/staff",
          icon: "home",
          text: "Home",
        },
        {
          route: "/account/staff",
          icon: "people",
          text: "Staff Profile",
        },
        // {
        //   route: "/staff-attendance",
        //   icon: "people",
        //   text: "Staff Attendance"
        // },
        {
          route: "/leave/staff",
          icon: "commute",
          text: "Leave Advice",
        },
        {
          route: "/pension",
          icon: "assignment",
          text: "Pension",
        },
      ],
      adminLinks: [
        {
          icon: "settings",
          text: "Settings",
          subLinks: [
            {
              icon: "security",
              text: "Roles and Permissions",
              route: "/roles-permissions",
            },
            {
              icon: "people",
              text: "User Management",
              route: "/user-management",
            },
            {
              icon: "emoji_transportation",
              text: "Expense Types",
              route: "/expense-types",
            },
            {
              icon: "assignment_turned_in",
              text: "Retirement Types",
              route: "/retirement-types",
            },
            {
              icon: "store_mall_directory",
              text: "Departments",
              route: "/departments",
            },
          ],
        },
      ],
      // CITLinks: [
      //   {
      //     text: "My Account",
      //     subLinks: [
      //       {
      //         route: "/citclients",
      //         icon: "person",
      //         text: "Clients"
      //       },
      //       {
      //         icon: "nature_people",
      //         text: "Movements",
      //         route: "/citmovements"
      //       }
      //     ]
      //   }
      // ],
      BudgetLinks: [
        {
          text: "Attendance",
          subLinks: [
            {
              route: "/budget",
              icon: "account_balance_wallet",
              text: "Repairs",
            },
            {
              icon: "account_balance_wallet",
              text: "Fueling",
              route: "/fueling",
            },
          ],
        },
      ],

      MaintenanceLinks: [
        {
          text: "General",
          subLinks: [
            {
              route: "/maintenance",
              icon: "build",
              text: "Maintenance",
            },
            {
              icon: "extension",
              text: "Repairs",
              route: "/repairs",
            },
            {
              icon: "commute",
              text: "Last Job",
              route: "/lastJob",
            },
            {
              icon: "departure_board",
              text: "Damages",
              route: "/damages",
            },
          ],
        },
      ],
    };
  },

  computed: {
    user_position() {
      return this.$root.user_position;
    },
    authUser() {
      return this.$root.authenticated;
    },
    curUserData() {
      return this.$root.curuser;
    },
    curUserAvatar() {
      return this.$root.curuseravatar;
    },
    curUserAvatarBig() {
      return this.$root.curuseravatarbig;
    },
    admin() {
      return this.$root.curuserroles[0] === "Super Administrator";
    },
    ermc() {
      return (
        this.$root.curuserroles[0] === "ERMC Executive" ||
        this.$root.curuserroles[0] === "ERMC Officer"
      );
    },
    cs() {
      return (
        this.$root.curuserroles[0] === "Corporate Services Executive" ||
        this.$root.curuserroles[0] === "Corporate Services Officer"
      );
    },
    finance() {
      return (
        this.$root.curuserroles[0] === "Finance Executive" ||
        this.$root.curuserroles[0] === "Finance Officer"
      );
    },
    manager() {
      return this.$root.curuserroles[0] === "Manager";
    },
  },

  beforeDestroy() {
    clearInterval(this.clock);
    clearInterval(this.fetchNotitfications);
    clearInterval(this.fetchMessages);
    clearInterval(this.intervalID);
    clearInterval(this.markIntervalID);
  },

  mixins: [navmethods, navmounted],
};
</script>
