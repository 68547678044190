<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h3 class="font-weight-light">
              {{ pageTitle }}
            </h3>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              light
              color="blue-grey darken-2"
              class="mr-2"
              @click="refreshTable()"
            >
              <v-icon>refresh</v-icon> Refresh
            </v-btn>
            <v-btn
              outlined
              rounded
              dark
              color="primary"
              @click="addItemDialog = true"
            >
              <v-icon>add</v-icon> New Recoord
            </v-btn>
          </v-card-title>
          <v-divider class="primary"></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td class="clickable" @click="viewDetail(item)">
                      {{ item.name }}
                    </td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.phone_number }}</td>
                    <td>{{ item.email_address }}</td>
                    <td>{{ item.years_experience }}</td>
                    <td>
                      <v-btn
                        color="orange darken-2"
                        rounded
                        outlined
                        small
                        dark
                        class="mr-2"
                        @click="editItem(item)"
                      >
                        <v-icon>edit</v-icon> Update
                      </v-btn>
                      <v-btn
                        color="blue-grey darken-2"
                        rounded
                        outlined
                        small
                        dark
                        @click="viewDetail(item)"
                      >
                        <v-icon>visibility</v-icon> View
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>Total: {{ itemCount | formatNumber }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ add_item_dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Employer"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      name="address"
                      v-model="address"
                      type="text"
                      label="Address"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="phone_number"
                      v-model="phone_number"
                      type="number"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      name="email_address"
                      v-model="email_address"
                      label="Email address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      name="years_experience"
                      v-model="years_experience"
                      type="number"
                      label="Years experience"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded dark class="px-6" @click="addItem()">
            <v-icon left>save</v-icon> Save Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          <span>{{ edit_item_dialog_title }} ID: {{ item_in_view.id }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form
              ref="editItemForm"
              @submit.prevent="editItemArray(item_in_view)"
            >
              <v-card-text>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      name="name"
                      v-model="name"
                      label="Name of Employer"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      name="address"
                      v-model="address"
                      type="text"
                      label="Address"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="phone_number"
                      v-model="phone_number"
                      type="number"
                      label="Phone number"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      name="email_address"
                      v-model="email_address"
                      label="Email address"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      name="years_experience"
                      v-model="years_experience"
                      type="number"
                      label="Years experience"
                      class="ml-5"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            dark
            class="px-6"
            @click="editItemArray(item_in_view)"
          >
            <v-icon left>save</v-icon>
            Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
          Training Detail
          <v-spacer></v-spacer>
          <v-btn text @click="detailsDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">business</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.name"
                ></v-list-item-title>
                <v-list-item-subtitle> Name </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.address"
                ></v-list-item-title>
                <v-list-item-subtitle> Location </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">phone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.phone_number"
                ></v-list-item-title>
                <v-list-item-subtitle> Phone Number </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon size="37" color="primary">email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="currentItem.email_address"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  Certification Obtained
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">event_available</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="currentItem.years_experience"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      Years of Experience
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="37" color="primary">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Exited</v-list-item-title>
                    <v-list-item-subtitle> Status </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded text @click="detailsDialog = false"> close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "LastEmployer",
  props: ["LastEmployer"],
  data() {
    return {
      pageTitle: "My Employment History",
      detailsDialog: false,
      details: [],
      hr_ids: [],
      edit_item_dialog_title: "",
      editItemDialog: false,
      years_experience: "",
      email_address: "",
      phone_number: "",
      address: "",
      name: "",
      status: {},
      status_list: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Suspended" },
      ],
      add_item_dialog_title: "Add Last Employer",
      addItemDialog: this.LastEmployer.addItemDialog,
      headers: [
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Phone number", value: "phone_number" },
        { text: "Email address", value: "email_address" },
        { text: "Years experience", value: "years_experience" },
        { text: "ACTIONS", value: "id" },
      ],
      currentItem: [],
      items: [],
      itemCount: 0,
      search: null,
      users_list: this.LastEmployer.users_list,
      loading: false,
      item_in_view: {},
      naira: "&#8358;",
      currency_symbol: "&#8358;",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mixins: [
    mounted,
    //  computed,
    methods,
  ],
};
</script>
