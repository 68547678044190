import { apiUrl, capitalize } from "@/config";
import Axios from "axios";

export default {
  methods: {
    editItem(item) {
      this.item_in_view = item;
      this.name = item.name;
      this.address = item.address;
      this.years_experience = item.years_experience;
      this.phone_number = item.phone_number;
      this.email_address = item.email_address;

      this.editItemDialog = true;
    },
    editItemArray(item) {
      if (item != null) {
        item.name = this.name;
        item.address = this.address;
        item.years_experience = this.years_experience;
        item.phone_number = this.phone_number;
        item.email_address = this.email_address;

        let new_items = [];
        this.items.forEach((element, index) => {
          new_items[index] = element;
          if (element.id == item.id) {
            new_items[index] = item;

            let new_item = {
              id: item.id,
              name: item.name,
              address: item.address,
              years_experience: item.years_experience,
              phone_number: item.phone_number,
              email_address: item.email_address,
            };
            this.updateItemOnAPI(new_item);
          }
        });
        this.items = new_items;
        this.editItemDialog = false;
      } else {
        alert("Not found");
      }
    },
    updateItemOnAPI(item) {
      Axios.put(`${apiUrl}/hrlastemployers/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Updated Successfully!",
            text: response.data.message,
          });

          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " editted last employer",
            title: "Last employer edit",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Update Failed!",
            text: response.data.message,
          });
          console.log(response);
        });
    },
    refreshTable() {
      this.loading = true;
      this.items = [];
      this.init();
    },
    postData(CDDialog) {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }
      let data = {
        ValueOfCurrencyDistributed: this.ValueOfCurrencyDistributed,
        Date: this.date,
        CbnInstitutionCode: this.CbnInstitutionCode,
        OriginatingSortCode: this.OriginatingSortCode,
        DestinationSortCode: this.DestinationSortCode,
        Currency: this.currency,
        Denomination: this.denomination,
        CDDialog: CDDialog,
      };
      CDDialog.button_loading = true;
      CDDialog.button_disabled = true;
      this.$emit("postCurrencyDistribution", data);
    },
    validateForm() {
      if (this.name == "") {
        return false;
      }
      if (this.address == "") {
        return false;
      }
      if (this.phone_number == "") {
        return false;
      }
      if (this.email_address == "") {
        return false;
      }
      if (this.years_experience == "") {
        return false;
      }
      return true;
    },
    clearFields() {
      this.name = "";
      this.address = "";
      this.phone_number = "";
      this.email_address = "";
      this.years_experience = "";
    },
    addItem() {
      this.$refs.addItemForm.validate();
      if (this.validateForm() == false) {
        return;
      }

      let item = {
        id: 0,
        user: this.$root.curuser,
        name: this.name,
        address: this.address,
        phone_number: this.phone_number,
        email_address: this.email_address,
        years_experience: this.years_experience,
        created_at: "Just Now",
        updated_at: "Just Now",
      };
      this.items.push(item);
      this.items.reverse();
      this.addItemDialog = false;
      let new_item = {
        user_id: item.user.id,
        name: item.name,
        address: item.address,
        phone_number: item.phone_number,
        email_address: item.email_address,
        years_experience: item.years_experience,
      };
      this.addItemToAPI(new_item);
      this.clearFields();
    },

    addItemToAPI(item) {
      Axios.post(`${apiUrl}/hrlastemployers`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          //update the ID of the item
          if (
            data.name == item.name &&
            data.phone_number == item.phone_number
          ) {
            this.items.forEach((storedData, index) => {
              if (
                storedData.name == item.name &&
                storedData.phone_number == item.phone_number
              ) {
                this.items[index].id = data.id;
                this.items[index].created_at = data.created_at;
                this.items[index].updated_at = data.updated_at;
              }
            });
          }
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Data Added!",
            text: "Action was successful",
          });

          let notification_data = {
            message:
              this.$root.curuser.surname +
              " " +
              this.$root.curuser.firstname +
              " added a last employer",
            title: "Last employer added",
            sender_id: this.$root.curuser.id,
          };
          notification_data.receiver_ids = this.hr_ids;
          this.postNotification(notification_data);
        })
        .catch(({ response }) => {
          //since the id on the datatable is defaulted to ZERO
          item.id = 0;
          this.items.forEach((element, index) => {
            if (element.name == item.name && element.id == 0) {
              this.items.splice(index, 1);
            }
          });

          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "An Error Occured",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    async getAllData() {
      await Axios.get(`${apiUrl}/hrlastemployers`, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.data.reverse();
          this.publishTable(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    publishTable(data) {
      data.forEach((q) => {
        let user = this.users_list.filter((element) => q.user_id == element.id);
        // let status = this.status_list.filter(element=>q.status == element.name)

        // q.status = (status.length > 0)? status[0] : {}
        q.user = user.length > 0 ? user[0] : {};
        //    if(this.$root.curuser.id == q.user.id)
        this.items.push(q);
      });
    },
    prepDetails(item) {
      let n = 0;
      this.details = [];
      for (var prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          let row = {
            icon: n % 2 == 0 ? "assignment" : "call_to_action",
            iconClass: "red white--text",
          };
          row.key = capitalize(prop).replace("_", " ");
          row.value = item[prop];
          if (row.value instanceof Object) {
            if (row.value.hasOwnProperty("name")) {
              row.value = item[prop].name;
            }
          }
          console.log(row);
          if (prop != "id" && prop != "user_id") this.details.push(row);
          n++;
        }
      }
    },

    viewDetail(item) {
      this.prepDetails(item);
      this.item_in_view = item;
      this.currentItem = {
        id: item.id,
        user_id: item.user_id,
        name: item.name,
        address: item.address,
        phone_number: item.phone_number,
        email_address: item.email_address,
        years_experience: item.years_experience,
      };
      this.detailsDialog = true;
    },
    getHR_Ids() {
      this.users_list.forEach((user) => {
        if (user.roles.length > 0) {
          user.roles.forEach((role) => {
            if (role.name.includes("HR")) {
              if (!this.hr_ids.includes(user.id)) this.hr_ids.push(user.id);
            }
          });
        }
      });
    },
    postNotification(item) {
      Axios.post(`${apiUrl}/hrnotifications`, item, {
        headers: {
          Authorization: `Bearer ${this.$root.auth}`,
        },
      })
        .then((response) => {
          let data = response.data.message;
          console.log(data);
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "success",
            title: "Notification Sent!",
            text: "Action was successful",
          });
        })
        .catch(({ response }) => {
          this.$notify({
            group: "platform",
            duration: 3000,
            type: "error",
            title: "Notification Error",
            text: response.data.message,
          });
          console.log(response.data);
        });
    },
    init() {
      this.getHR_Ids();
      this.getAllData();
    },
  },
};
